import React, { Component } from 'react';
import {
  Icon,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Dropdown,
  Menu,
  Input,
  Modal,
  Result,
  Popover,
  Select
} from 'antd';
import ModalPriceListEditForm from './components/ModalPriceListForm'
import ModalForm from './components/ModalForm'
import ModalFormSubAccount from './components/ModalFormSubAccount'
import BulkDownloadEXcel from './downloadExcel'
import PriceSync from './priceSync';
import './Distributors.scss'

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class Distributor extends Component {

  // Inital State for the sorting
  sortState = {
    sortBy: 'created_at',
    orderBy: 'asc'
  };

  // Initial state for searching
  searchState = {};

  pagination = {
    page: 1
  };

  constructor(props) {
    super(props);
    this.state = {
      showFormModal: false,
      showViewModal: false,
      selectedRow: null,
      showFormPricelistModal: false,
      showViewPricelistModal: false,
      showEditPriceListModal: false,
      showSubAccountFormModal: false,
      showAddEditModal: false,
      modalType: '',
      tableData: [],
      filterBy: [
        'customer_code'
      ]
    };
    this.imageUploader = React.createRef();
  }

  handleProductVariantData = (selectedRow) => {

    const { onLoadProductVariantData, onCreateMaterialLink } = this.props
    onCreateMaterialLink({
      customer_id: selectedRow.customer_code
    });
    onLoadProductVariantData({
      customer_code: selectedRow.customer_code
    })

  }

  handleSubmit = e => {
    e.preventDefault();

    const { onSubmitAddDistributor, onSubmitUpdateDistributor, form } = this.props
    const { selectedRow } = this.state;

    form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...values
        }

        if (selectedRow) {
          params.id = selectedRow.id;
          await onSubmitUpdateDistributor(params, { ...this.sortState, ...this.pagination, ...this.searchState });
        } else {
          await onSubmitAddDistributor(params, { ...this.sortState, ...this.pagination, ...this.searchState });
        }

        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };


  handleShowEditPricelistModal = row => {
    this.setState({
      showEditPriceListModal: true,
      selectedRow: row
    })
    this.props.onLoadCustomSoldTo({
      customer_code: row.customer_code
    })
    this.handleProductVariantData(row)
  }

  handleShowSubAccount = row => {
    this.props.onLoadCustomSoldTo({
      customer_code: row.customer_code,
      page: 1,
      limit: 5
    })
    this.props.onLoadSalesman({
      customer_code: row.customer_code,
      page: 1,
      limit: 5
    })
    this.setState({
      showSubAccountFormModal: true,
      selectedRow: row
    })
  }

  handlePriceSync = row => {
    this.props.onSyncCustomerPrice(row.id).then((response) => {
      if (response.error) {
        Modal.warning({
          title: 'Unable to sync Customer price.',
          content: ''
        })
      } else {
        Modal.success({
          title: 'Customer price sync has been executed. Please check after a few minutes.',
          content: ''
        })
      }
    })
  }

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      showAddEditModal: false,
      showEditPriceListModal: false,
      showSubAccountFormModal: false,
      selectedRow: null
    });
  };

  handleChangeStatus = (params) => {
    this.props.onSubmitUpdateDistributor(params, { ...this.sortState, ...this.pagination, ...this.searchState })
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Customer.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Customer has been updated successfully',
            content: ''
          })
        }
      })
  };

  handleEditDistributor = (row) => {
    this.setState({ showAddEditModal: true, selectedRow: row, modalType: 'edit' })
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadUsersData({ isPaginated: false })
    this.props.onLoadUserRolesData()
    this.props.onLoadDistributorsData({ filters: JSON.stringify({ status: [1], ewt_status: [0, 1] }) })
    this.props.onLoadStorageLocationData({ is_paginated: false, filters: JSON.stringify({ status: [1] }) });
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveDistributor({
      page: 1, // reset pagination to 1
    });
  }

  handleFilterBy = (value) => {
    this.setState({ filterBy: value, keyword: "" });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.distributorsData !== nextProps.distributorsData) {
      this.setState({ tableData: nextProps.distributorsData })
    }

    if (this.props.customerSoldToData !== nextProps.customerSoldToData) {
      this.props.onLoadCustomShipTo({
        customer_code: nextProps.customerSoldToData.data ? nextProps.customerSoldToData.data[0].customer.customer_code : nextProps.customerSoldToData[0].customer.customer_code,
      })
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.pagination = {
      page: pagination.current
    };

    this.sortState = {
      sortBy: sorter.field || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveDistributor(this.pagination);
  }

  retrieveDistributor(data) {
    this.props.onLoadDistributorsData({ ...this.sortState, ...data, ...this.searchState });
  }

  render() {
    const {
      selectedRow,
      showEditPriceListModal,
      tableData,
      filterBy,
      showAddEditModal,
      modalType,
      showSubAccountFormModal
    } = this.state
    const {
      myPermissionsLoading,
      users,
      myPermissions,
      distributorLoading,
      distributorPricelistLoading,
      distributorProductVariantData,
      productsData,
      productsLoading,
      onSubmitUpdateDistributor,
      storageLocationData,
      customerSoldToData,
      customerSoldToLoading,
      customerShipToData,
      onLoadSalesman,
      customerSalesmanData,
      customerSalesmanLoading,
      onUpdateSubCustomer,
      onUpdateSalesman,
      updateSalesmanLoading,
      onSubmitTagging,
      updateCustomerMaterialTagging,
      onLoadPricingV2,
      priceListV2,
      priceListV2Loading,
      customerShipToLoading,
      materialPrice,
      onLoadMaterialPrice
    } = this.props

    let dataSource = tableData.data;

    if (dataSource === undefined) return false;

    const payment_term_mapping = {
      'ZFDO': 'Credit term',
      'ZFDL': 'Cash On Delivery',
      'ZCIA': 'Cash In Advance', // NOTE: remove in second round of testing
      'ZFCI': 'Cash In Advance',
      'ZFDK': 'Special Term'
    };

    const columns = [
      {
        title: 'Customer Code',
        dataIndex: 'customer_code',
        key: 'customer_code',
        sorter: (a, b) => {

        },
      },
      {
        title: 'Customer Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {

        },
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        render: (value, row) => {
          return row.source ? (
            <span>{value.map(source => {
              return source['description']
            }).join(', ')}</span>
          ) : '';
        }
      },
      {
        title: 'Payment Term',
        dataIndex: 'payment_term',
        key: 'payment_term',
        render: (value, row) => {
          return row.source ? (
            <span>{payment_term_mapping[value]}</span>
          ) : '';
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 200,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        defaultFilteredValue: [1],
        render: (tags, row) => {
          const status = row.status

          let htmlElement

          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus({
                            id: row.id,
                            status: 1
                          })
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus({
                            id: row.id,
                            status: 0
                          })
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      {
        title: 'EWT Status',
        dataIndex: 'ewt_status',
        key: 'ewt_status',
        align: 'center',
        width: 200,
        filters: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
        defaultFilteredValue: [1, 0],
        render: (value, row) => {
          return row.source ? (<span>{value === 'Yes' ? 'Enabled' : 'Disabled'}</span>) : '';
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return row.source ? (
            <div>
              <Popover content={(<p>Edit Customer</p>)}>
                <span onClick={() => this.handleEditDistributor(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit Pricelist</p>)}>
                <span onClick={() => this.handleShowEditPricelistModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="dollar" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>View Customers</p>)}>
                <span onClick={() => this.handleShowSubAccount(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="team" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Sync Customer Price</p>)}>
                <span onClick={() => this.handlePriceSync(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="sync" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
            </div>
          ) : ''
        }
      }
    ];
    if (!myPermissions.some(r => r.name === 'admin.customer.edit')) {
      columns.pop();
    }

    if (myPermissionsLoading) {
      return null
    }

    return myPermissions.some(r => r.name === 'admin.customer.view') ? (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <Row style={{ display: "flex", flexWrap: "wrap" }}>
              <Col className="page-title">
                Customers
              </Col>
              <Col className="page-buttons">
                <BulkDownloadEXcel
                  className="btn-small btn-primary"
                  title="Extract Customer Report"
                  data={dataSource}
                />
                <PriceSync
                  className="btn-small btn-primary"
                  title="Sync Customer Prices"
                  onSyncPrice={this.props.onSyncPrice}
                  loading={distributorLoading}
                />
              </Col>
            </Row>
            <Row>
              <div className="distributors-container">
                <Col>
                  <Search className="search-container"
                    size="large"
                    placeholder="Search"
                    onSearch={(value) => this.handleSearch(value, filterBy)} />
                  <Select style={{ width: 160 }}
                    placeholder="Filter"
                    size="large"
                    defaultValue="customer_code" // changed the defaukt value selected in dropdown to material_code
                    onChange={(value) => this.handleFilterBy(value)}
                    dropdownClassName="--select"
                    autoFocus={true}
                    showSearch>
                    <Option value="customer_code">Code</Option>
                    <Option value="name">Customer Name</Option>
                  </Select>
                  <Table
                    rowKey="id"
                    dataSource={dataSource}
                    columns={columns}
                    loading={myPermissionsLoading || distributorLoading}
                    onChange={this.handleOnChangeTableListener}
                    pagination={{ total: tableData.total, current: tableData.current_page, pageSize: tableData.per_page || 10 }}
                    scroll={{ x: true }}
                  />
                </Col>
              </div>
            </Row>
          </Content>
        </Layout>
        {showEditPriceListModal &&
          <ModalPriceListEditForm
            showFormModal={showEditPriceListModal}
            handleCloseModal={this.handleCloseModal}
            selectedRow={selectedRow}
            distributorProductVariantData={distributorProductVariantData}
            productsData={productsData}
            productsLoading={productsLoading}
            isLoading={distributorPricelistLoading}
            customerSoldToData={customerSoldToData}
            customerSoldToLoading={customerSoldToLoading}
            customerShipToData={customerShipToData}
            customerShipToLoading={customerShipToLoading}
            onSubmitTagging={onSubmitTagging}
            updateCustomerMaterialTagging={updateCustomerMaterialTagging}
            onLoadPricingV2={onLoadPricingV2}
            priceListV2={priceListV2}
            priceListV2Loading={priceListV2Loading}
            onLoadShipTo={this.props.onLoadCustomShipTo}
            onLoadProductVariantData={this.props.onLoadProductVariantData}
            pricelistByProductLoading={this.props.pricelistByProductLoading}
            materialPrice={materialPrice}
            onLoadMaterialPrice={onLoadMaterialPrice}
          />
        }

        {
          showAddEditModal &&
          <ModalForm
            showAddEditModal={showAddEditModal}
            submitUpdateDistributor={onSubmitUpdateDistributor}
            handleCloseModal={this.handleCloseModal}
            users={users}
            modalType={modalType}
            selectedRow={selectedRow}
            searchParams={{ ...this.sortState, ...this.pagination, ...this.searchState }}
            storageLocation={storageLocationData}
          />
        }

        {
          showSubAccountFormModal &&
          <ModalFormSubAccount
            showFormModal={showSubAccountFormModal}
            submitUpdateDistributor={onSubmitUpdateDistributor}
            handleCloseModal={this.handleCloseModal}
            users={users}
            modalType={modalType}
            selectedRow={selectedRow}
            customerSoldToData={customerSoldToData}
            onLoadSoldTo={this.props.onLoadCustomSoldTo}
            customerSoldToLoading={this.props.customerSoldToLoading}
            onLoadShipTo={this.props.onLoadCustomShipTo}
            customerShipToLoading={this.props.customerShipToLoading}
            customerShipToData={customerShipToData}
            customerSalesmanData={customerSalesmanData}
            customerSalesmanLoading={customerSalesmanLoading}
            onLoadSalesman={onLoadSalesman}
            onUpdateSubCustomer={onUpdateSubCustomer}
            onUpdateSalesman={onUpdateSalesman}
            updateSalesmanLoading={updateSalesmanLoading}
          />
        }


      </div >
    ) : (
      <Result
        status="warning"
        title="You have no permission to view this page."
      />
    );
  }
}

export default Distributor;
