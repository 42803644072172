import { combineReducers } from 'redux'

import users from './users'
import roles from '../../UserTypes/reducers/roles'
import salesman from './salesman'

export default combineReducers({
  users,
  roles,
  salesman
})
