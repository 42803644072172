import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible'
import {
    loadOrdersData,
    loadOrderStatusData,
    sendRequest,
    loadStorageLocation,
    confirmOrder,
    proposeOrder,
    rejectOrder,
    downloadData,
    downloadAttachment,
    retrieveFileUrl
} from './actions'
import {
    fetchPermissions,
    fetchNotifications,
    fetchInfo
} from '../../actions'
import Page from './Page';

class OrderManagement extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const OrderForm = Form.create({ name: 'order_form' })(OrderManagement);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        myInfo: state.myInfo.myInfo,
        myInfoLoading: state.myInfo.myInfoLoading,
        orderData: state.orderManagement.orderData,
        orderDataTotalCount: state.orderManagement.orderDataTotalCount,
        orderStatusData: state.orderManagement.orderStatusData,
        orderLoading: state.orderManagement.orderLoading,
        orderCsvData: state.orderManagement.downloadData,
        orderCsvLoading: state.orderManagement.downloadDataLoading,
        notifData: state.notifications.notifications,
        requestData: state.requestData.requestData,
        storageLocationData: state.storageLocation.storageLocationData,
        storageLocationLoading: state.storageLocation.storageLocationLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        fetchInfo: (data) => dispatch(fetchInfo(data)),
        fetchNotifications: (data) => dispatch(fetchNotifications(data)),
        onSendRequest: (data) => dispatch(sendRequest(data)),
        onLoadOrdersData: (page) => dispatch(loadOrdersData(page)),
        onLoadOrderStatusData: (data) => dispatch(loadOrderStatusData(data)),
        onLoadStorageLocation: (data) => dispatch(loadStorageLocation(data)),
        onOrderConfirm: (data) => dispatch(confirmOrder(data)),
        onProposeOrder: (data) => dispatch(proposeOrder(data)),
        onRejectOrder: (data) => dispatch(rejectOrder(data)),
        onDownloadOrder: (data) => dispatch(downloadData(data)),
        onDownloadAttachment: (orderId, attachmentId) => dispatch(downloadAttachment(orderId, attachmentId)),
        onRetrieveFileUrl: (orderId, attachmentId) => dispatch(retrieveFileUrl(orderId, attachmentId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
