import React, { Component } from "react";
import moment from "moment";
import ModalView from "./components/ModalView";
import PlannerModalView from "./components/PlannerModalView";
import {
  Layout,
  Row,
  Col,
  Table,
  Input,
  Result,
  Popover,
  DatePicker,
  Select,
} from "antd";
import { Form, Icon } from '@ant-design/compatible';
import BulkDownloadEXcel from './downloadExcel'
import BulkDownloadEXcelPlanner from './downloadExcelPlanner'
import "./DistributorOrders.scss";
import ModalAttachment from "./components/ModalAttachment";
import _ from "lodash";

const { Content } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

class Order extends Component {
  constructor(props) {
    super(props);
    this.tableContainerRef = React.createRef();
    this.scrollContainerRef = React.createRef();
    this.state = {
      currentPage: 1,
      pageLimit: 5,
      pageFilter: {},
      pageSearch: {},
      showResponsesModal: false,
      radio: "all",
      showFormModal: false,
      showViewModal: false,
      showCancelModal: false,
      selectedRow: null,
      selectedRowKeys: [],
      notifData: [],
      tableData: [],
      statusData: [],
      searchValue: "",
      dataSearched: [],
      startDate: null,
      endDate: null,
      remarks: "",
      visible: false,
      visibleCancel: false,
      filterDate: 'All',
      filterTargetETD: 'All',
      filterCustomer: 'All',
      filterCountry: 'All',
      filterOptions: [],
      selectedSearchFilter: 'order_detail.created_at',
      sortState: {
        sortBy: 'order_detail.created_at',
        orderBy: 'desc',
      },
      jsonFilter: {},
      params: [],
      showModalAttachment: false,
      selectedId: 0
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  handleShowViewModal = (row) => {
    this.setState({
      selectedRow: row,
    }, () => {
      this.setState({
        showViewModal: true,
      });
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null,
    });
  };

  clearFilter() {
    this.setState({
      filterDate: 'All',
      filterTargetETD: 'All',
      filterCustomer: 'All',
      filterCountry: 'All',
      tableData: this.props.orderData,
      filterOptions: []
    })
  }

  fetchOrdersData() {
    const {
      currentPage,
      pageLimit,
      pageFilter,
      pageSearch,
      sortState,
      jsonFilter
    } = this.state;
    let params = {
      ...pageSearch,
      page: currentPage,
      limit: pageLimit,
      filters: JSON.stringify(pageFilter),
    }

    if (sortState.sortBy) {
      params = {
        ...params,
        ...sortState
      }
    }
    if (!_.isEmpty(jsonFilter)) {
      params = {
        ...params,
        json_filter: JSON.stringify(jsonFilter)
      }
    }

    this.setState({
      params: params
    })

    this.props.onLoadOrdersData(params);
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('id')) {
      this.props.onLoadOrdersData({
        page: 1,
        limit: this.state.pageLimit,
        filter: this.state.pageFilter,
        id: urlParams.get("id")
      }).then((response) => {
        if (response.response.data.data.length > 0) {
          this.handleShowViewModal(response.response.data.data[0]);
        } else {
          this.props.onLoadOrdersData({
            page: 1,
            limit: this.state.pageLimit,
            filter: this.state.pageFilter
          })
        }
      });
    } else {
      this.props.onLoadOrdersData({
        page: 1,
        limit: this.state.pageLimit,
        filter: this.state.pageFilter,
      });
    }

    this.setState({
      params: {
        page: 1,
        limit: this.state.pageLimit,
        filter: this.state.pageFilter,
      }
    })

    this.props.onLoadStorageLocation({ is_paginated: false });
    this.props.fetchPermissions()
    this.props.fetchInfo()
    this.props.fetchNotifications()

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orderData !== nextProps.orderData) {
      this.setState({ tableData: nextProps.orderData });
    }

    if (this.props.orderStatusData !== nextProps.orderStatusData) {
      this.setState({ statusData: nextProps.orderStatusData })
    }

    if (this.props.notifData !== nextProps.notifData) {
      this.setState({ notifData: nextProps.notifData })
      this.clearFilter();
    }
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidUpdate(prevState, prevProps) {
    const { startDate, endDate, searchValue } = this.state;
    if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
      prevProps.notifData = this.props.notifData;
    }

    if (searchValue !== prevState.searchValue) {
      if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
        prevProps.notifData = this.props.notifData;
      }
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.state.sortState[key])
    }

    this.setState({
      currentPage: pagination.current,
      sortState: {
        sortBy: sorter.columnKey || this.state.sortState.sortBy,
        orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      }
    }, () => {
      this.fetchOrdersData();
    })

  }

  render() {
    const {
      radio,
      showViewModal,
      selectedRow,
      selectedSearchFilter,
      params,
      showModalAttachment,
      selectedId
    } = this.state;

    const {
      myPermissions,
      myPermissionsLoading,
      notifData,
      orderLoading,
      onSendRequest,
      orderData,
      myInfo,
      myInfoLoading,
      orderCsvData,
      onDownloadOrder,
      orderCsvLoading,
      onDownloadAttachment,
      onRetrieveFileUrl
    } = this.props;

    // ask Dom for alternative; issue is when user is from stor loc page and navigates to order management page results to error
    let storageLocationData = this.props.storageLocationData?.data || this.props.storageLocationData;

    const handleSourceFilter = (value) => {
      const { jsonFilter } = this.state;
      let filter = jsonFilter;

      if (value === "all") {
        delete filter["order_detail.shipping_point"]
      } else {
        filter = { ...filter, 'order_detail.shipping_point': [value] }
      }

      this.setState({
        jsonFilter: filter
      }, () => {
        this.fetchOrdersData();
      });
    }

    const handleSelectSearchFilter = (value) => {
      this.setState({
        selectedSearchFilter: value
      })
    }

    const changeAttachmentListVisibility = (isShown = false, id = 0) => {
      this.setState({
        showModalAttachment: isShown,
        selectedId: id
      })
    }

    const payment_term_mapping = {
      'all': "All",
      'ZFCI': 'Cash In Advance',
      'ZFDO': 'Credit Term',
      'ZFDL': 'Cash On Delivery',
      'ZFDK': 'Special Term'
    };

    const columns = [
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id) => {
          return (<Icon type="paper-clip" onClick={(e) => {
            e.stopPropagation()
            changeAttachmentListVisibility(true, id)
          }} style={{ fontSize: "20px" }} />);
        },
        sorter: false,
      },
      {
        title: "ORDER DATE",
        dataIndex: "created_at",
        key: "order_detail.created_at",
        width: 120,
        render: (created_at) => {
          return <span style={{ whiteSpace: "nowrap" }}>{created_at}</span>;
        },
        sorter: true,
      },
      {
        title: "TRANSACTION NUMBER",
        dataIndex: "order_transaction_id",
        key: "order_detail.order_transaction_id",
        sorter: true,
      },
      {
        title: "ORDER TYPE",
        dataIndex: "tagging",
        key: "order_detail.tagging",
        sorter: true,
        render: (value) => {
          return <span style={{ "text-transform": "uppercase" }}>{value}</span>;
        }
      },
      {
        title: "SALES ORDER NUMBER",
        dataIndex: "sales_order_number",
        key: "order_detail.sales_order_number",
        sorter: true,
      },
      {
        title: "ORDER STATUS",
        dataIndex: "status",
        key: "order_detail.status",
        sorter: true,
      },
      {
        title: "PAYER NAME",
        dataIndex: "customer.name",
        key: "payer.name",
        sorter: true
      },
      {
        title: "SOLD TO NAME",
        dataIndex: "sold_to.name",
        key: "sold_to.name",
        sorter: true
      },
      {
        title: "CUSTOMER GROUP 2",
        dataIndex: "customer.customer_group_2",
        key: "payer.customer_group_2",
        sorter: true
      },
      {
        title: 'ORDERED QUANTITY',
        dataIndex: 'total_quantity',
        key: 'order_detail.total_quantity',
        sorter: true,
      },
      {
        title: (
          <span>
            ORDERED Amount
            <br />
            (PHP VAT-IN)
          </span>
        ),
        dataIndex: "order_price.total_amount",
        key: "price.total_amount",
        align: "center",
        sorter: true,
      },
      {
        title: 'OPEN ORDER',
        dataIndex: 'total_open_quantity',
        key: 'order_detail.total_open_quantity',
        render: (openQuantity) => {
          return openQuantity === null ? "N/A" : openQuantity
        },
        sorter: true,
      },
      {
        title: (
          <span>
            OPEN ORDER AMOUNT
            <br />
            (PHP VAT-IN)
          </span>
        ),
        dataIndex: "order_price.total_open_amount",
        key: "price.total_open_amount",
        align: "center",
        render: (openAmount) => {
          return openAmount === null ? "N/A" : openAmount
        },
        sorter: true,
      },
      {
        title: "SO DELIVERY DATE",
        dataIndex: "delivery_details.estimated_delivery_date",
        key: "order_delivery_detail.estimated_delivery_date",
        align: "center",
        sorter: true,
      },
      {
        title: "CONFIRMED AVAILABILITY DATE",
        dataIndex: "confirmed_availability_date",
        key: "order_detail.confirmed_availability_date",
        align: "center",
        render: (value) => {
          return value || "N/A"
        },
        sorter: true,
      },
      {
        title: (<span>TYPE</span>),
        dataIndex: "delivery_details.type",
        key: "order_delivery_detail.type",
        align: "center",
        sorter: true,
      },
      {
        title: (<span>SOURCE</span>),
        dataIndex: "shipping_point",
        key: "Source",
        render: (value) => {
          return value ? (
            <span>
              {value.map((shipping_point) => {
                return shipping_point.description
              }).join(', ')}
            </span>
          ) : ''
        }
      },
      {
        title: (<span>PAYMENT TERM</span>),
        dataIndex: "payment_term",
        key: "order_detail.payment_term",
        align: "center",
        width: 150,
        render: (value, record) => {
          return (record.payment && value === "ZFCI" ? <div>{payment_term_mapping[value]}<Popover content={(<p>Customer has uploaded the proof of payment reference. Please check SAP to review and approve</p>)} title="Proof of Payment Attachment">
            <span style={{ cursor: 'pointer', margin: 5 }}>
              <Icon type="info-circle" style={{ fontSize: '1rem', color: "#7CB9E8" }} />
            </span>
          </Popover></div> : payment_term_mapping[value])
        },
        sorter: true,
      },
      {
        title: (<span>SO Blocked Time</span>),
        dataIndex: "blocked_date",
        key: "order_detail.blocked_date",
        align: "center",
        render: (value) => {
          return value || "N/A"
        },
        sorter: true,
      },
      {
        title: (<span>Approved Time</span>),
        dataIndex: "approved_date",
        key: "order_detail.approved_date",
        align: "center",
        render: (value) => {
          return value || "N/A"
        },
        sorter: true,
      },
      {
        title: (<span>MTO Status</span>),
        dataIndex: "mto_status",
        key: "order_detail.mto_status",
        align: "center",
        render: (value) => {
          return value || "N/A"
        },
        sorter: true,
      },
    ];

    if (!myPermissions.some((r) => r.name === "admin.customer_order.amount")) {
      columns.splice(_.findIndex(columns, function (value) { return value.key == 'price.total_amount'; }), 1);
      columns.splice(_.findIndex(columns, function (value) { return value.key == 'price.total_open_amount'; }), 1);
    }

    if (!myPermissions.some((r) => r.name === "admin.customer_order.attachment")) {
      columns.splice(0, 1);
    }


    const handleOnStatusFilterChange = (e) => {

      const status = e.target.value === "Open Order" ? ["For Review", "For Salesman Approval"] : [e.target.value];

      if (e.target.value !== 'all') {
        this.setState({
          radio: e.target.value,
          currentPage: 1,
          pageFilter: { ...this.state.filters, 'order_detail.status': status },
        }, () => {
          this.fetchOrdersData();
        });
      } else {
        delete this.state.pageFilter.status;
        this.setState({
          radio: e.target.value,
          currentPage: 1,
          pageFilter: { ...this.state.filters },
        }, () => {
          this.fetchOrdersData();
        });
      }
    }

    const handleTaggingFilter = (value) => {
      if (value.length > 1) {
        value.push("both");
      }

      if (value.length === 0) {
        value = ['both', 'regular', 'mto']
      }

      this.setState({
        currentPage: 1,
        pageFilter: { ...this.state.filters, 'order_detail.tagging': value },
      }, () => {
        this.fetchOrdersData();
      });
    }

    const handleMtoStatus = (value) => {
      if (value.length === 0) {
        value = ['confirmed', 'proposed', 'rejected']
      }

      this.setState({
        currentPage: 1,
        pageFilter: { ...this.state.filters, 'order_detail.mto_status': value },
      }, () => {
        this.fetchOrdersData();
      });
    }

    const handleSearch = (value) => {
      if (value === "all") {
        this.setState({
          pageSearch: {},
        }, () => {
          this.fetchOrdersData();
        })
        return false;
      }
      this.setState({
        pageSearch: {
          searchField: selectedSearchFilter,
          searchValue: value,
        },
      }, () => {
        this.fetchOrdersData();
      })
    }

    const handleDatePicker = (value) => {
      this.setState({
        pageSearch: {
          searchField: selectedSearchFilter,
          searchValue: [value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')].join(),
        },
      }, () => {
        this.fetchOrdersData();
      })
    }

    if (myPermissionsLoading || myInfoLoading) {
      return null
    }

    const onScroll = (e) => {
      if (this.tableContainerRef.current) {
        this.tableContainerRef.current.childNodes[0].scrollLeft = e.target.scrollLeft;
      }
    };
    const onTableScroll = (e) => {
      if (this.scrollContainerRef.current) {
        this.scrollContainerRef.current.scrollLeft = e.target.scrollLeft;
      }
    };
    if (this.tableContainerRef.current?.childNodes && this.tableContainerRef.current?.childNodes[0]) {
      this.tableContainerRef.current.childNodes[0].addEventListener('scroll', onTableScroll);
    }

    return myPermissions.some((r) => r.name === "admin.customer_order.view") ? (
      <div>
        <Layout className="orders-container">
          <Content style={{ padding: 25 }}>
            <Content className="orders-header">
              <Row type="flex" justify="space-between">
                <Col className="page-title" style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                  <h1>Order Management</h1>
                </Col>
                <Col style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                  {myPermissions.some((r) => r.name === 'admin.customer_order.download') && ["Planner"].includes(myInfo.roles ? myInfo.roles[0].name : "") ? (<BulkDownloadEXcelPlanner
                    className="btn-small btn-primary"
                    title="Download Multiple PO"
                    myPermissions={myPermissions}
                    total={orderData?.total}
                    onDownloadOrder={onDownloadOrder}
                    csvData={orderCsvData}
                    filter={params}
                    loading={orderCsvLoading}
                  />) : myPermissions.some((r) => r.name === 'admin.customer_order.download') ? (<BulkDownloadEXcel
                    className="btn-small btn-primary"
                    title="Download Multiple PO"
                    myPermissions={myPermissions}
                    total={orderData?.total}
                    onDownloadOrder={onDownloadOrder}
                    csvData={orderCsvData}
                    filter={params}
                    loading={orderCsvLoading} />) : ""}
                </Col>
              </Row>
            </Content>
            <Content className='orders-content'>
              <Row style={{ display: "flex", padding: "24px", justifyContent: "space-between", flexWrap: "wrap" }}>
                <Col style={{ display: "flex", flexWrap: "wrap" }}>
                  <div>
                    {selectedSearchFilter === 'order_detail.created_at' ? (
                      <RangePicker
                        className="datepicker"
                        size="large"
                        style={{ width: 230 }}
                        onChange={handleDatePicker}
                      />
                    ) : selectedSearchFilter === 'order_delivery_detail.type' ? (
                      <Select defaultValue={'deliver'} onSelect={handleSearch} showSearch style={{ width: 230 }}>
                        <Option value='deliver'>Delivery</Option>
                        <Option value='pick-up'>Pick Up</Option>
                      </Select>
                    ) : selectedSearchFilter === 'order_detail.payment_term' ? (
                      <Select defaultValue={'all'} onSelect={handleSearch} showSearch style={{ width: 230 }}>
                        {Object.keys(payment_term_mapping).map(item => (
                          <Select.Option key={item} value={item}>
                            {payment_term_mapping[item]}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Search
                        // size="large"
                        className="search-container"
                        placeholder="Search"
                        onSearch={handleSearch}
                        style={{ width: 230 }}
                      />
                    )}
                  </div>
                  <div style={{ margin: "auto" }}>
                    <Select defaultValue={selectedSearchFilter} onSelect={handleSelectSearchFilter} showSearch style={{ width: 230 }}>
                      <Option value='order_detail.created_at'>Order Date</Option>
                      <Option value='order_detail.order_transaction_id'>Transaction Reference Number</Option>
                      <Option value='order_detail.sales_order_number'>Sales Order Number</Option>
                      <Option value='payer.name'>Payer Name</Option>
                      <Option value='payer.customer_group_2'>Customer Group 2</Option>
                      <Option value='order_delivery_detail.type'>Delivery Type</Option>
                      <Option value='order_detail.payment_term'>Payment Term</Option>
                    </Select>
                  </div>
                </Col>
                <Col>
                  <div></div>
                  <div>
                    <span>Source: </span>
                    <Select defaultValue={'all'} onSelect={handleSourceFilter} showSearch style={{ width: 230 }}>
                      <Option value='all' key="all">All</Option>
                      {storageLocationData.map((value) => <Option value={value.shipping_point} key={value.id}>{value.description}</Option>)}

                    </Select>
                  </div>
                </Col>
              </Row>
              <Row style={{ display: "flex", justifyContent: "space-between", margin: "0 24px", flexWrap: "wrap" }}>
                <Col style={{ width: "50%" }}>
                  <Select
                    mode="multiple"
                    style={{ width: "60%" }}
                    onChange={handleTaggingFilter}
                    placeholder="Please select tagging"
                  >
                    <Option key="regular" value="regular">Regular</Option>
                    <Option key="mto" value="mto">MTO</Option>
                  </Select>
                </Col>
                {!this.state.pageFilter["order_detail.tagging"] || (this.state.pageFilter["order_detail.tagging"] && this.state.pageFilter["order_detail.tagging"].includes('mto')) ? (<Col style={{ width: "50%" }}>
                  <Select
                    mode="multiple"
                    style={{ width: "60%" }}
                    placeholder="Please select status"
                    onChange={handleMtoStatus}
                  >
                    <Option key="confirmed" value="confirmed">Confirmed</Option>
                    <Option key="proposed" value="proposed">Proposed</Option>
                    <Option key="rejected" value="rejected">Rejected</Option>
                  </Select>
                </Col>) : ""}
              </Row>
              <Row style={{ display: "flex", margin: "24px" }}>
                  <div className="status-radio-filter">
                    <label className="radio">
                      ALL
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "all"}
                        value="all"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      SUBMITTED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Submitted"}
                        value="Submitted"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CONFIRMED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Confirmed"}
                        value="Confirmed"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      APPROVED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Approved"}
                        value="Approved"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      PARTIALLY DISPATCHED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Partially Dispatched"}
                        value="Partially Dispatched"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      COMPLETED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Completed"}
                        value="Completed"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CANCELLED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Cancelled"}
                        value="Cancelled"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      BLOCKED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Blocked"}
                        value="Blocked"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      OPEN ORDER
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Open Order"}
                        value="Open Order"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      PENDING
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Pending"}
                        value="Pending"
                        onChange={(e) => handleOnStatusFilterChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </Row>
            </Content>
            <Row>
              <Col>
                <div>
                  <div
                    style={{ overflowX: 'scroll', height: "20px", marginBottom: '5px' }}
                    ref={this.scrollContainerRef}
                    onScroll={onScroll}
                  >
                    <div>
                      <Table scroll={{ x: 0 }} loading={myPermissionsLoading || orderLoading} dataSource={orderData?.data} columns={columns} pagination={{ total: orderData?.total, current: orderData?.current_page, pageSize: orderData?.per_page || 10 }} />
                    </div>
                  </div>


                </div>
                <div ref={this.tableContainerRef}>
                  <Table
                    className="order-table-component"
                    rowKey="id"
                    pagination={false}
                    dataSource={orderData?.data}
                    columns={columns}
                    loading={myPermissionsLoading || orderLoading}
                    onChange={this.handleOnChangeTableListener}
                    onRowClick={(orderDetails, index) => {
                      this.handleShowViewModal(orderDetails)
                    }}
                    pagination={{ total: orderData?.total, current: orderData?.current_page, pageSize: orderData?.per_page || 10 }}
                  />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>

        {myInfo.roles && !["Planner", "Salesman"].includes(myInfo.roles[0].name) && (
          <ModalView
            {...this.props}
            showFormModal={showViewModal}
            handleCloseModal={this.handleCloseModal}
            selectedRow={selectedRow}
            onSendRequest={onSendRequest}
            myPermissions={myPermissions}
          />
        )}

        {myInfo.roles && ["Planner", "Salesman"].includes(myInfo.roles[0].name) && (
          <PlannerModalView
            {...this.props}
            showFormModal={showViewModal}
            handleCloseModal={this.handleCloseModal}
            selectedRow={selectedRow}
            onSendRequest={onSendRequest}
            myPermissions={myPermissions}
          />
        )}

        <ModalAttachment isVisible={showModalAttachment} onClose={changeAttachmentListVisibility} selectedOrder={_.find(orderData?.data, { "id": selectedId })} onDownload={onDownloadAttachment} onPreview={onRetrieveFileUrl} />

      </div>
    ) : (
      <Result
        status="warning"
        title="You have no permission to view this page."
      />
    );
  }
}

const OrderForm = Form.create({ name: "order_form" })(Order);

export default OrderForm;
