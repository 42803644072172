import moment from 'moment'
const initialData = {
  featuresAuditTrailLoading: false,
  featuresAuditTrailLoaded: false,
  featuresAuditTrailRejected: false,
  featuresAuditTrailData: []
}
export default function AUDITTRAIL(state = initialData, action) {
  switch (action.type) {
    case 'GET_FEATURES_AUDIT_TRAIL':
      return {
        ...state,
        featuresAuditTrailLoading: true,
        featuresAuditTrailLoaded: false,
        featuresAuditTrailRejected: false,
      }
    case 'GET_FEATURES_AUDIT_TRAIL_FULFILLED':
      action.payload.data.map((data) => {
        data.updated_at_local = moment(new Date(data.updated_at)).format('LLLL')
      })
      return {
        ...state,
        featuresAuditTrailLoading: false,
        featuresAuditTrailLoaded: true,
        featuresAuditTrailRejected: false,
        featuresAuditTrailData: action.payload
      }
    case 'GET_FEATURES_AUDIT_TRAIL_REJECTED':
      return {
        ...state,
        featuresAuditTrailLoading: false,
        featuresAuditTrailLoaded: false,
        featuresAuditTrailRejected: true,
      }
    default:
      return {
        ...state
      };
  }
}
