import React, { Component } from 'react';
import ModalForm from './components/ModalForm';
import ModalView from './components/ModalView';

import './ProductVariants.scss'

import { Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal, Result, Popover, Select } from 'antd';
import { Icon, Form} from '@ant-design/compatible';
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
class Material extends Component {
  initialState = {
    showFormModal: false,
    showViewModal: false,
    showWarningModal: false,
    selectedRow: null,
    materialsData: [],
    filterBy: [
      'description'
    ],
    isImageUploading: false,
    changeStatusData: {}
  }

  // Inital State for the sorting
  sortState = {
    sortBy: 'created_at',
    orderBy: 'asc',
    filters: JSON.stringify({ 'status': [1], 'is_separate_order': [1, 0] })
  };

  // Initial state for searching
  searchState = {};

  activeProducts = {};

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.imageUploader = React.createRef();
  }

  handleSubmit = e => {
    e.preventDefault();

    const { onSubmitUpdateMaterial } = this.props
    const { selectedRow, materialsData } = this.state;
    const data = {
      page: materialsData.current_page
    }

    this.props.form.validateFields(async (err, values) => {

      if (!err) {
        this.setState({isImageUploading: true})

        let params = {
          ...values
        }

        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? ""
            : await this.imageUploader.current.handleUploadRequest();
        params.image = media ? media.url : imageUrl;
        params.status = params.status === 'Active' ? 1 : 0
        params.is_separate_order = params.is_separate_order === 'Active' ? 1 : 0

        params.id = selectedRow.id;
        // passed the value for the current state of filter, sort, search, and page
        await onSubmitUpdateMaterial(params, { ...this.sortState, ...data, ...this.searchState })
          .then((response) => {
            this.setState({isImageUploading: false})
            if (response.error) {
              Modal.warning({
                title: 'Unable to update Product variants.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Product variants has been updated successfully',
                content: ''
              })
            }
          })
        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null
    });
  };

  handleChangeStatus = () => {
    const { materialsData, changeStatusData } = this.state;
    const data = {
      page: materialsData.current_page
    };

    const params = {
      id: changeStatusData.row.id,
      [changeStatusData.key]: changeStatusData.status
    };

    // passed the value for the current state of filter, sort, search, and page
    this.props.onSubmitUpdateMaterial(params, { ...this.sortState, ...data, ...this.searchState })
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update product variants.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Product variants has been updated successfully',
            content: ''
          })
        }
      })

    this.setState({
      showWarningModal: false,
      changeStatusData: {}
    });
  };
  handleFilterBy = (value) => {
    this.setState({ filterBy: value, keyword: "" });
  };
  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadMaterialsData(this.sortState);
    this.props.onLoadActiveProductData()
  }

  handleSearch = (searchTerm, searchKeys) => {

    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveVariantProducts({
      page: 1, // reset pagination to 1
    });
  }

  retrieveVariantProducts(data) {
    this.props.onLoadMaterialsData({ ...this.sortState, ...data, ...this.searchState });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.materialsData !== nextProps.materialsData) {
      this.setState({ materialsData: nextProps.materialsData })
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.columnKey || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveVariantProducts({
      page: pagination.current
    });
  }

  render() {
    const { 
      showFormModal, 
      showViewModal, 
      selectedRow, 
      materialsData, 
      filterBy, 
      isImageUploading,
      showWarningModal
    } = this.state
    const {
      myPermissions, myPermissionsLoading,
      materialLoading,
      productLoading,
      form: { getFieldDecorator }
    } = this.props
    const materialTableData = materialsData.data;
    const materialTablePagination = materialsData;
    if (materialsData === undefined) {
      return false;
    }

    const columns = [
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 150,
        render: (image, row) => {
          if (!image) {
            return <div style={{ height: 90 }}></div>;
          }
          return <div style={{ height: 90, margin: 0, padding: 0 }}><img src={process.env.REACT_APP_FILES_URL + image} alt={row.name} width={80} style={{ height: 80, objectFit: 'contain', filter: row.status === 0 && 'grayscale(100%)' }} /></div>
        }
      },
      {
        title: 'Material Code',
        dataIndex: 'material_number',
        key: 'material_number',
        align: 'center',
        sorter: (a, b) => {
        },
      },
      {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => {
        },
      },
      {
        title: 'Gross Weight',
        dataIndex: 'gross_weight',
        key: 'gross_weight',
        align: 'center',
        sorter: (a, b) => {

        },
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a, b) => {
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 100,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }], // change value from Active to 1 and Inactive to 0
        defaultFilteredValue: [1],
        render: (tags, row) => {
          const status = row.status
          let htmlElement

          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                disabled={row.status === 0}
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            changeStatusData: {
                              row: row,
                              status: 1,
                              key: 'status'
                            }
                          }, this.handleChangeStatus);
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            changeStatusData: {
                              row: row,
                              status: 0,
                              key: 'status'
                            }
                          }, this.handleChangeStatus);
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      {
        title: 'Value Brand',
        dataIndex: 'is_separate_order',
        key: 'is_separate_order',
        align: 'center',
        width: 100,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }], // change value from Active to 1 and Inactive to 0
        defaultFilteredValue: [1, 0],
        render: (tags, row) => {
          const status = row.is_separate_order
          let htmlElement

          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                disabled={row.is_separate_order === 0}
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            showWarningModal: true,
                            changeStatusData: {
                              row: row,
                              status: 1,
                              key: 'is_separate_order'
                            }
                          });
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            showWarningModal: true,
                            changeStatusData: {
                              row: row,
                              status: 0,
                              key: 'is_separate_order'
                            }
                          });
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <div>
              <Popover content={(<p>View</p>)}>
                <span onClick={() => row.status === 'Active' && this.handleShowViewModal(row)} style={{ cursor: row.status !== 'Active' ? 'no-drop' : 'pointer', margin: 5 }}>
                  <Icon type="eye" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit</p>)}>
                <span onClick={() => row.status === 'Active' && this.handleShowEditFormModal(row)} style={{ cursor: row.status !== 'Active' ? 'no-drop' : 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
            </div>
          )
        }
      }
    ];

    if (!myPermissions.some(r => r.name === 'admin.material.edit')) {
      columns.pop();
    }

    if (myPermissionsLoading) {
      return null
    }
    return myPermissions.some(r => r.name === 'admin.material.view') ? (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <div className="page-title">Materials</div>
            <Row>
              <Col >
                <div className="product-variants-container">
                  <Search
                    className="search-container"
                    size="large"
                    placeholder="Search"
                    onSearch={(value) => this.handleSearch(value, filterBy)}
                  />
                  <Select style={{ width: 160 }}
                    // suffixIcon={<img src={FilterIcon} />}
                    size="large"
                    placeholder="Filter"
                    onChange={(value) => this.handleFilterBy(value)}
                    defaultValue="description"
                    dropdownClassName="--select"
                    autoFocus={true}
                    showSearch>
                    {/* <Option value="all">All</Option> */}
                    <Option value="description">Name</Option>
                    <Option value="material_number">Material Code</Option>
                    <Option value="gross_weight">Gross Weight</Option>
                    <Option value="country">Country</Option>
                  </Select>
                  <Table
                    rowKey="id"
                    dataSource={materialTableData}
                    columns={columns}
                    loading={materialLoading}
                    onChange={this.handleOnChangeTableListener}
                    pagination={{ total: materialTablePagination.total, current: materialTablePagination.current_page, pageSize: materialTablePagination.per_page || 10 }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
        {showWarningModal && 
          <Modal
          visible={showWarningModal}
          title=""
          closable={false}
          maskClosable={false}
          onOk={this.handleChangeStatus}
          onCancel={ () => {
            this.setState({
              showWarningModal: false,
              changeStatusData: {}
            })
          } }
        >
          <p>
            <Icon type="exclamation-circle" 
                  theme="twoTone" 
                  twoToneColor="#faad14"/> Before updating, please make sure to update the mapping in SAP to avoid issues when creating sales order
          </p>
        </Modal>
        }
        {showFormModal &&
          <ModalForm
            showFormModal={showFormModal}
            handleCloseModal={this.handleCloseModal}
            handleSubmit={this.handleSubmit}
            imageUploader={this.imageUploader}
            selectedRow={selectedRow}
            getFieldDecorator={getFieldDecorator}
            isLoading={materialLoading || isImageUploading}
            products={this.activeProducts}
            loadActiveProducts={this.props.onLoadActiveProductData}
            isProductLoading={productLoading}
          />
        }


        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </div >
    ) : (
      <Result
        status="warning"
        title="You have no permission to view this page."
      />
    );
  }
}

const MaterialForm = Form.create({ name: 'product_variant_form' })(Material);

export default MaterialForm;
