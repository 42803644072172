import Axios from 'axios'
import { headers } from './token'
import { serialize } from './helper'
const API_URL = process.env.REACT_APP_API_URL

const axios = {

  customGet: (url, dispatch, type, data = undefined) => {
    const queryString = data !== undefined ? serialize(data) : null;
    const newUrl = `${url}${queryString ? queryString : ''}`;

    dispatch({
      type: type
    });

    return Axios.get(newUrl)
      .then(function (response) {
        dispatch({
          type: `${type}_FULFILLED`,
          payload: response.data
        })
        return { error: false, response, type: `${type}_FULFILLED`, };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_REJECTED`,
          payload: error
        })
        return { error: true, response: error, type: `${type}_REJECTED`, };
      })
  },

  customPut: (url, dispatch, type, data = {}, hasHeader = true) => {
    dispatch({
      type: type
    });

    return Axios.put(url, data, hasHeader ? { headers: headers } : {})
      .then(function (response) {
        dispatch({
          type: `${type}_FULFILLED`,
          payload: response.data
        })
        return { error: false, response, type: `${type}_FULFILLED` };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_REJECTED`,
          payload: error
        })
        return { error: true, response: error, type: `${type}_REJECTED` };
      })
  },

  // 
  // @params url = string
  // @params dispatch = method - redux dispatcj
  // @params type = string - action type
  // @params data = object - optional / default value is undefined
  // @params hasHeader = boolean - optional / default value is true
  // 
  get: (url, dispatch, type, data = undefined, hasHeader = true, customConfig = null) => {
    const queryString = data !== undefined ? serialize(data) : null;
    const newUrl = `${API_URL}${url}${queryString ? queryString : ''}`;

    dispatch({
      type: type
    });

    const config = customConfig ? customConfig : { headers: headers }

    return Axios.get(newUrl, hasHeader ? config : {})
      .then(function (response) {
        dispatch({
          type: `${type}_FULFILLED`,
          payload: response.data
        })
        return { error: false, response, type: `${type}_FULFILLED`, };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_REJECTED`,
          payload: error
        })
        return { error: true, response: error, type: `${type}_REJECTED`, };
      })
  },

  // 
  // @params url = string
  // @params dispatch = method - redux dispatcj
  // @params type = string - action type
  // @params data = object - optional / default value is {}
  // @params hasHeader = boolean - optional / default value is true
  // 
  post: (url, dispatch, type, data = {}, hasHeader = true) => {
    // dispatch({
    //   type: type
    // });
    const newUrl = `${API_URL}${url}`;

    dispatch({
      type: type
    });

    return Axios.post(newUrl, data, hasHeader ? { headers: headers } : {})
      .then(function (response) {
        dispatch({
          type: `${type}_FULFILLED`,
          payload: response.data
        })
        return { error: false, response, type: `${type}_FULFILLED` };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_REJECTED`,
          payload: error
        })
        return { error: true, response: error, type: `${type}_REJECTED` };
      })
  },
  put: (url, dispatch, type, data = {}, hasHeader = true) => {
    // dispatch({
    //   type: type
    // });
    const newUrl = `${API_URL}${url}`;

    dispatch({
      type: type
    });

    return Axios.put(newUrl, data, hasHeader ? { headers: headers } : {})
      .then(function (response) {
        dispatch({
          type: `${type}_FULFILLED`,
          payload: response.data
        })
        return { error: false, response, type: `${type}_FULFILLED` };
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_REJECTED`,
          payload: error
        })
        return { error: true, response: error, type: `${type}_REJECTED` };
      })
  },


}

export default axios;