import React, { Component } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { Row, Col, Card, Layout, Skeleton, Spin, Result, Typography, Button, Select, Icon, Modal, Checkbox, DatePicker, Badge, Divider, Tooltip } from 'antd'
import moment, { isDate } from 'moment';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import Papa from 'papaparse'
import { PDFDocument, StandardFonts } from 'pdf-lib'

import './Dashboard.scss'
import downloadIcon from '../../assets/images/download-icon.png';
import resetIcon from '../../assets/images/reset-icon.png';

const wb = new ExcelJS.Workbook()

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const plainOptions = ['EXCEL', 'CSV', 'PDF'];

export default class Dashboard extends Component {
    state = {
        month: "today",
        // customer: "all",
        // country: "all",
        selectedCountries: ['all'],
        selectedCustomers: ['all'],
        selectedETD: ["all"],
        showDownloadModal: false,
        showCustomDateModal: false,
        indeterminate: false,
        checkAll: false,
        checkedList: [],
        startDate: moment().format('MM-DD-YYYY'),
        endDate: moment().format('MM-DD-YYYY'),
        rangePicker: [],
        disableToday: true,
        disableWeek: false,
        disableMonth: false,
        disableYear: false,
    }

    removeAllOnly = (array, item) => {
        for (let i in array) {
            if (array[i] == item) {
                array.splice(i, 1);
            }
        }
    }

    removeOther = (array) => {
        for (let i in array) {
            if (array[i] !== 'all') {
                array.splice(i, array.length - 1);
            }
        }
    }

    // quickClear = async () => {
    //     this.setState({ startDate:  })
    //     this.setState({ endDate: null })

    //     await this.setState({ startDate: null })
    //     await this.setState({ endDate: null })
    // }

    filterCountries = async selectedCountries => {
        await this.setState({ selectedCountries: [] })
        await this.setState({ selectedCountries });
        // this.handleMonth(this.state.month)
    }

    filterCustomers = async selectedCustomers => {
        await this.setState({ selectedCustomers: [] })
        await this.setState({ selectedCustomers })
        // this.handleMonth(this.state.month)
    }

    filterETD = async selectedETD => {
        await this.setState({ selectedETD: [] })
        await this.setState({ selectedETD })
        // this.handleMonth(this.state.month)
    }

    onChange = checkedList => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
    };

    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    numberFormat = (amount, decimalPlace = 2) => {
        if (isNaN(amount)) {
            return amount;
        }

        const pieces = parseFloat(amount)
            .toFixed(decimalPlace)
            .split("");
        let ii = pieces.length - 3;
        while ((ii -= 3) > 0) {
            pieces.splice(ii, 0, ",");
        }

        return pieces.join("");
    };

    getTotal = (data, column) => data.reduce((a, c) => a + parseFloat(c[column]), 0)

    groupBy = (data, key) => {
        return data.reduce(function (storage, item) {
            var group = item[key];
            storage[group] = storage[group] || [];
            storage[group].push(item);
            return storage;
        }, {})
    };

    customGroup = (data, key) => {
        return data.reduce(function (storage, item) {
            var group = item[key];
            storage[group] = storage[group] || [];
            storage[group].push(item);
            return storage;
        }, {})
    }

    handleMonth = async (value) => {
        const today = moment().format('MM-DD-YYYY');
        const startOfWeek = moment().startOf('week').format('MM-DD-YYYY');
        const endOfWeek = moment().endOf('week').format('MM-DD-YYYY');
        const startOfMonth = moment().startOf('month').format('MM-DD-YYYY')
        const endOfMonth = moment().endOf('month').format('MM-DD-YYYY')
        const startOfYear = moment().startOf('year').format('MM-DD-YYYY')
        // const endOfYear = moment().endOf('year').format('MM-DD-YYYY')
        // commenting the code above, as per the discussion if the date is in this year, then 
        // display the current month and not the last month of the year.
        const endOfYear = moment(new Date()).format('MM-DD-YYYY')


        let startDate, endDate;

        if (value === "today") {
            startDate = today
            endDate = today
            this.setState({ disableToday: true })
            this.setState({ disableWeek: false })
            this.setState({ disableMonth: false })
            this.setState({ disableYear: false })
            this.setState({ selectedCustomers: ['all'] })
            this.setState({ selectedCountries: ['all'] })
            this.setState({ selectedETD: ['all'] })
        } else if (value === "this week") {
            startDate = startOfWeek
            endDate = endOfWeek
            this.setState({ disableToday: false })
            this.setState({ disableWeek: true })
            this.setState({ disableMonth: false })
            this.setState({ disableYear: false })
            this.setState({ selectedCustomers: ['all'] })
            this.setState({ selectedCountries: ['all'] })
            this.setState({ selectedETD: ['all'] })
        } else if (value === "this month") {
            startDate = startOfMonth
            endDate = endOfMonth
            this.setState({ disableToday: false })
            this.setState({ disableWeek: false })
            this.setState({ disableMonth: true })
            this.setState({ disableYear: false })
            this.setState({ selectedCustomers: ['all'] })
            this.setState({ selectedCountries: ['all'] })
            this.setState({ selectedETD: ['all'] })
        } else if (value === "this year") {
            await this.setState({ startDate: null })
            await this.setState({ endDate: null })
            this.setState({ disableToday: false })
            this.setState({ disableWeek: false })
            this.setState({ disableMonth: false })
            this.setState({ disableYear: true })
            this.setState({ selectedCustomers: ['all'] })
            this.setState({ selectedCountries: ['all'] })
            this.setState({ selectedETD: ['all'] })
            startDate = startOfYear
            endDate = endOfYear

        } else {
            startDate = null
            endDate = null
            this.setState({ disableToday: false })
            this.setState({ disableWeek: false })
            this.setState({ disableYear: false })
            this.setState({ disableMonth: false })
            this.setState({ selectedCustomers: ['all'] })
            this.setState({ selectedCountries: ['all'] })
            this.setState({ selectedETD: ['all'] })
        }

        this.setState({
            month: value,
            showCustomDateModal: value === "custom date",
            rangePicker: [],
            startDate,
            endDate
        })
    }
    handleCustomDate = async (startDateValue, endDateValue) => {
        await this.setState({
            showCustomDateModal: false,
            startDate: startDateValue,
            endDate: endDateValue
        })
    }

    removeDuplicates = (myArr, prop) => {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    filterData = (data, predicate) => {
        // if no data is sent in, return null, otherwise transform the data
        return !!!data ? null : data.reduce((list, entry) => {
            let clone = null;
            if (predicate(entry)) {
                // if the object matches the filter, clone it as it is
                clone = Object.assign({}, entry)
            } else if (entry.order != null) {
                // if the object has orders, filter the list of order
                let order = this.filterData(entry.order, predicate)
                if (order.length > 0) {
                    // if any of the order matches, clone the parent object, overwrite
                    // the order list with the filtered list
                    clone = Object.assign({}, entry, { order: order })
                }
            }
            // if there's a cloned object, push it to the output list
            clone && list.push(clone)
            return list
        }, [])

    }

    exportExcel = async (data) => {
        const worksheet = wb.addWorksheet("OMS_ANALYTICS_DASHBOARD_REPORT")

        // Width of column
        worksheet.getColumn('A').width = 16
        worksheet.getColumn('B').width = 16
        worksheet.getColumn('C').width = 20
        worksheet.getColumn('D').width = 25
        worksheet.getColumn('E').width = 20
        worksheet.getColumn('F').width = 16
        worksheet.getColumn('G').width = 16

        // Header
        worksheet.mergeCells('A1:G1');
        worksheet.getCell('A1').value = 'OMS ANALYTICS DASHBOARD REPORT';
        worksheet.getCell('A1').alignment = { horizontal: 'center' };
        worksheet.getCell('A1').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('A2').value = 'PO NUMBER';
        worksheet.getCell('A2').alignment = { horizontal: 'center' };
        worksheet.getCell('A2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('B2').value = 'CUSTOMER CODE';
        worksheet.getCell('B2').alignment = { horizontal: 'center' };
        worksheet.getCell('B2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('B2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('C2').value = 'CUSTOMER NAME';
        worksheet.getCell('C2').alignment = { horizontal: 'center' };
        worksheet.getCell('C2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('C2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('D2').value = 'TOTAL AMOUNT(IN PHP)';
        worksheet.getCell('D2').alignment = { horizontal: 'center' };
        worksheet.getCell('D2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('D2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('E2').value = 'ORDERED QUANTITY';
        worksheet.getCell('E2').alignment = { horizontal: 'center' };
        worksheet.getCell('E2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('E2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('F2').value = 'TARGET ETD';
        worksheet.getCell('F2').alignment = { horizontal: 'center' };
        worksheet.getCell('F2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('F2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheet.getCell('G2').value = 'PO DATE';
        worksheet.getCell('G2').alignment = { horizontal: 'center' };
        worksheet.getCell('G2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheet.getCell('G2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        const lastRow = 3
        data.map((value, i) => {
            worksheet.getCell((`A${lastRow + i}`).toString()).value = value.po_number;
            worksheet.getCell((`B${lastRow + i}`).toString()).value = value.customer_code;
            worksheet.getCell((`C${lastRow + i}`).toString()).value = value.customer_name;
            worksheet.getCell((`D${lastRow + i}`).toString()).value = `PHP ${this.numberFormat(value.total_amount)}`;
            worksheet.getCell((`E${lastRow + i}`).toString()).value = value.ordered_quantity.toLocaleString();
            worksheet.getCell((`F${lastRow + i}`).toString()).value = value.etd;
            worksheet.getCell((`G${lastRow + i}`).toString()).value = value.po_date;
        })


        let totalRow = lastRow + data.length
        worksheet.getCell(`A${totalRow}`).value = 'TOTAL';
        worksheet.getCell(`A${totalRow}`).font = { bold: true };

        worksheet.getCell((`D${totalRow}`).toString()).value = `PHP${this.numberFormat(this.getTotal(data, "total_amount"))}`;
        worksheet.getCell(`D${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' };
        worksheet.getCell(`D${totalRow}`).font = { bold: true };

        worksheet.getCell((`E${totalRow}`).toString()).value = this.getTotal(data, "ordered_quantity").toLocaleString();
        worksheet.getCell(`E${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' };
        worksheet.getCell(`E${totalRow}`).font = { bold: true };

        const excelData = await wb.xlsx.writeBuffer()

        saveAs(new Blob([excelData]), 'oms-dashboard-report.xlsx')
        wb.removeWorksheet(worksheet.id)
    }

    exportCSV = (data) => {
        const csv = Papa.unparse({
            "fields": ["PO NUMBER", "CUSTOMER CODE", "CUSTOMER NAME", "TOTAL AMOUNT (IN PHP)", "ORDERED QUANTITY", "TARGET ETD", "PO DATE"],
            "data": data.map((value) => {
                return [
                    value.po_number,
                    value.customer_code,
                    value.customer_name,
                    `PHP${this.numberFormat(value.total_amount)}`,
                    value.ordered_quantity.toLocaleString(),
                    value.etd,
                    value.po_date
                ]
            })
        });

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "oms-dashboard-report.csv");
    }

    exportPDF = async (data) => {
        const pdfDoc = await PDFDocument.create()
        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Split page by 80
        let pageArray = this.splitArrayIntoChunksOfLen(data, 80)


        let pageCount = 0
        pageArray.map((value, i) => {
            if (value == null) return
            const page = pdfDoc.addPage()
            const { height } = page.getSize()
            page.moveTo(110, 200);

            page.drawText('#', { x: 30, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('PO NUMBER', { x: 50, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CUST CODE', { x: 110, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CUST NAME', { x: 170, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('TOTAL AMOUNT', { x: 280, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('TOTAL QTY', { x: 370, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('TARGET ETD', { x: 440, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('PO DATE', { x: 520, y: height - 4 * 9, size: 9, font: timesRomanFont })

            value.map((value, i) => {
                if (value == null) return
                pageCount++
                page.drawText((pageCount).toString(), { x: 30, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.po_number}`, { x: 50, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.customer_code}`, { x: 110, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.customer_name.length > 18 ? `${value.customer_name.substring(0, 18)}...` : value.customer_name}`, { x: 170, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`PHP${this.numberFormat(value.total_amount)}`, { x: 280, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.ordered_quantity.toLocaleString()}`, { x: 370, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.etd}`, { x: 440, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
                page.drawText(`${value.po_date}`, { x: 520, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })


                if (Number(data.length) === Number(pageCount)) {
                    page.drawText(`TOTAL`, { x: 190, y: height - (8 + i) * 9, size: 10, font: timesRomanFont })
                    page.drawText(`PHP${this.numberFormat(this.getTotal(data, "total_amount"))}`, { x: 280, y: height - (8 + i) * 9, size: 10, font: timesRomanFont })
                    page.drawText(`${this.getTotal(data, "ordered_quantity").toLocaleString()}`, { x: 370, y: height - (8 + i) * 9, size: 10, font: timesRomanFont })
                }
            })
        })

        const pdfBytes = await pdfDoc.save()
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        saveAs(pdfBlob, "oms-dashboard-report.pdf");
    }

    handleDownload = (data) => {
        const { checkedList } = this.state
        checkedList.includes('EXCEL') && this.exportExcel(data);
        checkedList.includes('CSV') && this.exportCSV(data);
        checkedList.includes('PDF') && this.exportPDF(data);
    }

    getNameById = (id) => {
        const distributor = this.props.dashboardData.find(value => value.id === Number(id))
        return distributor && distributor.name ? distributor.name.replace(/[^A-Z0-9]+/ig, '').substring(0, 3).toUpperCase() : ""
    }

    kFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(0) + 'K';
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num < 1000) {
            return num;
        }
    }

    splitArrayIntoChunksOfLen = (arr, len) => {
        let chunks = [], i = 0, n = arr.length;
        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    }

    componentDidMount() {
        this.props.fetchPermissions()
        this.props.fetchDashboardData()
        this.props.fetchDistributorTotalActive()
    }

    render() {
        const { distributorTotalActiveData: { active_customer, total_customer } } = this.props
        const {
            myPermissions,
            myPermissionsLoading,
            dashboardLoading
        } = this.props
        let {
            dashboardData
        } = this.props
        const { month, showDownloadModal, showCustomDateModal, startDate, endDate, rangePicker, checkedList, selectedCountries, selectedCustomers, selectedETD } = this.state


        dashboardData = dashboardData
            .filter(value => value.order.length > 0)
        // .filter(value => value.country !== undefined)
        // .filter(customer => {
        //     let orderStatus
        //     customer.order.map(order => {
        //         return orderStatus = order.status
        //     })
        //     return orderStatus !== 0
        // })
        // .filter(value => value.created_at !== null)


        let filteredDashboardData = dashboardData

        filteredDashboardData = this.filterData(dashboardData, function (item) {
            return item.created_at && moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
        })

        // let testingData = filteredDashboardData.flatMap(value => value.order).filter(value => value.amount !== 0)

        // .sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())

        // filteredDashboardData = customer === "all" ? filteredDashboardData : filteredDashboardData.filter(value => value.id === customer)
        // filteredDashboardData = country === "all" ? filteredDashboardData : filteredDashboardData.filter(value => value.country === country)


        const customerData = this.filterData(dashboardData, function (item) {
            return item.created_at && moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
        })
        let customerList = this.removeDuplicates(customerData.filter(customer => !customer.order.every(order => order.status === 0)), "name")

        const countryData = this.filterData(dashboardData, function (item) {
            return item.created_at && moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
        })
        let countryList = this.removeDuplicates(countryData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")


        const etdData = this.filterData(dashboardData, function (item) {
            return item.created_at && moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
        })


        var monthNames = {
            "January": 1,
            "February": 2,
            "March": 3,
            "April": 4,
            "May": 5,
            "June": 6,
            "July": 7,
            "August": 8,
            "September": 9,
            "October": 10,
            "November": 11,
            "December": 12
        };

        let etdList = this.removeDuplicates(etdData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")
            .sort((a, b) => {
                var aa = a.estimated_delivery.split(' '),
                    bb = b.estimated_delivery.split(' ');
                return monthNames[aa[0]] - monthNames[bb[0]];
            })


        const customerSelectAll = async () => {
            await this.setState({ selectedCustomers: [] })
            await this.setState({ selectedCustomers: customerList.map(data => data.name) })
        }
        const customerDeselectAll = async () => {
            await this.setState({ selectedCustomers: [] })
        }

        const countrySelectAll = async () => {
            await this.setState({ selectedCountries: [] })
            await this.setState({ selectedCountries: countryList.map(data => data.country) })
        }
        const countryDeselectAll = async () => {
            await this.setState({ selectedCountries: [] })
        }

        const etdSelectAll = async () => {
            await this.setState({ selectedETD: [] })
            await this.setState({ selectedETD: etdList.map(data => data.estimated_delivery) })
        }
        const etdDeselectAll = async () => {
            await this.setState({ selectedETD: [] })
        }

        // Dashboard Data: Customer Filtering
        selectedCustomers.includes("all") && selectedCustomers.length === 1
            ? filteredDashboardData = filteredDashboardData
            : filteredDashboardData = filteredDashboardData.filter(value => selectedCustomers.includes(value.name))

        if (selectedCustomers[0] === 'all' && selectedCustomers.length > 1) {
            selectedCustomers.splice(selectedCustomers.indexOf('all'), 1)
            filteredDashboardData = filteredDashboardData.filter(value => selectedCustomers.includes(value.name))

            countryList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")
            etdList = this.removeDuplicates(filteredDashboardData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")

        } else if (selectedCustomers[selectedCustomers.length - 1] === 'all' && selectedCustomers.length > 1) {
            selectedCustomers.splice(0, selectedCustomers.length, 'all')

            filteredDashboardData = this.filterData(filteredDashboardData, function (item) {
                return item.created_at && moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
            });

            countryList = this.removeDuplicates(countryData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")
            etdList = this.removeDuplicates(etdData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")

        } else if (selectedCustomers.length > 0 && !selectedCustomers.includes('all')) {
            filteredDashboardData = filteredDashboardData.filter(value => selectedCustomers.includes(value.name))
            countryList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")
            etdList = this.removeDuplicates(filteredDashboardData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")

        } else if (selectedCustomers.length === 0) {
            filteredDashboardData = filteredDashboardData.filter(value => selectedCustomers.includes(value.name))
            countryList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")
            etdList = this.removeDuplicates(filteredDashboardData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")
        }

        // Dashboard Data: Country Filtering

        selectedCountries.includes("all") && selectedCountries.length === 1
            ? filteredDashboardData = filteredDashboardData
            : filteredDashboardData = filteredDashboardData.filter(value => selectedCountries.includes(value.country))

        if (selectedCountries[0] === 'all' && selectedCountries.length > 1) {
            selectedCountries.splice(selectedCountries.indexOf('all'), 1)

            filteredDashboardData = filteredDashboardData.filter(value => selectedCountries.includes(value.country))


            customerList = this.removeDuplicates(filteredDashboardData.filter(customer => !customer.order.every(order => order.status === 0)), "name")
            etdList = this.removeDuplicates(filteredDashboardData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")


        } else if (selectedCountries[selectedCountries.length - 1] === 'all' && selectedCountries.length > 1) {
            selectedCountries.splice(0, selectedCountries.length, 'all')

            filteredDashboardData = filteredDashboardData.filter(value => selectedCountries.includes(value.country))

            customerList = this.removeDuplicates(customerData.filter(customer => !customer.order.every(order => order.status === 0)), "name")
            etdList = this.removeDuplicates(etdData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")

        } else if (selectedCountries.length > 0 && !selectedCountries.includes("all")) {
            // filteredDashboardData = filteredDashboardData.filter(value => selectedCountries.includes(value.country))

            filteredDashboardData = filteredDashboardData.filter(value => selectedCountries.includes(value.country))

            customerList = this.removeDuplicates(filteredDashboardData.filter(customer => !customer.order.every(order => order.status === 0)), "name")
            etdList = this.removeDuplicates(filteredDashboardData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")
        } else if (selectedCountries.length === 0) {
            filteredDashboardData = filteredDashboardData.filter(value => selectedCountries.includes(value.country))
            customerList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "name")
            etdList = this.removeDuplicates(filteredDashboardData.flatMap(value => value.order.filter(order => order.status !== 0).filter(order => !!order.estimated_delivery)), "estimated_delivery")
        }


        // Dashboard Data: ETD Filtering
        selectedETD.includes("all") && selectedETD.length === 1
            ? filteredDashboardData = filteredDashboardData
            : filteredDashboardData = filteredDashboardData.filter(data => data.order.some(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all')))

        if (selectedETD[0] === 'all' && selectedETD.length > 1) {
            selectedETD.splice(selectedETD.indexOf('all'), 1)
            filteredDashboardData = filteredDashboardData.filter(data => data.order.some(order => selectedETD.includes(order.estimated_delivery || selectedETD.includes('all'))))
            countryList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")
            customerList = this.removeDuplicates(filteredDashboardData.filter(customer => !customer.order.every(order => order.status === 0)), "name")

        } else if (selectedETD[selectedETD.length - 1] === 'all' && selectedETD.length > 1) {
            selectedETD.splice(0, selectedETD.length, 'all')

            filteredDashboardData = this.filterData(filteredDashboardData, function (item) {
                return item.created_at && moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
            });

            countryList = this.removeDuplicates(countryData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")
            customerList = this.removeDuplicates(customerData.filter(customer => !customer.order.every(order => order.status === 0)), "name")

        } else if (selectedETD.length > 0 && !selectedETD.includes("all")) {
            filteredDashboardData.filter(data => data.order.some(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all')))
            customerList = this.removeDuplicates(filteredDashboardData.filter(customer => !customer.order.every(order => order.status === 0)), "name")
            countryList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")

        } else if (selectedETD.length === 0) {
            filteredDashboardData.filter(data => data.order.some(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all')))
            customerList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "name")
            countryList = this.removeDuplicates(filteredDashboardData.filter(value => !!value.country).filter(country => !country.order.every(order => order.status === 0)), "country")

        }

        // .filter(obj => {
        //     obj.list = obj.list.filter(el => el > 2);
        //     return obj.list.length > 0; // Technically just `return obj.list.length;` would work; I prefer the clarity of the comparison
        //   });

        // function one(callback) {
        //     return new Promise(function(resolve, reject) {
        //       setTimeout(function() {
        //         callback();
        //       }, 500);
        //     })
        //   }

        //   function two() {
        //   }

        //   one(two)


        // const customerList = filteredDashboardData.filter(value => selectedCountries.includes(value.country))

        // countryData.filter(value => selectedCustomers.includes(value.name))
        // customerData.filter(value => selectedCountries.includes(value.country))


        // if (selectedCustomers.length > 0) {
        //     customerList = customer
        // }


        // .sort((a, b) => {
        //     const x = a.country.toLowerCase();
        //     const y = b.country.toLowerCase();
        //     if (x < y) { return -1; }
        //     if (x > y) { return 1; }
        //     return 0;
        // })


        // .sort((a, b) =>
        // {
        //     var aa = moment().add(a.etd_month, 'months').format("MMM YYYY").split('-'),
        //         bb = moment().add(b.etd_month, 'months').format("MMM YYYY").split('-');
        //     return monthNames[aa[0]] - monthNames[bb[0]];
        // })
        // .sort((a, b) => b.etd_month-a.etd_month)

        // const activeUsers = filteredDashboardData.length
        // const activeUsers = this.removeDuplicates(filteredDashboardData, "id").length
        // const totalUsers = this.removeDuplicates(filteredDashboardData, "id").length
        const activeUsers = this.removeDuplicates(filteredDashboardData, "id").filter(data => data.order_count !== 0).length
        // const totalUsers = this.removeDuplicates(filteredDashboardData, "id").length
        const totalUsers = this.props.distributorTotalActiveData.total_customer

        const feedbackData = filteredDashboardData.flatMap(value => value.feedbacks)
        const feedbackRate = (filteredDashboardData.filter(value => value.feedbacks.length > 0).length / feedbackData.length).toFixed(2)
        const feedbackAverage = (this.getTotal(feedbackData, "feedback_score") / feedbackData.length).toFixed(2)

        const orders = filteredDashboardData.flatMap(value => value.order.filter(value => moment(moment(value.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')).filter(order => order.status !== 0)).sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()).filter(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all'))
        const products = orders.flatMap(value => value.product)
        const totalOrderQuantity = this.getTotal(products, "quantity")
        const totalOrderValue = this.getTotal(products.map(value => { return { amount: parseFloat((value.quantity || 0) * (value.selling_price || 0)) } }), "amount")

        const poCountBarOthers = filteredDashboardData.map(value => {
            return {

                name: value.name,
                po_count: value.order.filter(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all')).filter(order => order.status !== 0).length
            }
        }).filter(value => value.po_count !== 0).sort((a, b) => a.po_count - b.po_count).slice(0, 8)

        const partialOthersCount = filteredDashboardData.flatMap(value => {
            return { name: value.name.replace(value.name, 'Others'), po_count: value.order.filter(order => selectedETD.includes(order.estimated_delivery) || order).filter(order => order.status !== 0).length }
        }).filter(value => value.po_count !== 0).sort((a, b) => a.po_count - b.po_count).slice(8, filteredDashboardData.length)

        const totalOthersCount = {
            name: "Others",
            po_count: this.getTotal(partialOthersCount, "po_count")
        }

        poCountBarOthers.push(totalOthersCount)

        const poCountBar = filteredDashboardData.map(value => {
            return { name: value.name, po_count: value.order.filter(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all')).filter(order => order.status !== 0).length }
        }).filter(value => value.po_count !== 0).sort((a, b) => a.po_count - b.po_count)

        // .sort((a, b) => a.name.localeCompare(b.name))

        const orderPlacement = orders.filter(value => selectedETD.includes(value.estimated_delivery) || selectedETD.includes('all')).map(value => {
            return {
                id: value.distributor_id,
                po_date: moment(value.created_at).format("MMM"),
                amount: this.getTotal(value.product.map(item => { return { amount: parseFloat((item.quantity || 0) * (item.selling_price || 0)) } }), "amount")
            }
        }).filter(value => value.amount !== 0)


        const groupByID = this.groupBy(orderPlacement, "id")
        const groupByMonth = this.groupBy(orderPlacement, "po_date")

        const orderPlacementBar = Object.keys(groupByID).map((key) => {
            let date = Object.entries(this.groupBy(groupByID[key], 'po_date')).map(([key, value]) => { return { date: key, amount: this.getTotal(value, "amount") } })
            return { id: key, date, totalAmount: this.getTotal(date, "amount") }
        }).sort((a, b) => b.totalAmount - a.totalAmount)

        const orderPlacementTop8 = orderPlacementBar.slice(0, 8)

        const orderPlacementOthers = Object.keys(groupByID).map((key) => {
            let date = Object.entries(this.groupBy(groupByID[key], 'po_date')).map(([key, value]) => { return { date: key, amount: this.getTotal(value, "amount") } })
            return { id: 'Others', date, totalAmount: this.getTotal(date, "amount") }
        }).sort((a, b) => b.totalAmount - a.totalAmount).slice(8, orderPlacementBar.length)

        const mergedOrders = [...orderPlacementTop8, ...orderPlacementOthers]

        let downloadData = {}
        downloadData = orders.filter(value => moment(moment(value.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')).filter(value => value.status !== 0).filter(order => selectedETD.includes(order.estimated_delivery) || selectedETD.includes('all')).map(value => {
            const distributor = filteredDashboardData.find(d => Number(d.id) === Number(value.distributor_id))
            return {
                po_number: value.po_reference,
                customer_code: distributor.customer_code,
                customer_name: distributor.name,
                total_amount: this.getTotal(value.product.map(value => { return { amount: parseFloat((value.quantity || 0) * (value.selling_price || 0)) } }), "amount"),
                ordered_quantity: this.getTotal(value.product, "quantity"),
                etd: moment(value.estimated_delivery).format("MMMM YYYY"),
                po_date: moment(value.created_at).format("MM-DD-YYYY"),
            }
        })

        const colors = [
            '#35A0FF', // top 1
            '#1F3BB3', // top 2
            '#78D89F', // top 3
            '#59BFB9', // top 4
            '#7AC6FF', // top 5
            '#0496FF', // top 6
            '#9381FF', // top 7
            '#4361EE', // top 8
            '#CED298', // others
        ]

        const getAmountPerMonth = (groupByMonth, value) => {
            const amountArray = []
            groupByMonth.map(month => {
                const isFound = value.find((data) => data.date === month)
                if (isFound) amountArray.push(isFound.amount)
                else amountArray.push(0)
            })
            return amountArray
        }

        const orderPlacementdata = {
            labels: Object.keys(groupByMonth),
            datasets:
                orderPlacementBar.length > 8 ?
                    mergedOrders.map((value, index) => {
                        return {
                            label: this.getNameById(value.id) || value.id,
                            // data: value.date.map(value => value.amount),
                            data: getAmountPerMonth(Object.keys(groupByMonth), value.date.map(value => value)),
                            backgroundColor: colors[index] || colors[8],
                        }
                    }) :
                    orderPlacementBar.map((value, index) => {
                        return {
                            label: this.getNameById(value.id) || value.id,
                            // data: value.date.map(value => value.amount),
                            data: getAmountPerMonth(Object.keys(groupByMonth), value.date.map(value => value)),
                            backgroundColor: colors[index],
                        }
                    })
        };

        const poCountdata = {
            labels: poCountBar.length > 8 ? poCountBarOthers.map(value => value.name.replace(/[^A-Z0-9]+/ig, '').substring(0, 3).toUpperCase().replace('OTH', 'Others')) : poCountBar.map(value => value.name.replace(/[^A-Z0-9]+/ig, '').substring(0, 3).toUpperCase()).slice(0, 8),
            datasets: [
                {
                    label: 'PO Count',
                    backgroundColor: '#7AC6FF',
                    borderColor: '#7AC6FF',
                    borderWidth: 1,
                    hoverBackgroundColor: '#7AC6FF',
                    hoverBorderColor: '#7AC6FF',
                    data: poCountBar.length > 8 ? poCountBarOthers.map(value => value.po_count) : poCountBar.map(value => value.po_count)
                }
            ]
        };

        const orderPlacementOptions = {
            tooltips: {
                callbacks: {
                    label: (t, d) => {
                        return `${d.datasets[t.datasetIndex].label}: PHP ${this.numberFormat(Number(t.yLabel))}`
                    }
                }
            },
            legend: { display: false },
            scales: {
                xAxes: [{
                    stacked: true,
                    barPercentage: 0.5,
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        // min: 0,
                        // max: 5,
                        // stepSize: 1,
                        callback: value => {
                            parseFloat(value).toFixed(2)
                            // return this.kFormatter(value);
                            return (value.toFixed(2) < 1000000) ? value / 1000 + 'K' : value / 1000000 + 'M';
                        },
                        autoSkip: true,
                        maxTicksLimit: 10,
                        stepSize: .2
                    },
                    // ticks: {
                    //     max: 5,
                    //     min: 200000,
                    //     stepSize: 200000,
                    //     callback : function(value,index,array) {
                    //         return (value < 1000000) ? value/1000 + 'K' : value/1000000 + 'M';
                    //     }
                    // },
                    gridLines: {
                        borderDash: [8, 4],
                    },
                    stacked: true,
                    barPercentage: 2,
                }]
            }
        }
        const poCountOptions = {
            position: 'left',
            legend: { display: false },
            scales: {
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    barPercentage: 0.6,
                }],
                xAxes: [{
                    ticks: {
                        min: 0,
                        stepSize: 1,
                        maxTicksLimit: 10,
                        autoSkip: true
                    },
                    gridLines: {
                        borderDash: [8, 4],
                    }
                }]
            }
        }

        if (myPermissionsLoading) {
            // return (
            //     <Layout>
            //         <Skeleton active />
            //     </Layout>
            // )
            return null
        }

        return myPermissions.some(r => r.name === 'admin.dashboard.view') ? (
            <Layout>
                <Spin spinning={dashboardLoading}>
                    <Row type="flex" justify="center" className="admin-dashboard-page">
                        <Col span={22}>
                            <Row type="flex" align="middle" className="header">
                                <Col span={24}>
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            <Title level={3}>OMS Analytics Dashboard</Title>
                                        </Col>
                                        <Col>
                                            <Button
                                                disabled={!myPermissions.some(r => r.name === 'admin.analytics.download')}
                                                className="btn-small btn-primary"
                                                style={{ width: 175 }}
                                                onClick={() => this.setState({ showDownloadModal: true })}><img style={{ height: 16, width: 18, marginRight: 8, marginBottom: 2 }} src={downloadIcon} alt="download" /> Download File</Button>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24}>
                                    <Row type="flex" gutter={32} justify="end" align="bottom" className="filters">
                                        <Col xs={24} sm={24} md={8} lg={8} className="date">
                                            <p>DATE AS OF: <span>{moment().format("LL")}</span></p>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <p>DATE</p>
                                            <Select value={month} onSelect={(value) => this.handleMonth(value)} style={{ width: "100%" }} showSearch>
                                                <Option value="today" disabled={this.state.disableToday}>Today</Option>
                                                <Option value="this week" disabled={this.state.disableWeek}>This Week</Option>
                                                <Option value="this month" disabled={this.state.disableMonth}>This Month</Option>
                                                <Option value="this year" disabled={this.state.disableYear}>This Year</Option>
                                                <Option value="custom date">Custom Date</Option>
                                            </Select>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <p>CUSTOMER</p>
                                            <div onMouseDown={e => {
                                                e.preventDefault()
                                                return false
                                            }}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    listHeight={2}
                                                    allowClear={true}
                                                    value={selectedCustomers}
                                                    onChange={this.filterCustomers}
                                                    style={{ width: "100%", textTransform: "capitalize" }}
                                                    placeholder={"* Required"}
                                                    dropdownRender={menu => (
                                                        customerList.length > 0
                                                            ? (
                                                                <div>
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <a
                                                                            style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={() => customerSelectAll()}
                                                                        >
                                                                            <Icon type="select" /> Select All
                                                                    </a>
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <a
                                                                            style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={() => customerDeselectAll()}
                                                                        >
                                                                            <Icon type="close" /> Deselect All
                                                                    </a>
                                                                    </div>
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    {menu}
                                                                </div>
                                                            )
                                                            : menu
                                                    )}
                                                >
                                                    {/* <Tooltip placement="rightTop" title="Please deselect all before clicking All" visible={selectedCustomers.length !== 0 ? true : false}> */}
                                                    <Option value="all"
                                                        disabled={selectedCustomers.length !== 0 ? true : false}
                                                    >All</Option>
                                                    {/* </Tooltip> */}
                                                    {customerList.sort((a, b) => a.name.localeCompare(b.name)).map(value => (
                                                        <Option key={value.name} value={value.name}>{value.name}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <p>Country</p>
                                            <div onMouseDown={e => {
                                                e.preventDefault()
                                                return false
                                            }}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    allowClear={true}
                                                    value={selectedCountries}
                                                    onChange={this.filterCountries}
                                                    style={{ width: "100%", textTransform: "capitalize" }}
                                                    placeholder={"* Required"}
                                                    dropdownRender={menu => (
                                                        countryList.length > 0
                                                            ? (
                                                                <div>
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <a
                                                                            style={{ flex: 'none', padding: '4px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={() => countrySelectAll()}
                                                                        >
                                                                            <Icon type="select" /> Select All
                                                                        </a>
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <a
                                                                            style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={() => countryDeselectAll()}
                                                                        >
                                                                            <Icon type="close" /> Deselect All
                                                                    </a>
                                                                    </div>
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    {menu}
                                                                </div>
                                                            )
                                                            : menu
                                                    )}
                                                >
                                                    <Option value="all"
                                                        disabled={selectedCountries.length !== 0 ? true : false}
                                                    >All</Option>
                                                    {countryList.sort((a, b) => a.country.localeCompare(b.country)).map(value => (
                                                        <Option key={value.country} value={value.country}>{value.country}</Option>
                                                    )).sort((a, b) => a.country - b.country)}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <p>TARGET ETD</p>
                                            <div onMouseDown={e => {
                                                e.preventDefault()
                                                return false
                                            }}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    allowClear={true}
                                                    value={selectedETD}
                                                    onChange={this.filterETD}
                                                    style={{ width: "100%", textTransform: "capitalize" }}
                                                    placeholder={"* Required"}
                                                    dropdownRender={menu => (
                                                        etdList.length > 0
                                                            ? (
                                                                <div>
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <a
                                                                            style={{ flex: 'none', padding: '4px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={() => etdSelectAll()}
                                                                        >
                                                                            <Icon type="select" /> Select All
                                                                    </a>
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <a
                                                                            style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={() => etdDeselectAll()}
                                                                        >
                                                                            <Icon type="close" /> Deselect All
                                                                    </a>
                                                                    </div>
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    {menu}
                                                                </div>
                                                            )
                                                            : menu
                                                    )}
                                                >
                                                    <Option value="all"
                                                        disabled={selectedETD.length !== 0 ? true : false}
                                                    >All</Option>
                                                    {etdList.map(value => (
                                                        <Option key={value.estimated_delivery} value={value.estimated_delivery}>{moment(value.estimated_delivery).format('MMM YYYY')}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <Button className="btn-small btn-default" onClick={() => this.setState({ month: 'today', customer: 'all', selectedCustomers: ['all'], selectedCountries: ['all'], selectedETD: ['all'], rangePicker: [], startDate: moment().format('MM-DD-YYYY'), endDate: moment().format('MM-DD-YYYY'), disableToday: true, disableWeek: false, disableMonth: false, disableYear: false })} style={{ width: "100%" }}><img style={{ marginBottom: 5, marginRight: 5 }} src={resetIcon} alt="reset" /> Reset Filter</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={22} className="summary-card">
                            <Card>
                                <Row type="flex">
                                    <Col span={6}><p>PHP {this.numberFormat(totalOrderValue)}</p><span>Total Value Placed<br />In OMS</span></Col>
                                    <Col span={6}><p>{totalOrderQuantity.toLocaleString()}</p><span>Total Order Quantity Placed<br />In OMS</span></Col>
                                    <Col span={4}><p>{orders.length.toLocaleString()}</p><span>Total PO<br />Submitted</span></Col>
                                    <Col span={4}><p>{activeUsers}</p><p>Active Customers</p><p></p><span>VS. {totalUsers} Total Customers <br />Customers OMS Usage</span></Col>
                                    <Col span={4}><p>{isNaN(feedbackAverage) ? 0 : feedbackAverage}</p><p>{isNaN(feedbackRate) ? 0 : Math.round(feedbackRate * 100)}% Response Rate</p><span>Average CSAT</span></Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col span={22}>
                            <Row type="flex" gutter={20}>
                                <Col span={12} style={{ margin: "16px 0px" }}>
                                    <Card title={<p>Orders Placed in OMS {startDate === endDate ? (<span>({moment(startDate).format('MMM DD')})</span>) : (<span>({moment(startDate).format('MMM DD')} - {moment(endDate).format('MMM DD')})</span>)}</p>} className="order-placement-card">
                                        <p>Value PHP</p>
                                        <Bar ref="chart" data={orderPlacementdata} options={orderPlacementOptions} />
                                        <Row type="flex" justify="end">
                                            <Col span={20}>
                                                <Row type="flex" justify="center">
                                                    {orderPlacementBar.length > 8 ?
                                                        mergedOrders.map((value, index) => (
                                                            <Col key={index} span={6}>
                                                                <Badge color={colors[index] || colors[8]} text={this.getNameById(value.id) || 'Others'} />
                                                            </Col>
                                                        )).slice(0, 9) :
                                                        orderPlacementBar.map((value, index) => (
                                                            <Col key={index} span={6}>
                                                                <Badge color={colors[index]} text={this.getNameById(value.id)} />
                                                            </Col>
                                                        ))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12} style={{ margin: "16px 0px" }}>
                                    <Card title="PO Count in OMS" style={{ minHeight: "393px" }}>
                                        <HorizontalBar ref="chart" width={100} height={60} data={poCountdata} options={poCountOptions} />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Modal
                        title="Download Option"
                        visible={showDownloadModal}
                        width={350}
                        onCancel={() => this.setState({ showDownloadModal: false })}
                        footer={null}
                        className="modal"
                    >
                        <Checkbox
                            indeterminate={this.state.indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={this.state.checkAll}
                        >
                            All
                    </Checkbox>
                        <br />
                        <CheckboxGroup
                            options={plainOptions}
                            value={checkedList}
                            onChange={this.onChange}
                        />
                        <Row>
                            <Col span={24}><Button type="link" onClick={() => this.setState({ checkedList: [], indeterminate: false, checkAll: false })}>Clear All</Button></Col>
                        </Row>

                        <Row type="flex" style={{ margin: "10px 0px" }}>
                            <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showDownloadModal: false, checkedList: [], indeterminate: false, checkAll: false })}>Cancel</Button></Col>
                            <Col span={12}><Button className="btn-small btn-primary" disabled={checkedList.length < 1} onClick={() => this.handleDownload(downloadData)}>Download</Button></Col>
                        </Row>
                    </Modal>
                    <Modal
                        width={400}
                        visible={showCustomDateModal}
                        onCancel={() => this.setState({ showCustomDateModal: false })}
                        footer={null}
                        className="modal"
                        closable={false}
                    >
                        <Row>
                            <Col span={24}>
                                <p>Period Covered:</p>
                                <RangePicker
                                    size="large"
                                    value={rangePicker}
                                    onCalendarChange={value => this.setState({ rangePicker: value })}
                                />
                            </Col>
                            <Col span={24}>
                                <Row type="flex" justify="end" style={{ marginTop: "20px" }}>
                                    <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showCustomDateModal: false })}>Cancel</Button></Col>
                                    <Col span={12}><Button className="btn-small btn-primary" disabled={rangePicker.length < 1} onClick={() => {
                                        this.handleCustomDate(moment(rangePicker[0]).format("MM-DD-YYYY"), moment(rangePicker[1]).format("MM-DD-YYYY"))
                                    }
                                    }>Apply</Button></Col>
                                </Row>
                            </Col>

                        </Row>


                    </Modal>
                </Spin>
            </Layout >
        ) : (
                <Result
                    status="warning"
                    title="You have no permission to view this page."
                />
            );
    }
}
