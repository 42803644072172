const initialData = {
  salesmanCodeLoading: false,
  salesmanCodeData: []
}

export default function salesman(state = initialData, action) {
  switch (action.type) {
    case 'GET_SALESMAN_CODE':
      return {
        ...state,
        salesmanCodeLoading: true
      }
    case 'GET_SALESMAN_CODE_FULFILLED':
      return {
        ...state,
        salesmanCodeData: action.payload,
        salesmanCodeLoading: false
      }
    case 'GET_SALESMAN_CODE_REJECTED':
      return {
        ...state,
        salesmanCodeData: [],
        salesmanCodeLoading: false
      }

    default:
      return {
        ...state
      }
  }
}
