import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import * as _ from "lodash";
import { Layout } from "antd";

import "./App.scss";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from './shared/Header'
import Sider from './shared/Sider'
import NotFound from './NotFound'
import Distributors from './Distributors'
import OrderManagement from './OrderManagement'
import Materials from './Materials'
import CustomerGroup from './CustomerGroup'
import AnalyticsDashboard from './Dashboard'
import Login from './Login'
import ResetPassword from './ResetPassword'
import ForgotPassword from './ForgotPassword'
import Homepage from './Pages/Homepage'
import Feedback from './Reports/Feedback/components'
import AdminManagement from './AdminManagement'
import UserTypes from './UserTypes'
import AccessAuditTrail from './AccessAuditTrail'
import FeaturesAuditTrail from './FeaturesAuditTrail'
import StorageLocation from './StorageLocation'

moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })
const genericErrors = {
  401: {
    title: "Resource not Found",
    message: "Unable to load resource"
  },
  500: {
    title: "Internal Server Error",
    message: "The server was unable to complete your request."
  },
  502: {
    title: "Bad Gateway",
    message: "The server was unable to complete your request."
  },
  503: {
    title: "Service Unavailable",
    message: "The server was unable to complete your request."
  }
};
const non_logging_paths = [
  {
    default: true,
    exact: true,
    slug: 'login',
    route: '/',
    label: 'Login',
    component: Login
  },
  {
    default: false,
    exact: false,
    slug: 'forgot-password',
    route: '/forgot-password',
    label: 'Forgot Password',
    component: ForgotPassword
  },
  {
    default: false,
    exact: false,
    slug: 'password/reset',
    route: '/password/reset/:token/:email',
    label: 'Reset Password',
    component: ResetPassword
  },
]

const paths = [
  {
    default: true,
    exact: true,
    slug: 'analytics-dashboard',
    route: '/',
    label: 'OMS Analytics Dashboard',
    component: AnalyticsDashboard
  }, 
  {
    default: false,
    exact: false,
    slug: 'customers',
    route: '/customers',
    label: 'Customers',
    icon: 'team',
    component: Distributors
  },
  {
    default: false,
    exact: false,
    slug: 'order-management',
    route: '/order-management',
    label: 'Order Management',
    icon: 'shop',
    component: OrderManagement
  },
  {
    default: false,
    exact: false,
    slug: 'materials',
    route: '/materials',
    label: 'Materials',
    icon: 'book',
    component: Materials
  },
  {
    default: false,
    exact: false,
    slug: 'customer-group',
    route: '/customer-group',
    label: 'Customer Group',
    icon: 'book',
    component: CustomerGroup
  },
  {
    default: false,
    exact: false,
    slug: 'storage-location',
    route: '/storage-location',
    label: 'Storage Location',
    icon: 'tags',
    component: StorageLocation
  },
  {
    default: true,
    exact: true,
    slug: 'homepage',
    route: '/homepage',
    label: 'Homepage',
    component: Homepage
  },
  {
    default: true,
    exact: true,
    slug: 'feedback',
    route: '/feedback',
    label: 'Feedback',
    component: Feedback
  },
  {
    default: false,
    exact: false,
    slug: 'admin-management',
    route: '/admin-management',
    label: 'Admin Management',
    component: AdminManagement
  },
  {
    default: false,
    exact: false,
    slug: 'access',
    route: '/audit-trail/access',
    label: 'Access Audit Trail',
    component: AccessAuditTrail,
  },
  {
    default: false,
    exact: false,
    slug: 'features',
    route: '/audit-trail/features',
    label: 'Feature Audit Trail',
    component: FeaturesAuditTrail,
  },
  {
    default: false,
    exact: false,
    slug: 'user-types',
    route: '/user-types',
    label: 'User Types',
    component: UserTypes
  },
]

const toastXHRError = code => {
  const error = genericErrors[code];
  if (error) {
    console.error(error.title, error.message);
  }
};

const isLocalStorageEmpty = () => {
  let strResult;
  if (localStorage.getItem("SESSION_TOKEN") !== null) {
    strResult = true;
  } else {
    strResult = false;
  }
  return strResult;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
    axios.interceptors.response.use(
      response => response,
      error => {
        switch (error.response.status) {
          case 401 /* signout() */:
            break;
          case 404:
            toastXHRError(404);
            break;
          case 500:
            toastXHRError(500);
            break;
          case 502:
            toastXHRError(502);
            break;
          case 503:
            toastXHRError(503);
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );

    axios.defaults.transformResponse = axios.defaults.transformResponse
      .concat
      // (response) => func(response)
      ();
  }

  componentDidMount() {
    if (isLocalStorageEmpty()) this.setState({ isLoggedIn: false });
    else this.setState({ isLoggedIn: true });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
      this.setState({ isLoggedIn: true });
      window.location.reload();
    }
  }

  render() {
    return this.state.isLoggedIn ? (
      // <Login />
      <Switch className="sub-layout__content">
        {/* // this will scroll to top when switch changes. */}
        {window.scrollTo(0, 0)}
        {_.map(non_logging_paths, path => (
          <Route
            key={path.slug}
            exact={path.exact}
            path={path.route}
            component={path.component}
          />
        ))}
        <Route component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    ) : (
        <Layout className="layout">
          <Header {...this.props} paths={paths} />
          <Layout.Content>
            <Layout className="sub-layout">
              <Sider {...this.props} paths={paths} />
              <Layout.Content>
                <Switch className="sub-layout__content">
                  {window.scrollTo(0, 0)}
                  {_.map(paths, path => (
                    <Route
                      key={path.slug}
                      exact={path.exact}
                      path={path.route}
                      component={path.component}
                    />
                  ))}
                  <Route component={NotFound} />
                  <Redirect to="/404" />
                </Switch>
              </Layout.Content>
            </Layout>
          </Layout.Content>
          {/* <Footer {...this.props} paths={paths} /> */}
        </Layout>
      );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.login.loggedIn
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(App)
);
