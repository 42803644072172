import React, { Component } from 'react'
import _ from 'lodash';
import { Row, Col, Button, Form, Modal, Layout, Input, Select } from 'antd';
const { Content } = Layout;

class ModalForm extends Component {
    state = {
        indeterminate: true,
    }

    handleClose = () => {
        this.props.handleCloseModal()
    }

    handleSubmit = (e) => {
        const { selectedRow, updateStorageLocation, addStorageLocation, form } = this.props;
        e.preventDefault();
        // update
        if (selectedRow) {
            form.validateFields(async (err, values) => {
                if (!err) {
                    await updateStorageLocation(values, selectedRow.id)
                }
            });
        } else {
            // create
            form.validateFields(async (err, values) => {
                if (!err) {
                    await addStorageLocation(values)
                }
            });
        }


    }

    render() {
        const { selectedRow, showFormModal, isLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        console.log(selectedRow, 'selectedRow')

        return (
            <Modal
                title="Add New Storage Location"
                footer={null}
                visible={showFormModal}
                width={900}
                onCancel={this.handleClose}
            >
                <Layout>
                    <Content className="user-roles-modal">
                        <Row type='flex' justify='center' gutter={30}>
                            <Col span={22}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row type="flex" gutter={16}>
                                        <Col span={12}>
                                            <Form.Item label='Plant Code'>
                                                {getFieldDecorator(`plant`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please enter plant code.",
                                                        }
                                                    ],
                                                    initialValue: selectedRow ? selectedRow.plant : null
                                                })(
                                                    <Input placeholder="Enter Plant Code" />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label='Description'>
                                                {getFieldDecorator(`description`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please enter description.",
                                                        },
                                                    ],
                                                    initialValue: selectedRow ? selectedRow.description : null
                                                })(
                                                    <Input placeholder="Enter Description" />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label='Storage Location Code'>
                                                {getFieldDecorator(`codes`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please enter location code.",
                                                        },
                                                    ],
                                                    initialValue: selectedRow ? _.map(selectedRow.codes, (location) => location.code) : []
                                                })(
                                                    <Select mode="tags" style={{ width: '100%' }} placeholder="Enter location code">
                                                    </Select>)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label='Shipping Point'>
                                                {getFieldDecorator(`shipping_point`, {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please enter shipping point code.",
                                                        },
                                                    ],
                                                    initialValue: selectedRow ? _.map(selectedRow.shipping_point, (codes) => codes.code) : []
                                                })(
                                                    <Input placeholder="Enter shipping point" />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row type="flex" justify="end" gutter={16}>
                                        <Col xs={24} sm={12} md={8} lg={6} align="center" style={{ margin: "5px 0px" }}>
                                            <Button style={{ width: "100%", border: "none", fontSize: "16px", boxShadow: "none" }} onClick={this.handleClose} disabled={isLoading}>
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={6} align="center" style={{ margin: "5px 0px" }}>
                                            <Button type="primary" htmlType="submit" style={{ width: "100%", fontSize: "16px" }} disabled={isLoading} loading={isLoading}>
                                                {selectedRow ? 'Update' : 'Create'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Modal>
            //   <Modal
            //     title={Object.keys(selectedRow).length === 0 ? "Add New User Type" : "Edit User"}
            //     visible={showFormModal}
            //     onCancel={this.handleClose}
            //     footer={null}
            //     width={900}
            //   >

            //   </Modal>
        )
    }
}

export default Form.create({ name: 'storage_location_modal_form' })(ModalForm);
