import moment from 'moment'
const initialData = {
  accessAuditTrailLoading: false,
  accessAuditTrailLoaded: false,
  accessAuditTrailRejected: false,
  accessAuditTrailData: []
}
export default function AUDITTRAIL(state = initialData, action) {
  switch (action.type) {
    case 'GET_ACCESS_AUDIT_TRAIL':
      return {
        ...state,
        accessAuditTrailLoading: true,
        accessAuditTrailLoaded: false,
        accessAuditTrailRejected: false,
      }
    case 'GET_ACCESS_AUDIT_TRAIL_FULFILLED':
      action.payload.map((data, i) => {
        data.updated_at_local = moment(new Date(data.updated_at)).format('LLLL')
        // data.content = JSON.parse(data.content)
      })
      return {
        ...state,
        accessAuditTrailLoading: false,
        accessAuditTrailLoaded: true,
        accessAuditTrailRejected: false,
        accessAuditTrailData: action.payload
      }
    case 'GET_ACCESS_AUDIT_TRAIL_REJECTED':
      return {
        ...state,
        accessAuditTrailLoading: false,
        accessAuditTrailLoaded: false,
        accessAuditTrailRejected: true,
      }
    default:
      return {
        ...state
      };
  }
}
