import React, { Component } from 'react';
import { Form, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal, Result, Popover, Select } from 'antd';
import { Icon } from '@ant-design/compatible';
import ModalForm from './components/ModalForm';

import './StorageLocation.scss'

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class StorageLocation extends Component {
  initialState = {
    showFormModal: false,
    selectedRow: null,
    storageLocationData: [],
    filterBy: [
      'storage_location.plant'
    ]
  }

  sortState = {
    sortBy: 'storage_location.plant',
    orderBy: 'asc',
    filters: JSON.stringify({ 'storage_location.status': [1] }),
    page: 1
  };

  // Initial state for searching
  searchState = {};

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.onLoadStorageLocation(this.sortState);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.storageLocationData !== nextProps.storageLocationData) {
      this.setState({ storageLocationData: nextProps.storageLocationData })
    }
  }

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      selectedRow: null
    });
  }

  handleOpenModel = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleEdit = (storageDetails) => {
    this.setState({
      selectedRow: storageDetails,
      showFormModal: true
    })
  }

  handleFilterBy = (value) => {
    this.setState({ filterBy: value });
  };

  handleSearch = (searchTerm, searchKeys) => {
    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveVariantProducts({
      page: 1
    });
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.columnKey || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveVariantProducts({
      page: 1
    });
  }

  retrieveVariantProducts(data) {
    this.props.onLoadStorageLocation({ ...this.sortState, ...data, ...this.searchState });
  }

  handleChangeStatus = (row, status) => {
    const { storageLocationData } = this.state;
    const data = {
      page: storageLocationData.current_page
    };

    const params = {
      id: row.id,
      status: status
    };

    // passed the value for the current state of filter, sort, search, and page
    this.props.onUpdateStorageLocationStatus(params, row.id, { ...this.sortState, ...data, ...this.searchState })
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update storage location',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Storage location has been updated successfully',
            content: ''
          })
        }
      })
  };

  addStorageLocation = async (values) => {
    const { storageLocationData } = this.state;
    const data = {
      page: storageLocationData.current_page
    };
    await this.props.onSubmitAddStorageLocation(values, { ...this.sortState, ...data, ...this.searchState }).then(() => {
      this.setState({ showFormModal: false });
    })
  }

  updateStorageLocation = async (values, id) => {
    const { storageLocationData } = this.state;
    const data = {
      page: storageLocationData.current_page
    };
    await this.props.onUpdateStorageLocationDetails(values, id, { ...this.sortState, ...data, ...this.searchState }).then(() => {
      this.setState({ showFormModal: false, selectedRow: null });
    })
  }

  render() {
    const { filterBy, storageLocationData, showFormModal, selectedRow } = this.state
    const { 
      storageLocationLoading, 
      storageLocationUpdating, 
      myPermissions,
      myPermissionsLoading
    } = this.props;

    const columns = [
      {
        title: 'Plant Code',
        dataIndex: 'plant',
        key: 'storage_location.plant',
        sorter: true
      },
      {
        title: 'Storage Location Code',
        dataIndex: 'codes',
        key: 'storage_location_code.code',
        render: (value) => {
          return (
            <span>{value.map(source => {
              return source['code']
            }).join(', ')}</span>
          )
        }
      },
      {
        title: 'Shipping Point',
        dataIndex: 'shipping_point',
        key: 'storage_location.shipping_point'
      },
      {
        title: 'Storage Location Name',
        dataIndex: 'description',
        key: 'storage_location.description',
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: 'storage_location.status',
        key: 'storage_location.status',
        align: 'center',
        width: 100,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }], // change value from Active to 1 and Inactive to 0
        defaultFilteredValue: [1],
        render: (tags, row) => {
          const status = row.status
          let htmlElement

          if (status === 1) htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 0) htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 1)
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 0)
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <div>
              <Popover content={(<p>Edit</p>)}>
                <span onClick={() => this.handleEdit(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
            </div>
          )
        }
      }
    ];

    if (!myPermissions.some(r => r.name === 'admin.storage_location.edit')) {
      columns.pop();
    }

    if (myPermissionsLoading) return null;

    return myPermissions.some(r => r.name === 'admin.storage_location.view') ? (
    <div>
      <Layout>
        <Content style={{ padding: 25 }}>
          <Row type="flex" align="middle" style={{ margin: "20px 0px" }}>
            <Col span={12}>
              <h1 style={{ fontSize: 24 }}>Storage Location</h1>
            </Col>
            <Col span={12} align="right">
              {
                myPermissions.some(r => r.name === 'admin.storage_location.add') ? (<Button
                  type="primary"
                  onClick={this.handleOpenModel}
                  style={{ margin: '10px', height: 40 }} ><Icon type="plus" />Add New Storage Location</Button>) : ""
              }
            </Col>
          </Row>
          <Row>
            <Col >
              <div className="product-variants-container">
                <Search
                  className="search-container"
                  size="large"
                  placeholder="Search"
                  allowClear={true}
                  onSearch={(value) => this.handleSearch(value, filterBy)}
                />
                <Select style={{ width: 200 }}
                  size="large"
                  placeholder="Filter"
                  onChange={(value) => this.handleFilterBy(value)}
                  defaultValue="storage_location.plant"
                  dropdownClassName="--select"
                  autoFocus={true}
                  showSearch>
                  <Option value="storage_location.plant">Plant code</Option>
                  <Option value="storage_location_code.code">Storage Location Code</Option>
                  <Option value="storage_location.description">Storage Location Name</Option>
                  <Option value="storage_location.shipping_point">Shipping Point</Option>
                </Select>
                <Table
                  rowKey="id"
                  dataSource={storageLocationData.data}
                  columns={columns}
                  loading={storageLocationLoading}
                  onChange={this.handleOnChangeTableListener}
                  pagination={{ total: storageLocationData.total, current: storageLocationData.current_page, pageSize: storageLocationData.per_page || 10 }} />
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
      <ModalForm
        key={selectedRow}
        isLoading={storageLocationUpdating}
        addStorageLocation={this.addStorageLocation}
        updateStorageLocation={this.updateStorageLocation}
        showFormModal={showFormModal}
        handleCloseModal={this.handleCloseModal}
        selectedRow={selectedRow}
      />
    </div >) : (
      <Result
        status="warning"
        title="You have no permission to view this page."
      />
    );
  }
}

const StorageLocationForm = Form.create({ name: 'storage_location_modal_form' })(StorageLocation);

export default StorageLocationForm;