import React, { Component } from 'react';
import moment from 'moment'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { Button } from 'antd'
import { numberFormat } from "../../utils";
const wb = new ExcelJS.Workbook()

class DownloadExcel extends Component {
    constructor() {
        super()
        this.state = {
            selectedOrder: []
        }
        this.saveAsExcel = this.saveAsExcel.bind(this)
    }

    async saveAsExcel() {
        const payment_term_mapping = {
            'ZFCI': 'Cash In Advance',
            'ZFDO': 'Credit Term',
            'ZFDL': 'Cash On Delivery',
            'ZFDK': 'Special Term'
        };
        let worksheetArray = []
        const worksheet = wb.addWorksheet("Orders Report")

        this.state.selectedOrder.map((orderData, i) => {
            worksheet.getCell((`A1`).toString()).value = "Order Date";
            worksheet.getCell((`B1`).toString()).value = "Transaction Number"
            worksheet.getCell((`C1`).toString()).value = "Order Type"
            worksheet.getCell((`D1`).toString()).value = "Sales Order Number"
            worksheet.getCell((`E1`).toString()).value = "Order Status"
            worksheet.getCell((`F1`).toString()).value = "Payer Name"
            worksheet.getCell((`G1`).toString()).value = "Sold To Name"
            worksheet.getCell((`H1`).toString()).value = "Customer Group 2"
            worksheet.getCell((`I1`).toString()).value = "Ordered Quantity"
            worksheet.getCell((`J1`).toString()).value = "Open Order"
            worksheet.getCell((`K1`).toString()).value = "Ordered Amount (PHP VAT-IN)"
            worksheet.getCell((`L1`).toString()).value = "Open Order Amount (PHP VAT-IN)"
            worksheet.getCell((`M1`).toString()).value = "SO Delivery Date"
            worksheet.getCell((`N1`).toString()).value = "Confirmed Availability Date"
            worksheet.getCell((`O1`).toString()).value = "Type"
            worksheet.getCell((`P1`).toString()).value = "Source"
            worksheet.getCell((`Q1`).toString()).value = "Payment Term"
            worksheet.getCell((`R1`).toString()).value = "Blocked Time"
            worksheet.getCell((`S1`).toString()).value = "Approved Time"
            worksheet.getCell((`T1`).toString()).value = "MTO Status"

            worksheet.getCell((`A${i + 2}`).toString()).value = orderData.created_at;
            worksheet.getCell((`B${i + 2}`).toString()).value = orderData.order_transaction_id
            worksheet.getCell((`C${i + 2}`).toString()).value = orderData.tagging
            worksheet.getCell((`D${i + 2}`).toString()).value = orderData.sales_order_number
            worksheet.getCell((`E${i + 2}`).toString()).value = orderData.status
            worksheet.getCell((`F${i + 2}`).toString()).value = orderData.customer.name
            worksheet.getCell((`G${i + 2}`).toString()).value = orderData.sold_to.name
            worksheet.getCell((`H${i + 2}`).toString()).value = orderData.customer.customer_group_2
            worksheet.getCell((`I${i + 2}`).toString()).value = orderData.total_quantity
            worksheet.getCell((`J${i + 2}`).toString()).value = "Open Order"
            worksheet.getCell((`K${i + 2}`).toString()).value = orderData.order_price?.total_amount || "N/A"
            worksheet.getCell((`L${i + 2}`).toString()).value = "Open Order Amount (PHP VAT-IN)"
            worksheet.getCell((`M${i + 2}`).toString()).value = orderData?.delivery_details?.estimated_delivery_date || "N/A"
            worksheet.getCell((`N${i + 2}`).toString()).value = orderData.confirmed_availability_date
            worksheet.getCell((`O${i + 2}`).toString()).value = orderData?.delivery_details?.type
            worksheet.getCell((`P${i + 2}`).toString()).value = orderData.shipping_point ? orderData.shipping_point.map((shipping_point) => {
                return shipping_point.description
            }).join(', ') : "N/A"
            worksheet.getCell((`Q${i + 2}`).toString()).value = payment_term_mapping[orderData.payment_term]
            worksheet.getCell((`R${i + 2}`).toString()).value = orderData.blocked_date || "N/A"
            worksheet.getCell((`S${i + 2}`).toString()).value = orderData.approved_date || "N/A"
            worksheet.getCell((`T${i + 2}`).toString()).value = orderData.mto_status || "N/A"

            worksheetArray.push(worksheet)
        })
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `Order Management Report.xlsx`)

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })
    }

    retrieveOrderDownloadData = () => {
        const { onDownloadOrder, filter } = this.props;
        onDownloadOrder({
            ...filter, ... {
                page: 1,
                limit: 100
            }
        }).then((response) => {
            this.setState({
                selectedOrder: response.response.data.data
            }, () => {
                this.saveAsExcel();
            })
        })
    }

    render() {
        const { title, style, className } = this.props
        return (
            <div>
                <Button
                    className={className}
                    disabled={this.props.total > 100 || this.props.total < 1}
                    style={style}
                    onClick={this.retrieveOrderDownloadData}
                    loading={this.props.loading}
                >{title}
                </Button>
            </div>
        );
    }
}

export default DownloadExcel
