import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import login from '../components/Login/reducers'
import myPermissions from './myPermissions'
import myInfo from './myInfo'
import notifications from './notifications'
import requestData from './notifications'
import product from '../components/Products/reducers'
import materials from '../components/Materials/reducers'
import brand from '../components/Brands/reducers'
import distributor from '../components/Distributors/reducers/index'
import orderManagement from '../components/OrderManagement/reducers'
import articleAbout from '../components/Pages/AboutUsPage/reducers'
import homepage from '../components/Pages/Homepage/reducers'
import feedback from '../components/Reports/Feedback/reducers'
import userTypes from '../components/UserTypes/reducers'
import adminManagement from '../components/AdminManagement/reducers'
import users from '../components/Users/reducers'
import roles from '../components/UserTypes/reducers/roles'
import dashboard from '../components/Dashboard/reducers'
import inquiries from '../components/InquiryDashboard/reducers'
import FAQ from '../components/FAQ/reducers'
import inquiryDb from '../components/InquiryDB/reducers'
import contactUs from '../components/ContactUs/reducers'
import containerBreakdown from '../components/ContainerConfiguration/reducers'
import accessAuditTrail from '../components/AccessAuditTrail/reducers'
import featuresAuditTrail from '../components/FeaturesAuditTrail/reducers'
import storageLocation from '../components/StorageLocation/reducers'
import customerGroup from '../components/CustomerGroup/reducers'

const rootReducer = combineReducers({
  form,
  login,
  myPermissions,
  product,
  materials,
  brand,
  distributor,
  orderManagement,
  articleAbout,
  homepage,
  feedback,
  userTypes,
  adminManagement,
  users,
  roles,
  dashboard,
  inquiries,
  notifications,
  requestData,
  FAQ,
  inquiryDb,
  contactUs,
  containerBreakdown,
  accessAuditTrail,
  featuresAuditTrail,
  storageLocation,
  customerGroup,
  myInfo
})

export default rootReducer;
