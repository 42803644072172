import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input, Table, DatePicker } from 'antd'

import './ModalView.scss'
import { numberFormat } from "../../../../utils";
import MediaQuery from "react-responsive";
import _ from 'lodash';
import moment from 'moment';
const { ColumnGroup } = Table;
const { Search, TextArea } = Input;
const { Content } = Layout

export default class ModalForm extends Component {
  constructor() {
    super()
    this.state = {
      showRequestModal: false,
      // keyword: "",
      comments: "",
      tableData: [],
      sapData: [],
      searchedData: [],
      searchText: "",
    }
  }

  handleCloseModal = () => {
    this.props.form.resetFields()
    this.props.form.handleCloseModal()
  };

  handleComments = e => {
    this.setState({ comments: e.target.value })
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { onSendRequest, selectedRow } = this.props;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        let values = {
          status: 0,
          notif_type: 'sku replacement',
          progress: 'in process',
          description: this.state.comments,
          distributor_id: selectedRow.distributor_id,
          distributor_order_id: selectedRow.id
        }


        if (selectedRow) {
          await onSendRequest(values)
            .then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Unable to send Request.',
                  content: ''
                })
              } else {
                Modal.success({
                  title: 'Request has been sent successfully',
                  content: ''
                })
              }
            })
        }

        this.props.form.resetFields();
        this.handleCloseModal();
      }
    });
  };

  componentWillReceiveProps(nextProps) {

    const { selectedRow, orderStatusData } = this.props
    let { product } = nextProps
    product = ((nextProps.selectedRow)) ? nextProps.selectedRow.product : []
    let newNextProps = nextProps.selectedRow && (nextProps.selectedRow.product || '')
    let newThisProps = selectedRow && (selectedRow.product || '')
    if (newThisProps !== newNextProps) {
      this.setState({ tableData: product })
    }

    if (orderStatusData !== nextProps.orderStatusData) {
      this.setState({ sapData: nextProps.orderStatusData })
    }
  }


  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    const newData = [];
    _.forEach(this.props.selectedRow.order_material, (materialDetails) => {
      const materialNumber = materialDetails.material_details.material_number.toLowerCase()
      const materialDescription = materialDetails.material_details.description.toLowerCase()
      if (_.includes(materialNumber, searchTerm.toLowerCase())
        || _.includes(materialDescription, searchTerm.toLowerCase())) {
        newData.push(materialDetails);
      }
    })
    this.setState({
      searchedData: newData,
      searchText: searchTerm
    });
  }

  getStatus = sapData => {
    sapData = sapData || "";
    let status;
    if (sapData["billing_document"]) status = "invoiced";
    else if (sapData["delivery"]) status = "forLoading";
    else if (sapData["sales_document"]) status = "produced";
    else if (!sapData["sales_document"] && !sapData["delivery"] && !sapData["billing_document"]) status = "forProduction";
    else status = "";
    return status;
  };

  confirm = (event) => {
    Modal.confirm({
      title: "Order Confirm",
      content: `Are you sure you want to confirm the order?`,
      okText: 'Okay',
      onOk: () => {
        event.preventDefault()
        const { selectedRow, form } = this.props
        form.validateFields((err, values) => {
          if (!err) {
            this.props.onOrderConfirm({
              "order_id": selectedRow.id,
              "reason": values.reason,
              "delivery_date": moment(Math.max(...selectedRow.order_material.map(value => moment(value.proposed_date || value.availability_date)))).format("YYYY-MM-DD")
            }).then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Something went wrong',
                  content: ''
                })
              } else {
                Modal.success({
                  title: `Order Confirmed`,
                  content: 'Please wait for SAP to Confirm'
                })
              }
            })

            this.props.handleCloseModal();
          }
        });
      }
    })
  }

  propose = (e) => {
    Modal.confirm({
      title: "Order Propose",
      content: `Are you sure you want to propose the order?`,
      okText: 'Okay',
      onOk: () => {
        e.preventDefault()
        const { form, selectedRow } = this.props
        let params = {
          materials: {},
          reason: "",
          order_id: selectedRow.id
        };

        form.validateFields((err, values) => {
          if (!err) {
            if (!values.reason) {
              Modal.warning({
                title: 'Reason Field is required',
                content: ''
              })

              return false;
            }

            params.reason = values.reason;
            Object.keys(values.materials).forEach(function (key) {
              params.materials[key] = values.materials[key].format("YYYY-MM-DD");
            });

            this.props.onProposeOrder(params).then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Something went wrong',
                  content: ''
                })
              } else {
                Modal.success({
                  title: `Proposal submitted to Salesman`,
                })
              }
            })

            this.props.handleCloseModal();
          }
        });
      }
    })
  }

  reject = (e) => {
    Modal.confirm({
      title: "Order Reject",
      content: `Are you sure you want to reject the order?`,
      okText: 'Okay',
      onOk: () => {
        e.preventDefault()
        const { form, selectedRow } = this.props

        form.validateFields((err, values) => {
          if (!err) {
            if (!values.reason) {
              Modal.warning({
                title: 'Reason Field is required',
                content: ''
              })

              return false;
            }

            this.props.onRejectOrder({
              "order_id": selectedRow.id,
              "reason": values.reason
            }).then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Something went wrong',
                  content: ''
                })
              } else {
                Modal.success({
                  title: `Order Rejected`
                })
              }
            })

            this.props.handleCloseModal();
          }
        });
      }
    })
  }

  render() {
    const payment_term = {
      'ZCIA': 'Cash In Advance', // Note: Removed on second round of testin
      'ZFCI': 'Cash In Advance',
      'ZFDO': 'Credit Term',
      'ZFDL': 'Cash On Delivery',
      'ZFDK': 'Special Term'
    };

    const { selectedRow, myPermissions } = this.props;
    const { searchText, searchedData } = this.state

    if (selectedRow === null) {
      return "";
    }

    const orderTagging = selectedRow.order_material.map((value) => { return value["tagging"] });
    const footer = (myPermissions.some((r) => r.name === "admin.customer_order.amount") ? (
      <div>
        <MediaQuery minWidth={1224}>
          <Row className="footer-container">
            <Col span={8}>
            </Col>
            <Col span={16}>
              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>TOTAL</span>
                  </div>
                </Col>

                <Col span={6} align="left">
                  <div className="footer-label">
                    <span>
                      {
                        selectedRow.order_material.reduce(function (res, item) {
                          return res + (item.weight);
                        }, 0)
                      }
                    </span>
                  </div>
                </Col>
                <Col span={6} align="left">
                  <div className="footer-label">
                    <span>
                      {selectedRow.total_quantity}
                    </span>
                  </div>
                </Col>
                <Col span={6} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.vat_sales + selectedRow.order_price?.discount || 0) || "0.00"}
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>Less: Discount</span>
                  </div>
                </Col>
                <Col span={18} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.discount || 0)}
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>Total, Net of Discount</span>
                  </div>
                </Col>
                <Col span={18} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.vat_sales || 0) || "0.00"}
                    </span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>Add: VAT</span>
                  </div>
                </Col>
                <Col span={18} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.vat_amount || 0)}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>Total Amount</span>
                  </div>
                </Col>
                <Col span={18} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.total_amount || 0) || "0.00"}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>Less: EWT (If Applicable)</span>
                  </div>
                </Col>
                <Col span={18} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.ewt_amount || 0) || "0.00"}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div className="footer-label">
                    <span>Amount for Payment</span>
                  </div>
                </Col>

                <Col span={18} align="right">
                  <div className="footer-label">
                    <span>
                      {numberFormat(selectedRow.order_price?.total_amount - selectedRow.order_price?.ewt_amount || 0) || "0.00"}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </MediaQuery>
        <MediaQuery maxWidth={600}>
          <Row className="footer-container">
            <Row>
            <Col span={12}>
                <div className="footer-label">
                  <span>TOTAL</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.vat_sales + selectedRow.order_price?.discount || 0) || "0.00"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>TOTAL Quantity</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {selectedRow.total_quantity}
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>Less: Discount</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.discount || 0)}
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>Total, Net of Discount</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.vat_sales || 0) || "0.00"}
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>Add: VAT</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.vat_amount || 0)}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>Total Amount</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.total_amount || 0) || "0.00"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>Less: EWT (If Applicable)</span>
                </div>
              </Col>
              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.ewt_amount || 0) || "0.00"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="footer-label">
                  <span>Amount for Payment</span>
                </div>
              </Col>

              <Col span={12} align="right">
                <div className="footer-label">
                  <span>
                    {numberFormat(selectedRow.order_price?.total_amount - selectedRow.order_price?.ewt_amount || 0) || "0.00"}
                  </span>
                </div>
              </Col>
            </Row>
          </Row>
        </MediaQuery>
      </div>) : "")

    return (
      <Modal
        {...this.props}
        title={`ORDER #${selectedRow.order_transaction_id}`}
        visible={this.props.showFormModal}
        onCancel={this.props.handleCloseModal}
        centered={true}
        footer={null}
        width={"80%"}
        className="order-modal-container"
      >
        <Layout>
          <Content>
            <div className='header-container'>
              <Row type="flex" className="po-page-header">
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Sold To:</span>
                    <span className="value">{selectedRow.sold_to.name || 'N/A'}</span>
                  </div>
                </Col>
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Ship To:</span>
                    <span className="value">{selectedRow.ship_to.name || 'N/A'}</span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" className="po-page-header">
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Sold To Code:</span>
                    <span className="value">{selectedRow.sold_to.customer_code || 'N/A'}</span>
                  </div>
                </Col>
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Ship To Code:</span>
                    <span className="value">{selectedRow.ship_to.customer_code || 'N/A'}</span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" className="po-page-header">
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="left-field">Payment Term:</span>
                    <span className="value">{payment_term[selectedRow.payment_term] || 'N/A'}</span>
                  </div>
                </Col>
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Address:</span>
                    <span className="value">{selectedRow.ship_to?.address + ', ' + selectedRow.ship_to?.city + ', ' + selectedRow.ship_to?.postal_code || 'N/A'}</span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" className="po-page-header">
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Delivery Date:</span>
                    <span className="value">{selectedRow.delivery_details.estimated_delivery_date} / {selectedRow.delivery_details.type}</span>
                  </div>
                </Col>
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="left-field">Source:</span>
                    <span className="value">{
                      selectedRow.shipping_point ? selectedRow.shipping_point.map((shipping_point) => {
                        return shipping_point.description
                      }).join(', ') : 'N/A'
                    }</span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" className="po-page-header">
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="right-field">Special Instruction:</span>
                    <span className="value">{selectedRow.special_instruction || "N/A"}</span>
                  </div>
                </Col>
                <Col span={12} style={{ minWidth: "250px" }}>
                  <div className="po-page-sub-header">
                    <span className="left-field">Transaction Reference Number:</span>
                    <span className="value">{selectedRow.sales_order_number || 'N/A'}</span>
                  </div>
                </Col>
              </Row>
            </div>
            <Row type='flex' style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
              <Col className="search-container">
              </Col>
            </Row>
            <Row type='flex' class="table-container">
              <Col span={24}>
                <Form onSubmit={this.handleSubmit} className='login-form'>
                  <Table
                    dataSource={searchText.length ? searchedData : selectedRow.order_material}
                    footer={() => (
                      <React.Fragment>
                        {footer}
                      </React.Fragment>
                    )}
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: true }}
                    rowKey="id"
                  // pagination={false}
                  >
                    <Col title="PRODUCT" dataIndex="material_details" key="material_details" render={(materialDetails) => (
                      <span>
                        {materialDetails.material_number}
                        <br />
                        {materialDetails.description}
                      </span>
                    )} />
                    <Col title="AVAILABILITY DATE" dataIndex="availability_date" key="availability_date" render={(materialDetails) => (
                      <span>
                        {materialDetails || "N/A"}
                      </span>
                    )} />
                    <Col title="PROPOSED AVAILABILITY DATE" key="proposed_date" render={(text, orderMaterials) => (
                      <span>{orderMaterials.proposed_date ? orderMaterials.proposed_date : orderMaterials.availability_date}</span>
                    )} />
                    {!["For Review", "For Salesman Approval", "Pending"].includes(selectedRow.status) && orderTagging.includes("mto") ? (
                      <Col title="CONFIRMED AVAILABILITY DATE" dataIndex="proposed_date" key="confirmed_availability_date" render={(proposedAvailabilityDate, values) => (
                        <span>
                          {proposedAvailabilityDate || values.availability_date}
                        </span>
                      )} />) : ""}
                    {myPermissions.some((r) => r.name === "admin.customer_order.amount") ? (<ColumnGroup title="AMOUNT (PHP/UNIT)">
                      <Col align="center" title="VAT-IN" dataIndex="unit_price" render={(unitPrice) => (
                        <span>  {numberFormat(unitPrice + (unitPrice * 0.12)) || "0.00"}</span>
                      )} key="vat-in" />
                      <Col align="center" title="VAT-EX" dataIndex="unit_price" render={(unitPrice) => (
                        <span>  {numberFormat(unitPrice) || "0.00"}</span>
                      )} key="vat-ex" />
                    </ColumnGroup>) : ""}
                    <Col title="Quantity (IN KG)" dataIndex="weight" render={(weight) => (
                      <span >  {numberFormat(weight) || "0.00"}</span>
                    )} key="kg-quantity" />
                    <Col title="ORDERED QUANTITY" dataIndex="quantity" key="quantity" />
                    {myPermissions.some((r) => r.name === "admin.customer_order.amount") ? (<Col title="ORDERED AMOUNT (PHP)" dataIndex="price" render={(price) => (
                      <span>  {numberFormat(price ? price : 0) || "0.00"}</span>
                    )} key="price" />) : ""}
                  </Table>
                </Form>
              </Col>
            </Row>
            {/* </Form> */}
          </Content>
        </Layout>
      </Modal >
    )
  }
}
