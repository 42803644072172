import React, { Component } from 'react'
import { Row, Form, Modal, Layout, Descriptions, Tabs, Breadcrumb, Icon, Table, Button, Dropdown, Menu, Select, Input, Col } from 'antd';

const { TabPane } = Tabs;
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

class ModalFormSubAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      loading: false,
      pagination: 1,
      soldTo: {
        filterBy: "customer_code",
        keyword: "",
        filters: "",
        searchField: "",
        page: 1,
        limit: 5
      },
      shipTo: {
        filterBy: "customer_code",
        keyword: "",
        filters: "",
        searchField: "",
        page: 1,
        limit: 5
      },
      salesman: {
        filterBy: "sub_customer.partner_code",
        keyword: "",
        filters: "",
        searchField: "",
        page: 1,
        limit: 5
      },
      step: 'salesman',
      selectedSoldTo: null
    };
  }

  handleCloseModal = () => {
    this.props.form.resetFields()
    this.props.handleCloseModal()
  }

  handleOnChangeTable = (pagination, filters) => {
    const { soldTo, shipTo, step, selectedSoldTo, salesman } = this.state
    const { selectedRow, onLoadSoldTo, onLoadShipTo, onLoadSalesman } = this.props

    let currentState = {
      soldTo: {
        ...soldTo,
        ...{
          pagination: pagination.current,
          filters: JSON.stringify(filters)
        }
      }
    }

    if (step === 'ship_to') {
      currentState = {
        shipTo: {
          ...shipTo,
          ...{
            pagination: pagination.current,
            filters: JSON.stringify(filters)
          }
        }
      }
    } else if (step === 'salesman') {
      currentState = {
        salesman: {
          ...salesman,
          ...{
            pagination: pagination.current,
            filters: JSON.stringify(filters)
          }
        }
      }
    }

    this.setState(currentState)

    if (step === 'sold_to') {
      onLoadSoldTo({
        customer_code: selectedRow.customer_code,
        page: pagination.current,
        filters: JSON.stringify(filters),
        searchValue: this.state.soldTo.keyword,
        searchField: this.state.soldTo.filterBy,
        limit: 5
      });
    } else if (step === 'ship_to') {
      onLoadShipTo({
        customer_code: selectedSoldTo.customer_code,
        page: pagination.current,
        filters: JSON.stringify(filters),
        searchValue: this.state.shipTo.keyword,
        searchField: this.state.shipTo.filterBy,
        limit: 5
      });
    } else if (step === 'salesman') {
      onLoadSalesman({
        customer_code: selectedRow.customer_code,
        page: pagination.current,
        filters: JSON.stringify(filters),
        searchValue: this.state.salesman.keyword,
        searchField: this.state.salesman.filterBy,
        limit: 5
      });
    }
  }

  handleFilterBy = (value) => {
    const { soldTo, shipTo, step } = this.state
    let currentState = {
      soldTo: {
        ...soldTo, ...{ filterBy: value }
      }
    }

    if (step === 1) {
      currentState = {
        shipTo: {
          ...shipTo, ...{ filterBy: value }
        }
      }
    }

    this.setState(currentState);
  };

  handleFilterBySalesman = (value) => {
    const { salesman } = this.state

    this.setState({
      salesman: {
        ...salesman, ...{ filterBy: value }
      }
    });
  };


  handleSearch = (searchTerm) => {
    const { soldTo, shipTo, step, selectedSoldTo, salesman } = this.state
    const { selectedRow, onLoadSoldTo, onLoadShipTo, onLoadSalesman } = this.props
    let customer = soldTo;
    if (step === "ship_to") {
      customer = shipTo
    } else if (step === 'salesman') {
      customer = salesman
    }

    const searchParams = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: customer.filterBy
    };

    let currentState = {
      soldTo: {
        ...soldTo, ...{
          pagination: 1,
          keyword: searchTerm
        }
      }
    };

    if (step === 'ship_to') {
      currentState = {
        shipTo: {
          ...shipTo, ...{
            pagination: 1,
            keyword: searchTerm
          }
        }
      }
    } else if (step === 'salesman') {
      currentState = {
        salesman: {
          ...salesman, ...{
            pagination: 1,
            keyword: searchTerm
          }
        }
      }
    }

    this.setState(currentState);

    if (step === 'sold_to') {
      onLoadSoldTo({
        ...{
          customer_code: selectedRow.customer_code,
          page: 1,
          filters: customer.filters,
          limit: 5
        }, ...searchParams
      });
    } else if (step === 'ship_to') {
      onLoadShipTo({
        ...{
          customer_code: selectedSoldTo.customer_code,
          page: 1,
          filters: customer.filters,
          limit: 5
        }, ...searchParams
      });
    } else if (step === 'salesman') {
      onLoadSalesman({
        ...{
          customer_code: selectedRow.customer_code,
          page: 1,
          filters: customer.filters,
          limit: 5
        }, ...searchParams
      })
    }
  }

  handleShipToDisplay = (soldTo) => {
    const { onLoadShipTo } = this.props

    onLoadShipTo({
      customer_code: soldTo.customer.customer_code,
      page: 1,
      limit: 5
    });

    this.setState({
      step: 'ship_to',
      selectedSoldTo: soldTo.customer
    })
  }

  handleSalesmanChangeStatus = (params) => {
    const { salesman } = this.state
    const { selectedRow, onLoadSalesman, onUpdateSalesman } = this.props

    onUpdateSalesman(params).then((response) => {
      if (response.error) {
        return Modal.warning({
          title: 'Unable to update Salesman.',
          content: ''
        })
      }

      Modal.success({
        title: 'Customer has been updated successfully',
        content: ''
      })
      onLoadSalesman({
        ...{
          customer_code: selectedRow.customer_code,
        }, ...salesman,
        limit: 5
      })
    })
  }

  handleChangeStatus = (params) => {
    const { soldTo, shipTo, step, selectedSoldTo } = this.state
    const { selectedRow, onLoadSoldTo, onLoadShipTo, onUpdateSubCustomer } = this.props

    let customer = step === "ship_to" ? shipTo : soldTo;


    onUpdateSubCustomer(params)
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Customer.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Customer has been updated successfully',
            content: ''
          })
          if (step === 'sold_to') {
            onLoadSoldTo({
              ...{
                customer_code: selectedRow.customer_code,
                limit: 5
              }, ...customer
            });
          } else if (step === 'ship_to') {
            onLoadShipTo({
              ...{
                customer_code: selectedSoldTo.customer_code,
                limit: 5
              }, ...customer
            });
          }
        }
      })
  };

  updateStep = (step) => {
    this.setState({
      step: step === 'salesman' ? 'salesman' : 'sold_to'
    })
  }

  render() {
    const {
      selectedRow,
      showFormModal,
      handleCloseModal,
      customerSoldToData,
      customerSoldToLoading,
      customerShipToLoading,
      customerShipToData,
      customerSalesmanLoading,
      customerSalesmanData
    } = this.props

    const {
      step
    } = this.state;

    const customerColumns = [
      {
        title: 'Customer Code',
        dataIndex: step === "sold_to" ? 'customer.customer_code' : "ship_to.customer_code",
        key: 'customer_code',
      },
      {
        title: 'Name',
        dataIndex: step === "sold_to" ? 'customer.name' : "ship_to.name",
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: step === "sold_to" ? 'customer.status' : "ship_to.status",
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        defaultFilteredValue: [1],
        key: 'status',
        render: (status, row) => {
          const record = row.customer || row.ship_to;
          const htmlElement = status ? <Button className='active'>ACTIVE</Button> : <Button className='inactive'>INACTIVE</Button>

          if (!record) {
            return "";
          }

          return (
            <div>
              <Dropdown
                key={record.id}
                className='status-dropdown'
                overlay={
                  <Menu key={record.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus({
                            id: record.id,
                            status: 1
                          })
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus({
                            id: record.id,
                            status: 0
                          })
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      }
    ];

    const salesmanColumn = [
      {
        title: 'Salesman Code',
        dataIndex: 'salesman.customer_code',
        key: 'customer_code',
      },
      {
        title: 'Name',
        dataIndex: 'salesman.name',
        key: 'name',
      },
      {
        title: 'Email Address',
        dataIndex: 'salesman.email',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'is_notify',
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        defaultFilteredValue: [1],
        key: 'is_notify',
        render: (status, row) => {
          const htmlElement = status ? <Button className='active'>ACTIVE</Button> : <Button className='inactive'>INACTIVE</Button>

          if (!row) {
            return "";
          }

          return (
            <div>
              <Dropdown
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleSalesmanChangeStatus({
                            id: row.id,
                            is_notify: 1
                          })
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleSalesmanChangeStatus({
                            id: row.id,
                            is_notify: 0
                          })
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      }
    ];

    return (
      <Modal
        title="Customer Details"
        visible={showFormModal}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
      >
        <Layout>
          <Content className='contact-form-container'>
            <Row type='flex' style={{ margin: "0 24px" }} justify='center' gutter={30}>
              <Descriptions>
                <Descriptions.Item span={2} label="Customer Code">{selectedRow.customer_code}</Descriptions.Item>
                <Descriptions.Item span={2} label="Name">{selectedRow.name}</Descriptions.Item>
                <Descriptions.Item span={2} label="Email Address">{selectedRow.email}</Descriptions.Item>
                {/* <Descriptions.Item span={2} label="Tagging"><span style={{ 'text-transform': "uppercase" }}>{selectedRow.tagging.join(", ")}</span></Descriptions.Item> */}
                <Descriptions.Item span={2} label="Status">{selectedRow.status}</Descriptions.Item>
              </Descriptions>
            </Row>
            <Row type='flex' gutter={30}>
              <Tabs defaultActiveKey="1" style={{ width: "100%", padding: "0 24px" }} onTabClick={this.updateStep}>
                <TabPane tab="Salesman" key={"salesman"} style={{ cursor: "pointer" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Search className="search-container"
                        size="large"
                        placeholder="Search"
                        onSearch={this.handleSearch} />
                    </Col>
                    <Col span={12}>
                      <Select style={{ width: 160 }}
                        placeholder="Filter"
                        size="large"
                        defaultValue="sub_customer.partner_code" // changed the defaukt value selected in dropdown to material_code
                        onChange={this.handleFilterBySalesman}
                        dropdownClassName="--select"
                        autoFocus={true}
                        showSearch>
                        <Option value="sub_customer.partner_code">Code</Option>
                        <Option value="customer.email">Email</Option>
                        <Option value="customer.name">Name</Option>
                      </Select>
                    </Col>
                  </Row><Table
                    rowKey="id"
                    dataSource={customerSalesmanData?.data?.data}
                    columns={salesmanColumn}
                    onChange={this.handleOnChangeTable}
                    loading={customerSalesmanLoading}
                    pagination={{ total: customerSalesmanData?.total, current: customerSalesmanData?.current_page, pageSize: customerSalesmanData?.per_page || 10 }}
                    scroll={{ x: true }}
                  />
                </TabPane>
                <TabPane tab="Customers" key={"customer"}>
                  <Breadcrumb style={{ margin: "12px 24px" }}>
                    <Breadcrumb.Item onClick={() => this.updateStep('customer')} style={{ cursor: "pointer" }}>
                      <Icon type="user" />
                      <span>Sold-To Customers</span>
                    </Breadcrumb.Item>
                    {step === 'ship_to' ? <Breadcrumb.Item><Icon type="team" />Ship-To Customers</Breadcrumb.Item> : ""}
                  </Breadcrumb>

                  {step === 'ship_to' && (
                    <div>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Search className="search-container"
                            size="large"
                            placeholder="Search"
                            onSearch={this.handleSearch} />
                        </Col>
                        <Col span={12}>
                          <Select style={{ width: 160 }}
                            placeholder="Filter"
                            size="large"
                            defaultValue="customer_code" // changed the defaukt value selected in dropdown to material_code
                            onChange={this.handleFilterBy}
                            dropdownClassName="--select"
                            autoFocus={true}
                            showSearch>
                            <Option value="customer_code">Code</Option>
                            <Option value="name">Customer Name</Option>
                          </Select>
                        </Col>
                      </Row>
                      <Table
                        rowKey="id"
                        dataSource={customerShipToData.data}
                        columns={customerColumns}
                        onChange={this.handleOnChangeTable}
                        loading={customerShipToLoading}
                        pagination={{ total: customerShipToData.total, current: customerShipToData.current_page, pageSize: customerShipToData.per_page || 10 }}
                        scroll={{ x: true }}
                      />
                    </div>
                  )}
                  {step === 'sold_to' && <div>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Search className="search-container"
                          size="large"
                          placeholder="Search"
                          onSearch={this.handleSearch} />
                      </Col>
                      <Col span={12}>
                        <Select style={{ width: 160 }}
                          placeholder="Filter"
                          size="large"
                          defaultValue="customer_code" // changed the defaukt value selected in dropdown to material_code
                          onChange={this.handleFilterBy}
                          dropdownClassName="--select"
                          autoFocus={true}
                          showSearch>
                          <Option value="customer_code">Code</Option>
                          <Option value="name">Customer Name</Option>
                        </Select>
                      </Col>
                    </Row><Table
                      rowKey="id"
                      dataSource={customerSoldToData.data}
                      columns={customerColumns}
                      onChange={this.handleOnChangeTable}
                      loading={customerSoldToLoading}
                      pagination={{ total: customerSoldToData.total, current: customerSoldToData.current_page, pageSize: customerSoldToData.per_page || 10 }}
                      onRowClick={this.handleShipToDisplay}
                      scroll={{ x: true }}
                    /></div>}
                </TabPane>
              </Tabs>
            </Row>
          </Content>
        </Layout>
      </Modal >
    )
  }
}

ModalFormSubAccount.defaultProps = {
  roles: []
}

export default Form.create({ name: 'admin_modal_form' })(ModalFormSubAccount);
