const initialData = {
    storageLocationLoading: false,
    storageLocationUpdating: false,
    storageLocationData: []
  }
  
  export default function brand(state = initialData, action) {
    switch (action.type) {
      case 'GET_STORAGE_LOCATION_DATA':
        return {
          ...state,
          storageLocationLoading: true
        }
      case 'GET_STORAGE_LOCATION_DATA_FULFILLED':
        return {
          ...state,
          storageLocationData: action.payload,
          storageLocationLoading: false
        }
      case 'GET_STORAGE_LOCATION_DATA_REJECTED':
        return {
          ...state,
          storageLocationData: [],
          storageLocationLoading: false
        }
      case 'PUT_STORAGE_LOCATION_DATA':
        return {
          ...state,
          storageLocationUpdating: true
        }
      case 'PUT_STORAGE_LOCATION_DATA_FULFILLED':
        return {
          ...state,
          storageLocationUpdating: false
        }
      case 'PUT_STORAGE_LOCATION_DATA_REJECTED':
        return {
          ...state,
          storageLocationUpdating: false
        }
      case 'POST_STORAGE_LOCATION_DATA':
        return {
          ...state,
          storageLocationUpdating: true
        }
      case 'POST_STORAGE_LOCATION_DATA_FULFILLED':
        return {
          ...state,
          storageLocationUpdating: false
        }
      case 'POST_STORAGE_LOCATION_DATA_REJECTED':
        return {
          ...state,
          storageLocationUpdating: false
        }
  
      default:
        return {
          ...state
        }
    }
  }
  