import React, { Component } from 'react';
import moment from 'moment'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { Button } from 'antd'
import { numberFormat } from "../../utils";
const wb = new ExcelJS.Workbook()

class DownloadExcel extends Component {
    constructor() {
        super()
        this.saveAsExcel = this.saveAsExcel.bind(this)
    }

    async saveAsExcel() {

        let worksheetArray = []

        if (!this.props.data) {
            return false;
        }

        const worksheet = wb.addWorksheet()
        worksheet.getCell('A1').value = 'Source Plant';
        worksheet.getCell('A1').alignment = { horizontal: 'center' };
        worksheet.getCell('A1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('B1').value = 'Payer Code';
        worksheet.getCell('B1').alignment = { horizontal: 'center' };
        worksheet.getCell('B1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('C1').value = 'Payer Name';
        worksheet.getCell('C1').alignment = { horizontal: 'center' };
        worksheet.getCell('C1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('D1').value = 'Sold To Code';
        worksheet.getCell('D1').alignment = { horizontal: 'center' };
        worksheet.getCell('D1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('E1').value = 'Sold To Description';
        worksheet.getCell('E1').alignment = { horizontal: 'center' };
        worksheet.getCell('E1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('F1').value = 'Ship To Code';
        worksheet.getCell('F1').alignment = { horizontal: 'center' };
        worksheet.getCell('F1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('G1').value = 'Ship To Description';
        worksheet.getCell('G1').alignment = { horizontal: 'center' };
        worksheet.getCell('G1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('H1').value = 'Customer Email';
        worksheet.getCell('H1').alignment = { horizontal: 'center' };
        worksheet.getCell('H1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('I1').value = 'Salesman Code';
        worksheet.getCell('I1').alignment = { horizontal: 'center' };
        worksheet.getCell('I1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('J1').value = 'Salesman Name';
        worksheet.getCell('J1').alignment = { horizontal: 'center' };
        worksheet.getCell('J1').font = { bold: true, horizontal: 'center' };

        worksheet.getCell('K1').value = 'Salesman Email';
        worksheet.getCell('K1').alignment = { horizontal: 'center' };
        worksheet.getCell('K1').font = { bold: true, horizontal: 'center' };
        let row = 2;

        this.props.data.map((customerData, i) => {
            // console.log(customerData)

            customerData.sold_to.map((soldToData) => {

                if (soldToData.ship_to_extract.length < 1) {
                    const plant = customerData.source.map(item => item["plant"]);
                    worksheet.getCell((`A${row}`).toString()).value = plant[0];
                    worksheet.getCell((`B${row}`).toString()).value = customerData.customer_code;
                    worksheet.getCell((`C${row}`).toString()).value = customerData.name;
                    worksheet.getCell((`D${row}`).toString()).value = soldToData.customer_code;
                    worksheet.getCell((`E${row}`).toString()).value = soldToData.name;
                    worksheet.getCell((`F${row}`).toString()).value = "";
                    worksheet.getCell((`G${row}`).toString()).value = "";
                    worksheet.getCell((`H${row}`).toString()).value = customerData.email;
                    worksheet.getCell((`I${row}`).toString()).value = customerData.salesman?.customer_code || "";
                    worksheet.getCell((`J${row}`).toString()).value = customerData.salesman?.name || "";
                    worksheet.getCell((`K${row}`).toString()).value = customerData.salesman?.email || "";
                    ++row;
                } else {
                    soldToData.ship_to_extract.map((shipToExtract) => {
                        const plant = customerData.source.map(item => item["plant"]);
                        worksheet.getCell((`A${row}`).toString()).value = plant[0];
                        worksheet.getCell((`B${row}`).toString()).value = customerData.customer_code;
                        worksheet.getCell((`C${row}`).toString()).value = customerData.name;
                        worksheet.getCell((`D${row}`).toString()).value = soldToData.customer_code;
                        worksheet.getCell((`E${row}`).toString()).value = soldToData.name;
                        worksheet.getCell((`F${row}`).toString()).value = shipToExtract.customer_code;
                        worksheet.getCell((`G${row}`).toString()).value = shipToExtract.name;
                        worksheet.getCell((`H${row}`).toString()).value = customerData.email;
                        worksheet.getCell((`I${row}`).toString()).value = customerData.salesman?.customer_code || "";
                        worksheet.getCell((`J${row}`).toString()).value = customerData.salesman?.name || "";
                        worksheet.getCell((`K${row}`).toString()).value = customerData.salesman?.email || "";
                        ++row;
                    });
                }
            })
            worksheetArray.push(worksheet)
        })
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `Customer Report.xlsx`)

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })
    }

    render() {
        const { title, style, className } = this.props
        return (
            <div>
                <Button
                    className={className}
                    disabled={this.props.data?.length === 0}
                    style={style}
                    onClick={() => this.saveAsExcel()}
                >{title}
                </Button>
            </div>
        );
    }
}

export default DownloadExcel
