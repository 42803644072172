import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadAccessAuditTrail
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class AccessAudit extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const AccessAuditForm = Form.create({ name: 'auditTrailForm' })(AccessAudit);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        accessAuditTrailData: state.accessAuditTrail.accessAuditTrailData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadAccessAuditTrail: (data) => dispatch(loadAccessAuditTrail(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessAuditForm);
