import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input } from 'antd';
import _ from 'lodash';
const { Content } = Layout;

export default function ModalView(props) {
  const { selectedRow } = props;

  const trailMapping = {
    materials: {
      status: 'Status',
      is_separate_order: 'Value Brand'
    },
    storage_location: {
      plant: 'Plant',
      description: 'Description',
      codes: 'Code'
    },
    user_types: {
      name: 'Name',
      description: 'Description',
      permissions: 'Permission'
    },
    customers: {
      status: 'Status',
      source: 'Source'
    },
    customer_group: {
      customer_code: 'Customer Code',
      name: 'Customer Name'
    },
    admin_management: {
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      contact_number: 'Contact Number',
      status: 'Status',
      role: 'Role and Access'
    }
  }

  if (!selectedRow) return null;

  const groupBy = (data, key) => {
    return data.reduce(function (storage, item) {
      var group = item[key];
      storage[group] = storage[group] || [];
      storage[group].push(item);
      return storage;
    }, {});
  };

  const formatPermissionChanges = (value) => {
    const admin = value.filter(value => value.name.startsWith("admin"))
    let newAdminArray = [];
    admin.map(value => {
      let title = value.name.split('.')[1]
      let permission = value.name.split('.')[2]

      return newAdminArray.push({ title: title, permission: permission })
    })

    const adminGroupBy = groupBy(newAdminArray, 'title')
    const adminTab = Object.keys(adminGroupBy).filter(permissionValue => permissionValue !== "*").map((permissionKey) => {
      return { title: (permissionKey || "").replace(/_/g, ' '), permission: adminGroupBy[permissionKey].filter(adminValue => adminValue.permission !== "*").map(checkBoxValue => ( checkBoxValue.permission )) }
    });

    return adminTab.map((value) => (
      <div>
        <b style={{ 'text-transform': 'uppercase' }}>{value.title}</b>:<span style={{ 'text-transform': 'capitalize' }}> {value.permission.join(', ')}</span>
      </div>
    ));  
  }

  const formatContent = (value, key) => {
    if (_.isBoolean(value)) return value ? 'Active' : 'Inactive';
    if (key === 'image' && _.has(selectedRow.previous_content, key)) return process.env.REACT_APP_FILES_URL + value;
    if (key === 'permissions') return formatPermissionChanges(value);
    if (_.isArray(value)) return value.join(', ');

    return value || 'N/A'
  }

  return (
    <Modal
      title="Audit Trail Changes"
      visible={props.showModalView}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={900}
      style={{ height: 550 }}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Row style={{ display: 'block', marginBottom: 20 }}>
            <Col span={2}>
            </Col>
            <Col span={6}>
              <b style={{ fontSize: 16 }}>Field</b>
            </Col>

            <Col span={8}>
              <b style={{ fontSize: 16 }}>Previous Content</b>
            </Col>
            <Col span={6}>
              <b style={{ fontSize: 16 }}>New Content</b>
            </Col>
            <Col span={2}>
            </Col>
          </Row>
          {
            Object.keys(trailMapping[selectedRow.feature]).map((key) => _.has(selectedRow.previous_content, key) ? (
            <Row style={{ display: 'block', margin: '20px 0px' }}>
              <Col span={2}>
              </Col>
              <Col span={6}>
                <span>{trailMapping[selectedRow.feature][key]} {formatContent(selectedRow.content[key], key) === formatContent(selectedRow.previous_content[key], key) ? "" : <span style={ {color: 'red'} }>*</span> } </span>
              </Col>

              <Col span={8}>
                <span>
                  {formatContent(selectedRow.previous_content[key], key)} 
                </span>
              </Col>

              <Col span={6}>
                <span>
                  {formatContent(selectedRow.content[key], key)}
                </span>
              </Col>
              <Col span={2}>
              </Col>
            </Row>
            ) : ""
          )}
        </Content>
      </Layout>
    </Modal>
  )
}
