import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input } from 'antd';

const { Content } = Layout;
const Option = Select.Option;

class ModalForm extends Component {
  state = {
    confirmDirty: false,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { form, selectedRow } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const {
          email, password, status, is_admin,
          first_name, middle_name, last_name, mobile_number, access_type, code
        } = values
        const data = {
          first_name: first_name,
          middle_name: middle_name,
          last_name: last_name,
          contact_number: mobile_number,
          role: access_type,
          status: status === 'Active' ? 1 : 0,
          is_admin: is_admin === 'User' ? 0 : is_admin === 'Admin' ? 2 : 1,
          code: code
        }
        if (selectedRow) {
          data.id = selectedRow.id
          this.props.onSubmitUpdateAdminManagement(data, this.props.searchParams).then((response) => {
            if (response.error) {
              Modal.warning({
                title: 'Unable to update.',
                content: ''
              })
            } else {
              Modal.success({
                title: `Updated successfully`,
                content: ''
              })
            }
          })
        } else {
          data.email = email
          data.password = password
          this.props.onSubmitAddAdminManagement(data, this.props.searchParams).then((response) => {
            if (response.error) {
              Modal.warning({
                title: 'Unable to add.',
                content: ''
              })
            } else {
              Modal.success({
                title: `Added successfully`,
                content: ''
              })
            }
          })
        }
        this.handleCloseModal();
      }
    });
  }

  handleCloseModal = () => {
    this.props.form.resetFields()
    this.props.handleCloseModal()
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Please make sure your passwords match.');
    } else {
      callback();
    }
  };

  render() {
    const {
      users,
      roles,
      selectedRow,
      isLoading,
      showFormModal,
      salesman
    } = this.props

    const {
      getFieldDecorator
    } = this.props.form;

    const userDetail = selectedRow ? selectedRow.user_detail : null
    const selectedRoles = selectedRow ? selectedRow.roles.map(r => r.name) : []

    return (
      <Modal
        title={`${selectedRow ? 'Edit' : 'Add New'} User`}
        visible={showFormModal}
        onCancel={this.handleCloseModal}
        footer={null}
        width={800}
        className="admin-management-modal"
      >
        <Layout>
          <Content className='contact-form-container'>
            <Row type='flex' justify='center' gutter={30}>
              <Col span={22}>
                <Form onSubmit={this.handleSubmit} className='login-form'>
                  <Row style={{ margin: "15px 0px" }}>
                    <Col span={24}>
                      <span style={{ color: "red" }}>*</span> - Field with this icon are required
                    </Col>
                  </Row>
                  <Row type="flex" gutter={16}>
                    <Col span={12}>
                      <Form.Item label={<label>First Name <span style={{ color: "red" }}>*</span></label>} colon={false}>
                        {getFieldDecorator(`first_name`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter first name.",
                            },
                          ],
                          initialValue: userDetail ? userDetail.first_name : ''
                        })(
                          <Input placeholder="Please Enter First Name" />)}
                      </Form.Item>
                    </Col>
                    {!selectedRow ?
                      <Col span={12}>
                        <Form.Item label={<label>Email Address <span style={{ color: "red" }}>*</span></label>} colon={false}>
                          {getFieldDecorator(`email`, {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Please enter email.",
                              },
                              {
                                type: "email",
                                message: "Please enter a valid email.",
                              },
                              {
                                validator: (rule, value, callback) => {
                                  try {
                                    if (users.some(r =>
                                      selectedRow ? selectedRow.email.toLowerCase() !== r.email.toLowerCase() : true &&
                                        r.email.toLowerCase() === value.toLowerCase()
                                    )) {
                                      callback("email already exists");
                                    } else {
                                      callback();
                                    }
                                  } catch (err) {
                                    callback(err);
                                  }
                                }
                              }
                            ],
                            initialValue: userDetail ? userDetail.middle_name : ''
                          })(
                            <Input placeholder="Please Enter Email" />)}
                        </Form.Item>
                      </Col>
                      : ''}
                    <Col span={12}>
                      <Form.Item label={<label>Middle Name <span style={{ color: "red" }}>*</span></label>} colon={false}>
                        {getFieldDecorator(`middle_name`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter middle name.",
                            },
                          ],
                          initialValue: userDetail ? userDetail.middle_name : ''
                        })(
                          <Input placeholder="Please Enter Middle Name" />)}
                      </Form.Item>
                    </Col>
                    {!selectedRow ?
                      <Col span={12}>
                        <Form.Item label={<label>Password <span style={{ color: "red" }}>*</span></label>} colon={false}>
                          {getFieldDecorator(`password`, {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Please enter password.",
                              },
                            ],
                          })(
                            <Input.Password placeholder="Please Enter Password" />)}
                        </Form.Item>
                      </Col>
                      : ''}
                    <Col span={12}>
                      <Form.Item label={<label>Last Name <span style={{ color: "red" }}>*</span></label>} colon={false}>
                        {getFieldDecorator(`last_name`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter last name.",
                            },
                          ],
                          initialValue: userDetail ? userDetail.last_name : ''
                        })(
                          <Input placeholder="Please Enter Last Name" />)}
                      </Form.Item>
                    </Col>
                    {!selectedRow ?
                      <Col span={12} >
                        <Form.Item label={<label>Re-enter Password <span style={{ color: "red" }}>*</span></label>} colon={false}>
                          {getFieldDecorator(`confirm_password`, {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "Please re-enter password.",
                              },
                              {
                                validator: this.compareToFirstPassword,
                              },
                            ],
                          })(
                            <Input.Password placeholder="Please Re-enter Password" onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                      </Col>
                      : ''}
                    <Col span={12}>
                      <Form.Item label={<label>Code</label>} colon={false}>
                        {getFieldDecorator(`code`, {
                          initialValue: userDetail ? userDetail.code : ''
                        })(
                          <Select
                            showSearch
                            placeholder="Select a code"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                          >
                            {salesman.map((value) => (
                              <Option value={value}>{value}</Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={<label>Mobile Number <span style={{ color: "red" }}>*</span></label>} colon={false}>
                        {getFieldDecorator(`mobile_number`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter mobile number.",
                            },
                          ],
                          initialValue: userDetail ? userDetail.contact_number : ''
                        })(
                          <Input placeholder="Please Enter Mobile Number" />)}
                      </Form.Item>
                    </Col>

                    <Col span={12} id="selectAccess">
                      <Form.Item label={<label>Roles And Access <span style={{ color: "red" }}>*</span></label>} colon={false}>
                        {getFieldDecorator(`access_type`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please select access type",
                              type: 'array'
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value) {
                                  if (value.length > 1) {
                                    callback("No more than 1 access type");
                                  } else if (value.length <= 1) {
                                    callback();
                                  }
                                }
                                return;
                              }
                            }
                          ],
                          initialValue: selectedRoles
                        })(
                          <Select
                            mode="multiple"
                            placeholder='Select Access Type'
                            getPopupContainer={() => document.getElementById('selectAccess')}
                          >
                            {roles.map((role) =>
                              <Option key={role.id} value={role.name}>{role.name}</Option>
                            )}
                          </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='Status'>
                        {this.props.form.getFieldDecorator('status', {
                          rules: [{ required: true, message: 'Please input your Status!' }],
                          initialValue: selectedRow ? selectedRow.status.toString() : ""
                        })(
                          <Select placeholder='Select a Status' showSearch>
                            <Option value='Active'>Active</Option>
                            <Option value='Inactive'>Inactive</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='Account Type'>
                        {this.props.form.getFieldDecorator('is_admin', {
                          rules: [{ required: true, message: 'Please input your Account Type!' }],
                          initialValue: selectedRow ? selectedRow.is_admin : ""
                        })(
                          <Select placeholder='Select an Account Type' showSearch>
                            <Option value='Super Admin'>Super Admin</Option>
                            <Option value='Admin'>Admin</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" justify="end" gutter={16}>
                    <Col xs={24} sm={12} md={8} lg={6} align="center" style={{ margin: "5px 0px" }}>
                      <Button style={{ width: "100%", border: "none", fontSize: "16px", boxShadow: "none" }} onClick={this.handleCloseModal} disabled={isLoading}>
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} align="center" style={{ margin: "5px 0px" }}>
                      <Button type="primary" htmlType="submit" style={{ width: "100%", fontSize: "16px" }} disabled={isLoading} loading={isLoading}>
                        {selectedRow ? 'Update' : 'Create'} User
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Modal >
    )
  }
}

export default Form.create({ name: 'admin_modal_form' })(ModalForm);
