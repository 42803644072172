import Axios from '../../../utils/axios'

export const loadCustomerGroup = (data) => {
    return dispatch => {
        Axios.get('/admin/customer-group', dispatch, 'GET_CUSTOMER_GROUP_DATA', data)
    }
}

export const loadCustomerByGroup = (data) => {
    return dispatch => {
        Axios.get('/admin/fetch-customer-by-group', dispatch, 'GET_FETCH_CUSTOMER_BY_GROUP_DATA', data)
    }
}

export const updateCustomerGroup = (data, searchParams) => {
    return dispatch => {
        return Axios.put(`/admin/customer-group/${data.id}`, dispatch, 'POST_CUSTOMER_GROUP_DATA', data)
            .then((response) => {
                dispatch(loadCustomerGroup(searchParams))
                return response
            })
    }
}
