import moment from 'moment'
import * as _ from "lodash";

let initialData = {
  distributorLoading: false,
  distributorsData: [],
  distributorPricelistLoading: false,
  distributorPricelistsData: [],
  pricelistByProductLoading: false,
  pricelistByProductData: [],
  productVariantData: [],
  productVariantLoading: false,
  searchProductVariantData: [],
  searchProductVariantLoading: false,
  productVariantEditPricelist: false,
  showHidePricelistLoading: false,
  productsData: [],
  productsLoading: false,
  storageLocationData: [],
  storageLocationLoading: false,
  customerSoldToData: [],
  customerSoldToLoading: false,
  customerShipToData: [],
  customerShipToLoading: false,
  customerSalesmanData: [],
  customerSalesmanLoading: false,
  updateCustomerSalesmanLoading: false,
  updateCustomerMaterialTagging: false,
  priceListV2: [],
  priceListV2Loading: false,
  materialPrice: {}
};

export default function distributorList(state = initialData, action) {
  switch (action.type) {
    case "GET_CUSTOMER_DATA":
      return {
        ...state,
        distributorLoading: true
      };
    case "GET_CUSTOMER_DATA_FULFILLED":
      action.payload.data.map((data, i) => {
        data.status = data.status ? 'Active' : 'Inactive'
        data.ewt_status = data.ewt_status ? 'Yes' : 'No'
        data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
        data.created_at_number = moment(data.created_at).format("MM/DD/YYYY")
      })
      return {
        ...state,
        distributorsData: action.payload,
        distributorLoading: false
      };
    case "GET_CUSTOMER_DATA_REJECTED":
      return {
        ...state,
        distributorsData: "",
        distributorLoading: false
      };

    case "GET_CUSTOMER_PRICELIST_DATA":
      return {
        ...state,
        distributorPricelistLoading: true
      };
    case "GET_CUSTOMER_PRICELIST_DATA_FULFILLED":
      return {
        ...state,
        distributorPricelistsData: action.payload,
        distributorPricelistLoading: false
      };
    case "GET_CUSTOMER_PRICELIST_DATA_REJECTED":
      return {
        ...state,
        distributorPricelistsData: "",
        distributorPricelistLoading: false
      };
    case "GET_MATERIAL_DATA":
      return {
        ...state,
        pricelistByProductLoading: true
      };
    case "GET_MATERIAL_DATA_FULFILLED":
      action.payload.filter(data => data.status === 1)
      return {
        ...state,
        pricelistByProductData: action.payload,
        pricelistByProductLoading: false
      };
    case "GET_MATERIAL_DATA_REJECTED":
      return {
        ...state,
        pricelistByProductData: "",
        pricelistByProductLoading: false
      };

    case "POST_PRODUCT_VARIANT_SEARCH_DATA":
      return {
        ...state,
        pricelistByProductLoading: true
      };
    case "POST_PRODUCT_VARIANT_SEARCH_DATA_FULFILLED":
      if (_.some(action.payload, (data) => { return data === null })) {
        action.payload = _.filter(_.values(action.payload), (data) => { return data !== null })
        return {
          ...state,
          pricelistByProductData: action.payload,
          pricelistByProductLoading: false
        };
      } else {
        return {
          ...state,
          pricelistByProductData: action.payload,
          pricelistByProductLoading: false
        };
      }

    case "POST_PRODUCT_VARIANT_SEARCH_DATA_REJECTED":
      return {
        ...state,
        pricelistByProductData: "",
        pricelistByProductLoading: false
      };

    case "GET_PRODUCT_VARIANT_DATA":
      return {
        ...state,
        productVariantLoading: true,
        productVariantData: []
      };
    case "GET_PRODUCT_VARIANT_DATA_FULFILLED":
      return {
        ...state,
        productVariantData: action.payload,
        productVariantLoaded: true,
        productVariantLoading: false
      };
    case "POST_EDIT_PRICE_LIST":
      return {
        ...state,
        productVariantEditPricelist: true
      };

    case "POST_EDIT_PRICE_LIST_FULFILLED":
      return {
        ...state,
        productVariantEditPricelist: false
      };
    case "POST_EDIT_PRICE_LIST_REJECTED":
      return {
        ...state,
        productVariantEditPricelist: false
      };

    case "POST_SHOW_HIDE_PRICELIST":
      return {
        ...state,
        showHidePricelistLoading: true
      };

    case "POST_SHOW_HIDE_PRICELIST_FULFILLED":
      return {
        ...state,
        showHidePricelistLoading: false
      };
    case "POST_SHOW_HIDE_PRICELIST_REJECTED":
      return {
        ...state,
        showHidePricelistLoading: false
      };
    case "GET_STORAGE_LOCATION_DATA":
      return {
        ...state,
        storageLocationData: action.payload,
        storageLocationLoading: false
      };
    case "POST_CREATE_MATERIAL_LINK":
      return {
        ...state
      };
    case "POST_CREATE_MATERIAL_LINK_FULFILLED":
      return {
        ...state
      };
    case "POST_CREATE_MATERIAL_LINK_REJECTED":
      return {
        ...state
      };
    case "POST_UPDATE_AVAILABILITY_DATE":
      return {
        ...state
      };
    case "GET_CUSTOMER_SOLD_TO":
      return {
        ...state,
        customerSoldToLoading: true
      };
    case "GET_CUSTOMER_SOLD_TO_FULFILLED":
      return {
        ...state,
        customerSoldToData: action.payload,
        customerSoldToLoading: false
      };
    case "GET_CUSTOMER_SOLD_TO_REJECTED":
      return {
        ...state
      };
    case "GET_CUSTOMER_SHIP_TO":
      return {
        ...state,
        customerShipToLoading: true
      };
    case "GET_CUSTOMER_SHIP_TO_FULFILLED":
      return {
        ...state,
        customerShipToData: action.payload,
        customerShipToLoading: false
      };
    case "GET_CUSTOMER_SHIP_TO_REJECTED":
      return {
        ...state
      };
    case "GET_CUSTOMER_SALESMAN":
      return {
        ...state,
        customerSalesmanLoading: true
      };
    case "GET_CUSTOMER_SALESMAN_FULFILLED":
      return {
        ...state,
        customerSalesmanData: action.payload,
        customerSalesmanLoading: false
      };
    case "GET_CUSTOMER_SALESMAN_REJECTED":
      return {
        ...state
      };
    case "POST_CUSTOMER_SALESMAN":
      return {
        ...state,
        updateCustomerSalesmanLoading: true
      };
    case "POST_CUSTOMER_SALESMAN_FULFILLED":
      return {
        ...state,
        updateCustomerSalesmanLoading: false
      };
    case "POST_UPDATE_TAGGING":
      return {
        ...state,
        updateCustomerMaterialTagging: true
      };
    case "POST_UPDATE_TAGGING_FULFILLED":
      return {
        ...state,
        updateCustomerMaterialTagging: false
      };
    case "GET_PRICE_BY_SOURCE":
      return {
        ...state,
        pricelistByProductLoading: true
      };
    case "GET_PRICE_BY_SOURCE_FULFILLED":
      let material = action.payload[0];
      let priceList = state.pricelistByProductData;
      const index = _.findIndex(priceList, function (o) { return o.id === material.id; });

      priceList[index] = material;

      return {
        ...state,
        pricelistByProductData: priceList,
        pricelistByProductLoading: false
      };
    case "GET_PRICE_BY_SOURCE_REJECTED":
      return {
        ...state
      };
    case "GET_PRICING_DATA":
      return {
        ...state,
        priceListV2Loading: true
      };
    case "GET_PRICING_DATA_FULFILLED":
      return {
        ...state,
        priceListV2: action.payload,
        priceListV2Loading: false
      };
    case "GET_PRICING_DATA_REJECTED":
      return {
        ...state,
        priceListV2: [],
        priceListV2Loading: false
      };
    case "GET_MATERIAL_PRICE":
      return {
        ...state,
        priceListV2Loading: true
      };
    case "GET_MATERIAL_PRICE_FULFILLED":
      return {
        ...state,
        materialPrice: action.payload,
        priceListV2Loading: false
      };
    case "GET_MATERIAL_PRICE_REJECTED":
      return {
        ...state,
        materialPrice: {},
        priceListV2Loading: false
      };
    case "PUT_SYNC_CUSTOMER_PRICE":
      return {
        ...state,
        distributorLoading: true
      };
    case "PUT_SYNC_CUSTOMER_PRICE_FULFILLED":
      return {
        ...state,
        distributorLoading: false
      };
    case "PUT_SYNC_CUSTOMER_PRICE_REJECTED":
      return {
        ...state,
        distributorLoading: false
      };
    case "PUT_SYNC_PRICE":
      return {
        ...state,
        distributorLoading: true
      };
    case "PUT_SYNC_PRICE_FULFILLED":
      return {
        ...state,
        distributorLoading: false
      };
    case "PUT_SYNC_PRICE_REJECTED":
      return {
        ...state,
        distributorLoading: false
      };
    default:
      return {
        ...state
      };
  }
}
