import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input } from 'antd';

const { Content } = Layout;

export default function ModalForm(props) {
  const { selectedRow } = props;

  if (selectedRow === null) {
    return "";
  }

  return (
    <Modal
      title="View Details"
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>
            <Row type="flex" justify="center" gutter={30}>
              <Col span={8} align="center" style={{ height: 200 }}>
                <div style={{ position: "relative", height: '200px' }}>
                  {
                    selectedRow.image ? (<img
                      alt={selectedRow.name}
                      src={process.env.REACT_APP_FILES_URL + selectedRow.image}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />) : (<div style={{ verticalAlign: "middle", marginTop: "30%" }}>No Image Found</div>)
                  }
                </div>
              </Col>
              <Col span={16}>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Name">
                      <Input value={selectedRow.description} readOnly={true} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Material Number">
                      <Input value={selectedRow.material_number} readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={30}>
              <Col span={12}>
                <Form.Item label="Gross Weight">
                  <Input value={selectedRow.gross_weight} readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Volume">
                  <Input value={selectedRow.volume} readOnly={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="start" gutter={30}>
              <Col span={12}>
                <Form.Item label="Sales Unit">
                  <Input value={selectedRow.sales_unit} readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Status">
                  <Input value={selectedRow.status} readOnly={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="start" gutter={30}>
              <Col span={12}>
                <Form.Item label="Separate Sales Order">
                  <Input value={selectedRow.is_separate_order} readOnly={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal}>
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Row>
        </Content>
      </Layout>
    </Modal>
  )
}
