import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible'
import {
    loadCustomerGroup,
    updateCustomerGroup,
    loadCustomerByGroup
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class CustomerGroup extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const CustomerGroupForm = Form.create({ name: 'customer_group_form' })(CustomerGroup);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        customerGroupLoading: state.customerGroup.loading,
        customerGroupData: state.customerGroup.tableData,
        customerByGroupData: state.customerGroup.customerByGroup,
        customerByGroupLoading: state.customerGroup.customerByGroupLoading,
        updatesLoading: state.customerGroup.updatesLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadCustomerGroupData: (data) => dispatch(loadCustomerGroup(data)),
        onLoadCustomerByGroup: (data) => dispatch(loadCustomerByGroup(data)),
        onSubmitUpdateCustomerGroup: (data, searchParams) => dispatch(updateCustomerGroup(data, searchParams))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGroupForm);