import Axios from 'axios'
import { headers } from '../utils/token';

export const FETCH_INFO = 'FETCH_INFO';
export const FETCH_INFO_FULFILLED = 'FETCH_INFO_FULFILLED';
export const FETCH_INFO_REJECTED = 'FETCH_INFO_REJECTED';

const API_URL = process.env.REACT_APP_API_URL

export const fetchInfo = (data) => {
    return dispatch => {
        dispatch({
            type: FETCH_INFO,
            payload: {}
        })
        return Axios.get(`${API_URL}/my-info`, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: FETCH_INFO_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                dispatch({
                    type: FETCH_INFO_REJECTED,
                    payload: error
                })
            })
    }
}
