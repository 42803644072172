import React from "react";
import { Link } from "react-router-dom";
import { Menu, Layout, Icon } from "antd";
import * as _ from "lodash";

import './Sider.scss'

const { SubMenu } = Menu;
function Sider({ location, paths }) {
  let selectedKeys = _.chain(paths)
    .filter(path => location.pathname.includes(path.slug))
    .map("slug")
    .value();
  selectedKeys = !selectedKeys.length
    ? [_.find(paths, ["default", true]).slug]
    : selectedKeys;

  return (
    <Layout.Sider collapsible={true} style={ { padding: 0 }} breakpoint={"sm"} theme="dark" collapsedWidth="0">
      <Link to="/" className="logo" />
      <Menu
        className="ant-menu-urc left"
        mode="inline"
        theme="light"
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        defaultOpenKeys={['sub1']}
        style={{
          float: "left",
          lineHeight: "61px",
          height: "100%"
        }}
      >
        <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="dashboard" />
              <span>Dashboard</span>
            </span>
          }
        >
          <Menu.Item key="analytics-dashboard">
            <Link data-menu-label="analytics-dashboard" to="/" className="analytics-dashboard-link">
              <span>OMS Analytics<br />Dashboard</span>
            </Link>
          </Menu.Item>
        </SubMenu>

        {_.map(paths, path => {
          if (path.slug === 'homepage'
            || path.slug === 'about-us-page'
            || path.slug === 'analytics-dashboard' 
            || path.slug === 'inquiry-dashboard' 
            || path.slug === 'feedback' 
            || path.slug === 'user-types' 
            || path.slug === 'admin-management' 
            || path.slug === 'access' 
            || path.slug === 'features') {
            return;
          }
          return (
            <Menu.Item key={path.slug}>
              <Link data-menu-label={path.label} to={path.route}>
                <Icon type={path.icon} />
                <span>{path.label}</span>
              </Link>
            </Menu.Item>
          );
        })}

        <SubMenu
          key="sub2"
          title={
            <span>
              <Icon type={'audit'} />
              <span>Audit Trail</span>
            </span>
          }
        >
          <Menu.Item key='access'><Link to='/audit-trail/access'>Access</Link></Menu.Item>
          <Menu.Item key='features'><Link to='/audit-trail/features'>Features</Link></Menu.Item>
        </SubMenu>


        <SubMenu
          key="sub3"
          title={
            <span>
              <Icon type="line-chart" />
              <span>Reports</span>
            </span>
          }
        >
          <Menu.Item key="feedback">
            <Link data-menu-label="feedback" to="/feedback">
              <Icon type="container" />
              Feedback Survey
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub4"
          title={
            <span>
              <Icon type={'user'} />
              <span>Admin</span>
            </span>
          }
        >
          <Menu.Item key='admin-management'><Link to='/admin-management'>Admin Management</Link></Menu.Item>
          <Menu.Item key='user-types'><Link to='/user-types'>User Types</Link></Menu.Item>
        </SubMenu>


      </Menu>
    </Layout.Sider>
  );
}

export default Sider;
