import Axios from '../../../utils/axios'

const PRICING_URL = process.env.REACT_APP_API_PRICING_URL;

export const loadStorageLocation = (data) => {
    return dispatch => {
        return Axios.get(`/admin/storage-location`, dispatch, 'GET_STORAGE_LOCATION_DATA', data)
            .then((response) => {
                return response
            })
    }
}

export const submitAddSubAccount = (data) => {
    return dispatch => {
        return Axios.post(`/admin/distributor/${data.distributor_id}/employee`, dispatch, 'POST_SUB_ACCOUNT', data)
            .then((response) => {
                return response
            })
    }
}

export const loadUserRolesData = (data) => {
    return dispatch => {
        Axios.get('/admin/role', dispatch, 'GET_USER_ROLES', data)
    }
}

export const loadDistributorsData = (data) => {
    return dispatch => {
        return Axios.get('/admin/customers', dispatch, 'GET_CUSTOMER_DATA', data)
    }
}

export const submitUpdateDistributor = (data, searchParams) => {
    return dispatch => {
        return Axios.post(`/admin/customer/${data.id}`, dispatch, 'POST_DISTRIBUTOR_DATA', data)
            .then((response) => {
                dispatch(loadDistributorsData(searchParams))
                return response
            })
    }
}

export const submitUpdateSubCustomer = (data) => {
    return dispatch => {
        return Axios.post(`/admin/customer/${data.id}`, dispatch, 'POST_DISTRIBUTOR_DATA', data);
    }
}

export const submitUpdateTagging = (data) => {
    return dispatch => {
        return Axios.post(`/admin/customer-pricelists/material-tagging`, dispatch, 'POST_UPDATE_TAGGING', data);
    }
}

export const submitUpdateSalesman = (data) => {
    return dispatch => {
        return Axios.post(`/admin/customers/salesman/${data.id}`, dispatch, 'POST_CUSTOMER_SALESMAN', data);
    }
}

export const submitEditPriceList = (data) => {
    return dispatch => {
        return Axios.post(`/admin/customer-pricelist-update`, dispatch, 'POST_EDIT_PRICE_LIST_DATA', data)
            .then((response) => {
                return response;
            })
    }
}

export const submitShowHideVariant = (data) => {
    return dispatch => {
        return Axios.post(`/admin/product-variant-is-hidden`, dispatch, 'POST_EDIT_SHOW_HIDE_VARIANT_DATA', data)
            .then((response) => {
                return response;
            })
    }
}

export const loadProductVariantData = (data) => {
    return dispatch => {
        Axios.get(`/admin/customer-pricelist`, dispatch, 'GET_MATERIAL_DATA', data)
            .then(function (response) {
                return response
            })
    }
}

export const searchProductVariant = (data) => {
    return dispatch => {
        Axios.post(`/admin/product-variants-search`, dispatch, 'POST_PRODUCT_VARIANT_SEARCH_DATA', data)
            .then((response) => {
                return response
            })
    }
}

export const createCustomerMaterial = (data) => {
    return dispatch => {
        Axios.post(`/admin/create-customer-material-link`, dispatch, 'POST_CREATE_MATERIAL_LINK', data)
            .then((response) => {
                return response
            })
    }
}

export const loadSoldTo = (data) => {
    return dispatch => {
        Axios.get(`/admin/customer-sold-to-list`, dispatch, 'GET_CUSTOMER_SOLD_TO', data)
            .then((response) => {
                return response
            })
    }
}

export const loadShipTo = (data) => {
    return dispatch => {
        Axios.get(`/admin/customer-ship-to-list`, dispatch, 'GET_CUSTOMER_SHIP_TO', data)
            .then((response) => {
                return response
            })
    }
}

export const loadSalesman = (data) => {
    return dispatch => {
        Axios.get(`/admin/customers/salesman`, dispatch, 'GET_CUSTOMER_SALESMAN', data)
            .then((response) => {
                return response
            })
    }
}

export const priceChangeBySource = (data) => {
    return dispatch => {
        Axios.get(`/admin/price-by-source`, dispatch, 'GET_PRICE_BY_SOURCE', data)
            .then((response) => {
                return response
            })
    }
}

export const updateMaterialAvailabilityDate = (id, value, material_variant_id, customer_id) => {
    return dispatch => {
        return Axios.post(`/admin/customer-pricelists/availability-date`, dispatch, 'POST_UPDATE_AVAILABILITY_DATE', {
            key: id,
            value: value,
            material_variant_id: material_variant_id,
            customer_id: customer_id,
        })
            .then((response) => {
                return response
            })
    }
}
export const loadPrice = (customerCode) => {
    return dispatch => {
        Axios.customGet(`${PRICING_URL}/customer/${customerCode}/material`, dispatch, 'GET_PRICING_DATA')
            .then(function (response) {
                return response
            })
    }
}
export const loadMaterialPrice = (customerCode, materialCode) => {
    return dispatch => {
        Axios.customGet(`${PRICING_URL}/customer/${customerCode}/material/${materialCode}`, dispatch, 'GET_MATERIAL_PRICE')
            .then(function (response) {
                return response
            })
    }
}

export const syncCustomerPrice = (customerId) => {
    return dispatch => {
        return Axios.customPut(`${PRICING_URL}/customer/${customerId}`, dispatch, 'PUT_SYNC_CUSTOMER_PRICE');
    }
}

export const syncPrice = () => {
    return dispatch => {
        return Axios.customPut(`${PRICING_URL}/customer`, dispatch, 'PUT_SYNC_PRICE');
    }
}