import React, { Component } from 'react';
import { Layout, Row, Col, Input, Result, Select, Form, Table, DatePicker } from 'antd';
import _ from 'lodash';
// Import CSS
import './FeaturesAuditTrail.scss'
import ModalView from './components/ModalView'
import moment from 'moment';
const { Content } = Layout
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

class FeaturesAuditTrail extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      showModalView: false,
      selectedRow: null,
      sortState: {
        sortBy: 'audit_trails.created_at',
        orderBy: 'desc',
        filters: ''
      },
      filterBy: 'audit_trails.created_at',
      searchState: {},
      pageSearch: {}
    }
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadFeaturesAuditTrail()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.featuresAuditTrailData !== nextProps.featuresAuditTrailData) {
      this.setState({
        tableData: nextProps.featuresAuditTrailData
      })
    }
  }

  handleSearch = (searchTerm) => {
    this.setState({
      searchState: searchTerm.trim() === '' || searchTerm === 'all' ? {} : {
        searchValue: searchTerm,
        searchField: this.state.filterBy
      }
    }, () => {
      this.props.onLoadFeaturesAuditTrail({ ...this.state.sortState, ...{ page: 1 }, ...this.state.searchState });
    });
  }

  handleDatePicker = (value) => { 
    this.setState({
      searchState: _.isEmpty(value) ? {} : {
        searchField: this.state.filterBy,
        searchValue: [value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')].join(),
      },
    }, () => {
      this.props.onLoadFeaturesAuditTrail({ ...this.state.sortState, ...{ page: 1 }, ...this.state.searchState });
    })
  }

  handleFilterBy = (value) => {
    this.setState({ filterBy: value, keyword: "" });
  };

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;

    this.setState({
      sortState: {
        sortBy: sorter.columnKey || this.state.sortState.sortBy,
        orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
        filters: JSON.stringify(filters)
      }
    }, () => {
      if (!sorter.order) {
        ['sortBy', 'orderBy'].forEach(key => delete this.state.sortState[key])
      }
      
      this.props.onLoadFeaturesAuditTrail({ ...this.state.sortState, ...{ page: pagination.current }, ...this.state.searchState });
    })
  }

  handleOpenModal = (record) => {
    this.setState({
      showModalView: true,
      selectedRow: record
    });
  }

  handleCloseModal = () => {
    this.setState({
      showModalView: false,
      selectedRow: null
    });
  }

  render() {
    const { showModalView, selectedRow, tableData, filterBy } = this.state;
    const { myPermissions, myPermissionsLoading, featuresAuditTrailLoading } = this.props
    const columns = [
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'audit_trails.created_at',
        sorter: true,
        render: (value) => {
          return (
            <div>
              {moment(value).format('YYYY-MM-DD')}
            </div>
          )
        }
      },
      {
        title: 'Time',
        dataIndex: 'created_at',
        key: 'audit_trails.created_at',
        render: (value) => {
          return (
            <div>
              {moment(value).format('HH:mm:ss')}
            </div>
          )
        }
      },
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'audit_trails.feature',
        sorter: true,
        render: data => (<span style={{ textTransform: 'capitalize' }}>{data.replace('_', ' ')}</span>)
      },
      {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'audit_trails.activity',
        sorter: true,
        render: data => (<span style={{ textTransform: 'capitalize' }}>{data}</span>)
      },
      {
        title: 'Reference',
        render: (value, data) => {
          return (
            <div>
              {data.feature === 'user_types' ? data.role?.name || "N/A" : null}
              {data.feature === 'admin_management' ? data.user?.email || "N/A" : null}
              {data.feature === 'materials' ? (data.material?.description || "N/A") + (data.material ? ` (${data.material.material_number})` : "") : null}
              {data.feature === 'customers' ? (data.customer?.name || "N/A") + (data.customer ? ` (${data.customer.customer_code})` : "") : null}
              {data.feature === 'customer_group' ? (data.customer_group?.customer_group_code || "N/A") : null}
              {data.feature === 'storage_location' ? data.storage_location?.description + (data.storage_location ? ` (${data.storage_location.plant})` : "") || "N/A" : null}
            </div>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'user_action',
        key: 'users.email',
        sorter: true,
        render: (data) => {
          return (
            <div>{data.email}</div>
          )
        }
      },
    ];

    if (myPermissionsLoading) return null

    return myPermissions.some(r => r.name === 'admin.faq.view') ? (
      <div className='audit-trail-page'>
        <Layout>
          <Content className='audit-trail-content-page' style={{ padding: 25 }}>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <span className='audit-trail-title'>
                  FEATURES AUDIT TRAIL
                </span>
              </Col>
            </Row>
            <Row style={{
              background: '#FFFFFF',
              boxShadow: "0px 7px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: 6
            }}>
              <Col>
                <Layout.Content style={{ margin: 24, backgroundColor: 'white' }}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='audit-trail-search'>
                    <Col span={24}>
                      {filterBy === 'audit_trails.created_at' ? (
                        <RangePicker
                          className="datepicker"
                          size="large"
                          style={{ width: 230, }}
                          onChange={this.handleDatePicker}
                        />
                      ) : filterBy === 'audit_trails.feature' ? (
                        <Select size="large" 
                                defaultValue={'admin_management'} 
                                onSelect={this.handleSearch} 
                                showSearch
                                style={{ width: 230 }}>
                          <Option value='all'>All</Option>
                          <Option value='admin_management'>Admin Management</Option>
                          <Option value='customer'>Customer</Option>
                          <Option value='material'>Material</Option>
                          <Option value='storage_location'>Storage Location</Option>
                          <Option value='user_type'>User Type</Option>
                        </Select>
                      ) : filterBy === 'audit_trails.activity' ? (
                        <Select size="large" 
                                defaultValue={'edit'} 
                                onSelect={this.handleSearch} 
                                showSearch 
                                style={{ width: 230 }}>
                          <Option value='all'>All</Option>
                          <Option value='edit'>Edit</Option>
                          <Option value='create'>Create</Option>
                        </Select>
                      ) :  (
                        <Search
                          size="large"
                          className="search-container"
                          placeholder="Search"
                          onSearch={this.handleSearch}
                          style={{ width: 230 }}
                        />
                      )}
                      <Select style={{ width: 160, marginLeft: 10 }}
                        size="large"
                        placeholder="Filter"
                        onChange={(value) => this.handleFilterBy(value)}
                        defaultValue="audit_trails.created_at"
                        dropdownClassName="--select"
                        autoFocus={true}
                        showSearch>
                        <Option value="audit_trails.created_at">Date Created</Option>
                        <Option value="audit_trails.feature">Feature</Option>
                        <Option value="audit_trails.activity">Activity</Option>
                        <Option value="users.email">Email</Option>
                      </Select>
                    </Col>
                  </Row>
                </Layout.Content>
              </Col>
              <Table 
                    dataSource={tableData.data}
                    columns={columns}
                    onRow={(record) => {
                      return {
                        onClick: event => this.handleOpenModal(record)
                      };
                    }}
                    style={{ margin: 24 }}
                    onChange={this.handleOnChangeTableListener}
                    pagination={{ total: tableData.total, current: tableData.current_page, pageSize: tableData.per_page || 10 }}
                    loading={featuresAuditTrailLoading}
                />
            </Row>
          </Content>
        </Layout>

        {
          showModalView &&
          <ModalView
            showModalView={showModalView}
            handleCloseModal={this.handleCloseModal}
            selectedRow={selectedRow}
          />
        }

      </div >
    )
      : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

export default Form.create({ name: 'AuditTrail' })(FeaturesAuditTrail);