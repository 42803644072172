
const initialData = {
    partnerFunctionLoading: false,
    partnerFunctionData: []
}

export default function partnerFunction(state = initialData, action) {
    switch (action.type) {
        case 'GET_PARTNER_FUNCTION':
            return {
                ...state,
                partnerFunctionLoading: true
            }
        case 'GET_PARTNER_FUNCTION_FULFILLED':
            return {
                ...state,
                partnerFunctionData: action.payload,
                partnerFunctionLoading: false
            }
        case 'GET_PARTNER_FUNCTION_REJECTED':
            return {
                ...state,
                partnerFunctionData: null,
                partnerFunctionLoading: false
            }

        default:
            return {
                ...state
            }
    }
}