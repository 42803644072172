import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input, Table, DatePicker, Descriptions } from 'antd'

import './ModalView.scss'
import { numberFormat } from "../../../../utils";
import _ from 'lodash';
import moment from 'moment';
const { ColumnGroup } = Table;
const { Search, TextArea } = Input;
const { Content } = Layout

export default class PlannerModalForm extends Component {
  constructor() {
    super()
    this.state = {
      showRequestModal: false,
      // keyword: "",
      comments: "",
      tableData: [],
      sapData: [],
      searchedData: [],
      searchText: "",
    }
  }

  handleCloseModal = () => {
    this.props.form.resetFields()
    this.props.form.handleCloseModal()
  };

  componentWillReceiveProps(nextProps) {

    const { selectedRow, orderStatusData } = this.props
    let { product } = nextProps
    product = ((nextProps.selectedRow)) ? nextProps.selectedRow.product : []
    let newNextProps = nextProps.selectedRow && (nextProps.selectedRow.product || '')
    let newThisProps = selectedRow && (selectedRow.product || '')
    if (newThisProps !== newNextProps) {
      this.setState({ tableData: product })
    }

    if (orderStatusData !== nextProps.orderStatusData) {
      this.setState({ sapData: nextProps.orderStatusData })
    }
  }

  confirm = (event) => {
    const { selectedRow, form } = this.props
    let params = {
      materials: {},
      order_id: selectedRow.id,
    }

    Object.keys(selectedRow.order_material).forEach(function (key) {
      params.materials[selectedRow.order_material[key].material_code] = selectedRow.order_material[key].proposed_date ? moment(selectedRow.order_material[key].proposed_date).format("YYYY-MM-DD") : moment(selectedRow.order_material[key].availability_date).format("YYYY-MM-DD");
    });

    Modal.confirm({
      title: "Order Confirm",
      content: `Are you sure you want to confirm the order?`,
      okText: 'Okay',
      onOk: () => {
        event.preventDefault()
        form.validateFields((err, values) => {

          if (!err) {
            this.props.onOrderConfirm({
              ...params, ...{
                "reason": values.reason,
                "delivery_date": moment(Math.max(...selectedRow.order_material.map(value => moment(value.proposed_date || value.availability_date)))).format("YYYY-MM-DD")
              }
            }).then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Something went wrong',
                  content: ''
                })
              } else {
                Modal.success({
                  title: `Order Confirmed`,
                  content: 'Please wait for SAP to Confirm'
                })
              }
            })

            this.props.handleCloseModal();
          }
        });
      }
    })
  }

  propose = (e) => {
    Modal.confirm({
      title: "Order Propose",
      content: `Are you sure you want to propose the order?`,
      okText: 'Okay',
      onOk: () => {
        e.preventDefault()
        const { form, selectedRow } = this.props
        let params = {
          materials: {},
          reason: "",
          order_id: selectedRow.id
        };

        form.validateFields((err, values) => {
          if (!err) {
            if (!values.reason) {
              Modal.warning({
                title: 'Reason Field is required',
                content: ''
              })

              return false;
            }

            params.reason = values.reason;
            Object.keys(values.materials).forEach(function (key) {
              params.materials[key] = values.materials[key].format("YYYY-MM-DD");
            });

            this.props.onProposeOrder(params).then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Something went wrong',
                  content: ''
                })
              } else {
                Modal.success({
                  title: `Proposal submitted to Salesman`,
                })
              }
            })

            this.props.handleCloseModal();
          }
        });
      }
    })
  }

  reject = (e) => {
    Modal.confirm({
      title: "Order Reject",
      content: `Are you sure you want to reject the order?`,
      okText: 'Okay',
      onOk: () => {
        e.preventDefault()
        const { form, selectedRow } = this.props

        form.validateFields((err, values) => {
          if (!err) {
            if (!values.reason) {
              Modal.warning({
                title: 'Reason Field is required',
                content: ''
              })

              return false;
            }

            this.props.onRejectOrder({
              "order_id": selectedRow.id,
              "reason": values.reason
            }).then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Something went wrong',
                  content: ''
                })
              } else {
                Modal.success({
                  title: `Order Rejected`
                })
              }
            })

            this.props.handleCloseModal();
          }
        });
      }
    })
  }

  render() {

    const { selectedRow, myPermissions, myInfo } = this.props;
    const { searchText, searchedData } = this.state
    const {
      getFieldDecorator
    } = this.props.form;

    if (selectedRow === null) {
      return "";
    }

    const plannerAccess = myPermissions.some((r) => r.name === "admin.customer_order.edit") && !selectedRow.mto_status && myInfo.roles[0].name === "Planner";
    const salsemanAccess = myPermissions.some((r) => r.name === "admin.customer_order.edit") && ["Propose"].includes(selectedRow.mto_status) && selectedRow.tagging === "mto" && myInfo.roles[0].name === "Salesman";

    const footer = (myPermissions.some((r) => r.name === "admin.customer_order.amount") ? (<Row className="footer-container">
      <Col span={8}>
      </Col>
      <Col span={16}>
        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>TOTAL</span>
            </div>
          </Col>

          <Col span={6} align="left">
            <div className="footer-label">
              <span>
                {
                  selectedRow.order_material.reduce(function (res, item) {
                    return res + (item.weight);
                  }, 0)
                }
              </span>
            </div>
          </Col>
          <Col span={6} align="left">
            <div className="footer-label">
              <span>
                {selectedRow.total_quantity}
              </span>
            </div>
          </Col>
          <Col span={6} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.vat_sales + selectedRow.order_price?.discount || 0) || "0.00"}
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>Less: Discount</span>
            </div>
          </Col>
          <Col span={18} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.discount || 0)}
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>Total, Net of Discount</span>
            </div>
          </Col>
          <Col span={18} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.vat_sales || 0) || "0.00"}
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>Add: VAT</span>
            </div>
          </Col>
          <Col span={18} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.vat_amount || 0)}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>Total Amount</span>
            </div>
          </Col>
          <Col span={18} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.total_amount || 0) || "0.00"}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>Less: EWT (If Applicable)</span>
            </div>
          </Col>
          <Col span={18} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.ewt_amount || 0) || "0.00"}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className="footer-label">
              <span>Amount for Payment</span>
            </div>
          </Col>

          <Col span={18} align="right">
            <div className="footer-label">
              <span>
                {numberFormat(selectedRow.order_price?.total_amount - selectedRow.order_price?.ewt_amount || 0) || "0.00"}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>) : "")

    const editFooter = <Row>
      <Col span={24} align="center">
        {selectedRow.reason_planner ?
          <div style={{ "margin": "35px 0" }} align="left">
            <span style={{ fontWeight: "500" }}>Planner Remarks: </span>
            <TextArea placeholder='Enter Reason' style={{ "height": "20vh" }} readOnly value={selectedRow.reason_planner} />
          </div>

          : ""}
        {selectedRow.reason_salesman ?
          <div style={{ "margin": "35px 0" }} align="left">
            <span style={{ fontWeight: "500" }}>Salesman Remarks: </span>
            <TextArea placeholder='Enter Reason' style={{ "height": "20vh" }} readOnly value={selectedRow.reason_salesman} />
          </div>

          : ""}
        {plannerAccess || salsemanAccess ? <Button id={selectedRow.id} onClick={this.confirm} style={{ margin: "5px" }} disabled={['Cancelled'].includes(selectedRow.status)}>Confirm</Button> : ""}
        {plannerAccess ? <Button onClick={this.propose} type="danger" style={{ margin: "5px" }} disabled={['Cancelled'].includes(selectedRow.status)}>Propose</Button> : ""}
        {salsemanAccess ? <Button onClick={this.reject} type="danger" style={{ margin: "5px" }}>Reject</Button> : ""}
        {plannerAccess || salsemanAccess ? <Form.Item colon={false} style={{ "padding-top": "10px" }}>
          {getFieldDecorator(`reason`)(
            <TextArea placeholder='Enter Reason' style={{ "height": "20vh" }} />)}
        </Form.Item> : ""}
      </Col>
    </Row>

    return (
      <Modal
        {...this.props}
        visible={this.props.showFormModal}
        onCancel={this.props.handleCloseModal}
        centered={true}
        footer={null}
        width={"80%"}
        className="order-modal-container"
      >
        <Layout>
          <Content>
            <div className='planner-header-container'>
              <Descriptions bordered>
                <Descriptions.Item span={2} label="Order No.:">{selectedRow.order_transaction_id}</Descriptions.Item>
                <Descriptions.Item span={2} label="Sales Order Number: ">{selectedRow.sales_order_number || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={2} label="Deliver To: ">{selectedRow.sold_to.name || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={2} label="Ship To: ">{selectedRow.ship_to?.name || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={2} label="Sold To Code: ">{selectedRow.sold_to.customer_code || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={2} label="Ship To Code: ">{selectedRow.ship_to?.customer_code || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={2} label="Address: ">{selectedRow.ship_to?.address + ', ' + selectedRow.ship_to?.city + ', ' + selectedRow.ship_to?.postal_code || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={2} label="Order Date: ">{moment(selectedRow.created_at).format("YYYY-MM-DD")}</Descriptions.Item>
                <Descriptions.Item label="Special Instructions: ">{selectedRow.special_instruction || "N/A"}</Descriptions.Item>
              </Descriptions>
            </div>
            <Row type='flex' style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
              <Col className="search-container">
              </Col>
            </Row>
            <Row type='flex' className="table-container">
              <Col span={24}>
                <Form className='login-form'>
                  <Table
                    dataSource={searchText.length ? searchedData : selectedRow.order_material}
                    footer={() => (
                      <React.Fragment>
                        {footer}
                        {editFooter}
                      </React.Fragment>
                    )}
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: true }}
                    rowKey="id"
                    bordered={true}
                  // pagination={false}
                  >
                    <Col title="PRODUCT" dataIndex="material_details" key="material_details" render={(materialDetails) => (
                      <span>
                        {materialDetails.material_number}
                        <br />
                        {materialDetails.description}
                      </span>
                    )} />
                    <Col title="AVAILABILITY DATE" dataIndex="availability_date" key="availability_date" render={(materialDetails) => (
                      <span>
                        {materialDetails || "N/A"}
                      </span>
                    )} />
                    <Col title="PROPOSED AVAILABILITY DATE" key="proposed_date" render={(text, orderMaterials) => (
                      plannerAccess && !selectedRow.mto_status ?
                        <Form.Item key={orderMaterials.id} colon={false} style={{ "padding-top": "10px" }}>
                          {getFieldDecorator(`materials[${orderMaterials.id}]`, {
                            rules: [{ type: 'object' }],
                            initialValue: moment(orderMaterials.availability_date)
                          })(
                            <DatePicker defaultValue={moment(orderMaterials.availability_date)} disabledDate={(current) => current && current < moment(orderMaterials.availability_date)} disabled={orderMaterials.tagging === "regular"} allowClear={false} />
                          )} </Form.Item> : (orderMaterials.proposed_date ? orderMaterials.proposed_date : orderMaterials.availability_date)
                    )} />
                    {["Confirmed"].includes(selectedRow.mto_status) ?
                      <Col title="CONFIRMED AVAILABILITY DATE" dataIndex="confirmed_availability_date" key="confirmed_availability_date" render={(confirmedAvailabilityDate, materialDetails) => (
                        <span>
                          {confirmedAvailabilityDate || materialDetails.availability_date}
                        </span>
                      )} /> : ""}
                    {myPermissions.some((r) => r.name === "admin.cusomer_order.amount") ? (<ColumnGroup title="AMOUNT (PHP/UNIT)">
                      <Col align="center" title="VAT-IN" dataIndex="unit_price" render={(unitPrice) => (
                        <span>  {numberFormat(unitPrice + (unitPrice * 0.12)) || "0.00"}</span>
                      )} key="vat-in" />
                      <Col align="center" title="VAT-EX" dataIndex="unit_price" render={(unitPrice) => (
                        <span>  {numberFormat(unitPrice) || "0.00"}</span>
                      )} key="vat-ex" />
                    </ColumnGroup>) : ""}
                    <Col title="ORDERED QUANTITY (IN KG)" dataIndex="weight" render={(weight) => (
                      <span >  {weight ? numberFormat(weight) : "0"}</span>
                    )} key="kg-quantity" />
                    <Col title="ORDERED QUANTITY" dataIndex="quantity" key="quantity" />
                    {myPermissions.some((r) => r.name === "admin.customer_order.amount") ? (<Col title="ORDERED AMOUNT (PHP)" dataIndex="price" render={(price) => (
                      <span>  {numberFormat(price ? price : 0) || "0.00"}</span>
                    )} key="price" />) : ""}
                  </Table>
                </Form>
              </Col>
            </Row>
            {/* </Form> */}
          </Content>
        </Layout>
      </Modal >
    )
  }
}
