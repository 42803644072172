import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadFeaturesAuditTrail
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class FeaturesAudit extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const FeaturesAuditForm = Form.create({ name: 'auditTrailForm' })(FeaturesAudit);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        featuresAuditTrailData: state.featuresAuditTrail.featuresAuditTrailData,
        featuresAuditTrailLoading: state.featuresAuditTrail.featuresAuditTrailLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadFeaturesAuditTrail: (data) => dispatch(loadFeaturesAuditTrail(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesAuditForm);
