import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible'
import {
    loadMaterialsData,
    submitAddMaterial,
    submitUpdateMaterial
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import {
    loadActiveProductData
} from '../Products/actions';
import Page from './Page';

class Materials extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const MaterialsForm = Form.create({ name: 'product_variant_form' })(Materials);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        materialLoading: state.materials.loading,
        materialsData: state.materials.tableData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadMaterialsData: (data) => dispatch(loadMaterialsData(data)),
        onSubmitAddMaterial: (data, searchParams) => dispatch(submitAddMaterial(data, searchParams)),
        onSubmitUpdateMaterial: (data, searchParams) => dispatch(submitUpdateMaterial(data, searchParams)),
        onLoadActiveProductData: (data) => dispatch(loadActiveProductData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsForm);