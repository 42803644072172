import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadStorageLocation,
    updateStorageLocationStatus,
    updateStorageLocationDetails,
    submitAddStorageLocation,
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class StorageLocation extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const StorageLocationForm = Form.create({ name: 'storage_location_form' })(StorageLocation);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        storageLocationUpdating: state.storageLocation.storageLocationUpdating,
        storageLocationLoading: state.storageLocation.storageLocationLoading,
        storageLocationData: state.storageLocation.storageLocationData
    };
}


function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadStorageLocation: (data) => dispatch(loadStorageLocation(data)),
        onUpdateStorageLocationStatus: (data, id, searchParams) => dispatch(updateStorageLocationStatus(data, id, searchParams)),
        onUpdateStorageLocationDetails: (data, id, searchParams) => dispatch(updateStorageLocationDetails(data, id, searchParams)),
        onSubmitAddStorageLocation: (data, searchParams) => dispatch(submitAddStorageLocation(data, searchParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorageLocationForm);