import { combineReducers } from 'redux'

import roles from './roles'
import permissions from './permissions'
import partnerFunction from './partner_function'



export default combineReducers({
  roles,
  permissions,
  partnerFunction
})

