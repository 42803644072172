import moment from 'moment'
let initialData = {
    loading: false,
    tableData: []
}

export default function MaterialList(state = initialData, action) {
    switch (action.type) {
        case 'GET_MATERIALS_DATA':
            return {
                ...state,
                loading: true,
            }
        case 'GET_MATERIALS_DATA_FULFILLED':
            action.payload.data.map((data, i) => {
                data.created_at = moment(data.created_at).format("MMM. DD, YYYY")
                data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
                data.created_at_number = moment(data.created_at).format("MM/DD/YYYY")
                data.status = data.status ? 'Active' : 'Inactive'
                data.is_separate_order = data.is_separate_order ? 'Active' : 'Inactive'
                data.is_disabled = data.is_disabled ? 'Disabled' : 'Enabled'
            })
            return {
                ...state,
                tableData: action.payload,
                loading: false
            }
        case 'GET_MATERIALS_DATA_REJECTED':
            return {
                ...state,
                tableData: '',
                loading: false
            }

        case 'POST_MATERIALS_DATA':
            return {
                ...state,
                loading: true,
            }
        case 'POST_MATERIALS_DATA_FULFILLED':
            return {
                ...state,
                loading: false
            }
        case 'POST_MATERIALS_DATA_REJECTED':
            return {
                ...state,
                loading: false
            }

        default:
            return {
                ...state,
            }
    }
}