const initialData = {
    myInfoLoading: false,
    myInfo: []
}

export default function info(state = initialData, action) {
    switch (action.type) {
        case 'FETCH_INFO':
            return {
                ...state,
                myInfoLoading: true
            }
        case 'FETCH_INFO_FULFILLED':
            return {
                ...state,
                myInfo: action.payload,
                myInfoLoading: false
            }
        case 'FETCH_INFO_REJECTED':
            return {
                ...state,
                myInfo: [],
                myInfoLoading: false
            }

        default:
            return {
                ...state
            }
    }
}
