import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input, Icon, Tabs, Divider } from 'antd';
import storage from 'redux-persist/lib/storage';
import { loadStorageLocation } from '../../actions';

const { Content } = Layout;
const Option = Select.Option;

class ModalForm extends Component {

  _modalType = (type) => type === 'edit' ? 'updated' : 'added';

  handleSubmitDistributorData = (e) => {
    e.preventDefault()
    const { form, modalType } = this.props


    form.validateFields((err, values) => {
      if (!err) {
        const selectedId = (this.props.selectedRow) ? this.props.selectedRow.id : ''
        const distributorData = {
          id: selectedId,
          source: values.source,
          status: values.status === 'Active' ? 1 : 0,
          tagging: values.tagging
        }

        this.props.submitUpdateDistributor(distributorData, this.props.searchParams).then((response) => {
          if (response.error) {
            Modal.warning({
              title: 'Unable to update Customer.',
              content: ''
            })
          } else {
            Modal.success({
              title: `Customer has been ${this._modalType(modalType)} successfully`,
              content: ''
            })
          }
        })
        this.props.handleCloseModal();
      }
    });
  }

  displayDistributorBasedOnId(type, key) {
    const { selectedRow } = this.props
    let returnData = ''
    selectedRow && selectedRow.contact.map((data) => {
      if (data.type === type) {
        returnData = data[key]
      }
    })
    return returnData
  }

  render() {
    const {
      isLoading,
      selectedRow
    } = this.props

    const payment_term_mapping = {
      'ZFDO': 'Credit term',
      'ZFDL': 'Cash On Delivery',
      'ZCIA': 'Cash In Advance', // NOTE: remove in second round of testing
      'ZFCI': 'Cash In Advance',
      'ZFDK': 'Special Term'
    };

    return (
      <Modal
        title={`${selectedRow ? 'Edit' : 'Add'} Customer`}
        visible={this.props.showAddEditModal}
        onCancel={this.props.handleCloseModal}
        centered={true}
        footer={null}
        width={800}
      >
        <Layout>
          <Content className='contact-form-container'>
            <Row type='flex' justify='center' gutter={30}>
              <Form onSubmit={this.handleSubmitDistributorData} className='login-form'>
                <Content style={{ padding: '20px' }}>
                  <h2>Information</h2>
                  <Row style={{ display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "space-between" }}>
                    <Col span={12} style={{ minWidth: "300px" }}>
                      <Form.Item label='Customer Code'>
                        <Input
                          placeholder='Customer code'
                          readOnly={true}
                          value={selectedRow.customer_code}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ minWidth: "300px" }}>
                      <Form.Item label='Customer Name'>
                        <Input
                          placeholder='Customer Name'
                          readOnly={true}
                          value={selectedRow.name}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label='Address'>
                        <Input
                          placeholder='Address'
                          readOnly={true}
                          value={selectedRow.address}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Col span={8} id="source" style={{ minWidth: "150px" }}>
                    <Form.Item label={<label>Source</label>} colon={false}>
                      {this.props.form.getFieldDecorator(`source`, {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "Please select Source",
                            type: 'array'
                          }
                        ],
                        initialValue: selectedRow.source.map(source => source['description'])
                      })

                        (<Select
                          mode="multiple"
                          placeholder='Select Source'
                          getPopupContainer={() => document.getElementById('source')}
                        >
                          {/* static values, conenct to database if time allows. If not, connect to db in MVP 2 */}
                          {this.props.storageLocation.map((value) =>
                            <Option key={value.description} value={value.description}>{value.description}</Option>
                          )}
                        </Select>)}
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ minWidth: "150px" }}>
                    <Form.Item label='EWT Status'>
                      {this.props.form.getFieldDecorator('ewt_status', {
                        rules: [{ required: true, message: 'Please input your EWT Status' }],
                        initialValue: selectedRow ? selectedRow.ewt_status.toString() === 'Yes' ? 'Enabled' : 'Disabled' : ""
                      })(
                        <Input
                          placeholder='EWT Status'
                          readOnly={true}
                          value={selectedRow.ewt_status === 'Yes' ? 'Enabled' : 'Disabled'}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ minWidth: "150px" }}>
                    <Form.Item label='Status'>
                      {this.props.form.getFieldDecorator('status', {
                        rules: [{ required: true, message: 'Please input your Status!' }],
                        initialValue: selectedRow ? selectedRow.status.toString() : ""
                      })(
                        <Select placeholder='Select a Status' showSearch>
                          <Option value='Active'>Active</Option>
                          <Option value='Inactive'>Inactive</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ minWidth: "150px" }}>
                    <Form.Item label='Payment Term'>
                      {this.props.form.getFieldDecorator('payment_term', {
                        rules: [{ required: true, message: 'Please input Payment Term!' }],
                        initialValue: selectedRow ? payment_term_mapping[selectedRow.payment_term.toString()] : ""
                      })(
                        <Input
                          placeholder='EWT Status'
                          readOnly={true}
                          value={payment_term_mapping[selectedRow.payment_term]}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8} id="tagging" style={{ minWidth: "150px" }}>
                    <Form.Item label={<label>Customer Tagging</label>} colon={false}>
                      {this.props.form.getFieldDecorator(`tagging`, {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "Please select customer tagging",
                            type: 'array'
                          }
                        ],
                        initialValue: selectedRow.tagging ? selectedRow?.tagging : ["regular"]
                      })

                        (<Select
                          mode="multiple"
                          placeholder='Select tagging'
                          getPopupContainer={() => document.getElementById('tagging')}
                        >
                          <Option key="regular" value="regular">Regular</Option>
                          <Option key="mto" value="mto">MTO</Option>
                        </Select>)}
                    </Form.Item>
                  </Col>

                </Content>
                <Col span={24} align="right">
                  <Form.Item>
                    <Button key="back" onClick={this.props.handleCloseModal} disabled={isLoading}>
                      Close
                    </Button>
                    &nbsp;
                    <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Content>
        </Layout>
      </Modal >
    )
  }
}

export default Form.create({ name: 'modal_form' })(ModalForm);
