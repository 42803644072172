import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Switch, Input, Table } from 'antd';

const { Content } = Layout;

export default function ModalForm(props) {
  const { selectedRow, isLoading } = props;

  
  const columns = [
    {
      title: 'Customer Code',
      dataIndex: 'customer_code',
      key: 'customer_code'
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 200,
      render: (value, row) => {
        return (
          <div>
            <Switch checked={row.customer_code === selectedRow.customer?.customer_code} value={value} onChange={props.handleSubmit}/>
          </div>
        )
      }
    }
  ];

  return (
    <Modal
      title="Update Customer Group Mapping"
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Form className="login-form">
            <Row type="flex" gutter={30}>
              <Col span={12}>
                <Form.Item label="Customer Group">
                  <Input value={selectedRow.customer_group_code} readOnly={true} />
                </Form.Item>
              </Col>
            </Row>
            <i style={{ fontSize: 12 }}>* Only 1 customer can be enabled. If there is an existing enabled customer, and a new customer will be enabled the previous/existing customer will be disabled</i>
            <Table
              rowKey="customer_code"
              dataSource={props.customerByGroup}
              columns={columns}
              pagination={false}
              loading={props.customerByGroupLoading}
            />
            <Col span={24} align="right">
              <Form.Item>
                <Button key="back" onClick={props.handleCloseModal} disabled={isLoading}>
                  Close
                </Button>
                &nbsp;
                <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Content>
      </Layout>
    </Modal>
  )
}
