import Axios from '../../../utils/axios'
import axios from 'axios'
import { headers } from '../../../utils/token'
import moment from 'moment'

export const loadStorageLocation = (data) => {
  return dispatch => {
    Axios.get('/admin/storage-location', dispatch, 'GET_STORAGE_LOCATION_DATA', data)
  }
}

export const loadOrdersData = (params) => {
  return dispatch => {
    return Axios.get('/orders', dispatch, 'GET_ORDER_DATA', params)
  }
}

export const downloadData = (params) => {
  return dispatch => {
    return Axios.get('/admin/orders/download', dispatch, 'GET_DOWNLOAD', params)
  }
}

export const loadOrderStatusData = (data) => {
  return dispatch => {
    Axios.get('/admin/orders-reconfig', dispatch, 'GET_ORDER_STATUS_DATA', data)
  }
}

export const fetchNotifications = (data) => {
  return dispatch => {
    Axios.get('/admin/notifications', dispatch, 'DISTRIBUTOR_NOTIFICATION', data)
  }
}

export const sendRequest = (data) => {
  return dispatch => {
    return Axios.post(`/admin/notifications`, dispatch, 'SEND_NOTIFICATION', data)
      .then((response) => {
        dispatch(fetchNotifications())
        return response
      })
  }
}

export const confirmOrder = (data) => {
  return dispatch => {
    return Axios.post(`/admin/orders-confirm`, dispatch, 'CONFIRM_ORDER', data)
      .then((response) => {
        dispatch(loadOrdersData())
        return response
      })
  }
}

export const proposeOrder = (data) => {
  return dispatch => {
    return Axios.post(`/admin/orders-propose`, dispatch, 'PROPOSE_ORDER', data)
      .then((response) => {
        dispatch(loadOrdersData())
        return response
      })
  }
}

export const rejectOrder = (data) => {
  return dispatch => {
    return Axios.post(`/admin/orders-reject`, dispatch, 'REJECT_ORDER', data)
      .then((response) => {
        dispatch(loadOrdersData())
        return response
      })
  }
}

export const submitDownloadCsv = (data) => {
  return dispatch => {
    return axios({
      url: `${process.env.REACT_APP_API_URL}/order-csv/${data.id}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: headers
    })
      .then((response) => {
        dispatch({
          type: `GET_ORDER_CSV_DATA_FULFILLED`,
          payload: response.data
        })

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let filename = `${moment(data.created_at).format("MM-DD-YYYY")}_${data.transaction_number}.xlsx`
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        dispatch({
          type: `GET_ORDER_CSV_DATA_REJECTED`,
          payload: error
        })
      });
  }
}

export const downloadAttachment = (orderId, attachmentId) => {
  return dispatch => {
    return Axios.get(`/order/${orderId}/attachment/${attachmentId}/download`, dispatch, 'DOWNLOAD_ATTACHMENT', undefined, true, { headers: headers, responseType: 'blob' })
      .then((response) => {
        return response
      })
  }
}

export const retrieveFileUrl = (orderId, attachmentId) => {
  return dispatch => {
    return Axios.get(`/order/${orderId}/attachment/${attachmentId}/file-url`, dispatch, 'FETCH_FILE_URL')
      .then((response) => {
        return response
      })
  }
}