import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadUserRolesData,
    submitAddUserRole,
    submitUpdateUserRole,
    loadPermissionsData,
    loadPartnerFunction
} from './actions';
import {
  fetchPermissions  
} from '../../actions';
import Page from './Page';

class UserTypes extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const UserTypesForm = Form.create({ name: 'brand_form' })(UserTypes);

function mapStateToProps(state) {
    console.log(state, "state")
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        rolesLoading: state.userTypes.roles.userRolesLoading,
        roles: state.userTypes.roles.userRolesData,
        permissions: state.userTypes.permissions.permissionsData,
        permissionsLoading: state.userTypes.permissions.permissionsLoading,
        partnerFunction: state.userTypes.partnerFunction.partnerFunctionData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onloadPermissionsData: (data) => dispatch(loadPermissionsData(data)),
        onLoadUserRolesData: (data) => dispatch(loadUserRolesData(data)),
        onSubmitAddUserRole: (data) => dispatch(submitAddUserRole(data)),
        onSubmitUpdateUserRole: (data) => dispatch(submitUpdateUserRole(data)),
        onLoadPartnerFunction: () => dispatch(loadPartnerFunction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTypesForm);
