import React, { Component } from 'react';
import { Button, Modal } from 'antd'

class PriceSync extends Component {
	priceSync = () => {
		this.props.onSyncPrice().then((response) => {
			Modal.success({
				title: 'Customer price sync has been executed. Please check after a few minutes.',
				content: ''
			})
		})
	}

	render() {
		const { title, style, className, loading } = this.props
		return (
			<div>
				<Button
					className={className}
					style={style}
					onClick={this.priceSync}
					loading={loading}
				>{title}
				</Button>
			</div>
		);
	}
}

export default PriceSync
