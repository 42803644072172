import React, { Component } from 'react'
import { Row, Col, Form, Modal, Layout, Input, Switch, Tabs, Spin, message, Empty, Select, Button } from 'antd'
import { connect } from 'react-redux'
import {
  submitEditPriceList,
  loadProductVariantData,
  searchProductVariant,
  submitShowHideVariant,
  priceChangeBySource,
  updateMaterialAvailabilityDate
} from '../../actions';
import _ from 'lodash';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

class ModaPricelistForm extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      products: [],
      constantProduct: [],
      keyword: '',
      activeKey: 0,
      searchKey: '',
      isSearching: false,
      soldTo: null,
      customerMaterials: [],
      materialPriceV2: []
    }
  }

  handleMaterialSoldToList = (soldTo) => {

    const { onLoadShipTo } = this.props

    this.setState({ soldTo: soldTo });

    onLoadShipTo({ customer_code: soldTo })

  }

  handleMaterialShipToList = (shipTo) => {
    const { soldTo } = this.state
    const { selectedRow } = this.props

    this.setState({ shipTo: shipTo });

    this.props.onLoadPricingV2(`${selectedRow.customer_code}-${soldTo}-${shipTo}-${selectedRow.source[0].shipping_point}`)
  }

  handlePriceChangeOnSource = (source, materialNumber) => {
    const { soldTo, shipTo } = this.state
    const { onLoadMaterialPrice, selectedRow, customerSoldToData, customerShipToData } = this.props;

    const storageLocation = _.find(selectedRow.source, (location) => {
      return location.shipping_point === source
    })
    const soldToCode = soldTo || customerSoldToData[0].customer.customer_code
    const shipToCode = shipTo || customerShipToData[0].ship_to.customer_code

    onLoadMaterialPrice(`${selectedRow.customer_code}-${soldToCode}-${shipToCode}-${storageLocation.shipping_point}`, materialNumber)
  }

  onCancel = () => {
    this.setState({ soldTo: null });
    this.props.handleCloseModal();
  }

  searchMaterial = (value) => {
    const { pricelistByProductData } = this.props;
    let products = pricelistByProductData.filter(function (item) {
      return item['material']['description'].toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
    this.setState({
      products: products
    }, () => console.log(this.state.products));

  }

  handleEditPricelist = (e) => {
    e.preventDefault()
    const { selectedRow: { id } } = this.props
    const { pricelistByProductData, onSubmitEditPriceList, form } = this.props
    form.validateFields(async (err, values) => {
      if (!err) {
        let returnData = pricelistByProductData && pricelistByProductData.map((data, i) => {

          return {
            distributor_id: id,
            product_id: data.id,
            product_variant_id: data.pricelist[0].product_variant_id,
            selling_price: values[data.id] ? parseFloat(values[data.id].selling_price) : 0,
            special_code: values[data.id] ? values[data.id].special_code : "",
            status: values[data.id] ? values[data.id].status : false
          }
        })

        this.setState({
          isLoading: true
        });

        onSubmitEditPriceList({ pricelist: returnData })
          .then((response) => {
            if (response.error) {
              Modal.warning({
                title: 'Unable to update Pricelist.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Pricelist has been updated successfully',
                content: ''
              })
            }

            this.setState({
              isLoading: false
            })
          })
      }
    })
  }

  handleAvailabilityDate = (e) => {
    const { onUpdateMaterialAvailabilityDate } = this.props
    const product = this.state.customerMaterials.find(product => product.id === parseInt(e.target.id, 10));
    this.setState({
      isLoading: true
    })
    onUpdateMaterialAvailabilityDate(e.target.value, product[e.target.value], product.material_variant_id, product.customer_id).then((response) => {
      if (response.error) {
        Modal.warning({
          title: 'Unable to update material.',
          content: ''
        })
      } else {
        Modal.success({
          title: 'Material has been updated successfully',
          content: ''
        })
      }
      this.setState({
        isLoading: false
      })
    })
  }

  handleChange = (id, value, key) => {
    value = isNaN(value) === false && value !== "" ? parseInt(value, 10) : 0;
    const updatedData = this.state.customerMaterials.map(item =>
      item.id === id ? { ...item, [key]: value } : item
    );
    this.setState({ customerMaterials: updatedData });
  };

  handleTagging = (id, value) => {
    const { onSubmitTagging } = this.props;
    const product = this.state.customerMaterials.find(product => product.id === parseInt(id, 10));

    this.setState({
      isLoading: true
    })
    onSubmitTagging({
      customer_id: product.customer_id,
      material_variant_id: product.material_variant_id,
      tagging: value,
    }).then((response) => {
      if (response.error) {
        Modal.warning({
          title: 'Unable to update material.',
          content: ''
        })
      } else {
        Modal.success({
          title: 'Material has been updated successfully',
          content: ''
        })
      }
      this.setState({
        isLoading: false
      })
    })
  };

  componentDidUpdate(prevProps) {
    if (this.props.pricelistByProductData !== prevProps.pricelistByProductData) {
      this.setState({ customerMaterials: this.props.pricelistByProductData })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.customerShipToData !== nextProps.customerShipToData) {
      const soldToCode = this.state.soldTo || this.props.customerSoldToData[0]?.customer.customer_code;
      this.props.onLoadPricingV2(`${this.props.selectedRow.customer_code}-${soldToCode}-${nextProps.customerShipToData[0]?.ship_to.customer_code}-${this.props.selectedRow.source[0]?.shipping_point}`)
    }

    if (this.props.priceListV2 !== nextProps.priceListV2) {
      this.setState({
        materialPriceV2: nextProps.priceListV2.resource
      });
    }

    if (this.props.materialPrice !== nextProps.materialPrice) {
      const newMaterialPrice = nextProps.materialPrice.resource;
      let priceV2 = this.state.materialPriceV2;
      const priceIndex = _.findIndex(priceV2, function (materialPrice) { return parseInt(materialPrice.material_number, 10) === parseInt(newMaterialPrice.material_number, 10); });

      if (priceIndex > -1) {
        priceV2[priceIndex] = newMaterialPrice;
        this.setState({
          materialPriceV2: priceV2
        });
      }
    }
  }

  render() {
    const {
      selectedRow,
      pricelistByProductData,
      pricelistByProductLoading,
      customerSoldToLoading,
      customerSoldToData,
      updateCustomerMaterialTagging,
      customerShipToLoading,
      priceListV2Loading,
      customerShipToData,
    } = this.props;

    const {
      isSearching,
      isLoading,
      customerMaterials,
      materialPriceV2
    } = this.state

    const productsData = [{ id: 1 }]

    return (
      <Modal
        destroyOnClose={true}
        visible={this.props.showFormModal}
        title={`Distributor Pricelist (${selectedRow !== null && selectedRow.name})`}
        onCancel={this.onCancel}
        centered
        footer={null}
        width={1024}
      >
        <Spin spinning={customerSoldToLoading || updateCustomerMaterialTagging || customerShipToLoading || priceListV2Loading || isLoading || pricelistByProductLoading}>
          <Layout>
            <Content className='contact-form-container'>
              <Row style={{ display: "flex", flexWrap: "wrap" }}>
                <Col span={12} style={{ minWidth: "300px" }}>
                  {!_.isEmpty(customerSoldToData) && !customerSoldToLoading ?
                    (<div><span style={{ fontWeight: "bold" }}>Sold To</span><Select
                      className="sold-to-input"
                      style={{ margin: '10px' }}
                      defaultValue={customerSoldToData[0].customer.customer_code}
                      onChange={this.handleMaterialSoldToList}
                    >
                      {
                        customerSoldToData.map((soldTo) => {
                          return (<Option value={soldTo.customer.customer_code} key={soldTo.customer.customer_code} className="select">
                            {soldTo.customer.name}
                          </Option>)
                        })
                      }
                    </Select></div>) : ""
                  }
                  {!_.isEmpty(customerShipToData) && !customerShipToLoading ?
                    (<div><span style={{ fontWeight: "bold" }}>Ship To</span><Select
                      className="ship-to-input"
                      style={{ margin: '10px' }}
                      defaultValue={customerShipToData[0].ship_to.customer_code}
                      onChange={(value) => {
                        this.handleMaterialShipToList(value)
                      }}
                    >
                      {
                        customerShipToData.map((shipTo) => {
                          return (<Option value={shipTo.ship_to.customer_code} key={shipTo.ship_to.customer_code} className="select">
                            {shipTo.ship_to.name}
                          </Option>)
                        })
                      }
                    </Select></div>) : ""
                  }
                </Col>
                <Col span={12} style={{ minWidth: "300px" }}>
                  <Search
                    placeholder="Search"
                    onSearch={async (value) => {
                      this.props.onLoadProductVariantData({
                        customer_code: selectedRow.customer_code,
                        material: value
                      })
                    }}
                    style={{ margin: '10px', width: "230px", marginBottom: '50px' }}
                    allowClear="true"
                  />
                </Col>
              </Row>
              <Row justify="center">


                {pricelistByProductData.length === 0 && !isSearching && !isLoading && !pricelistByProductLoading
                  ?
                  (
                    <Col style={{ display: 'flex', flex: 1, justifyContent: "center", height: 550 }}>
                      <Row style={{ display: 'flex', flex: 1, flexDirection: "column", justifyContent: "center" }}>
                        <div>
                          <Empty />
                          <h4>You may have entered an invalid keyword or search term. Please try again.</h4>
                        </div>
                      </Row>
                    </Col>
                  )
                  : pricelistByProductData.length > 0
                    ?
                    ''
                    :
                    (
                      <Col style={{ display: 'flex', flex: 1, justifyContent: "center", height: 550 }}>
                        <Row style={{ display: 'flex', flex: 1, flexDirection: "column", justifyContent: "center" }}>
                          <Empty />
                        </Row>
                      </Col>
                    )
                }
                <Tabs
                  defaultActiveKey="0"
                  tabPosition={"left"}
                  style={{ width: '100%', height: 550, position: "relative", display: pricelistByProductData.length === 0 ? 'none' : 'block' }}
                  type="scrollable"
                  tabBarStyle={{ display: 'none', whiteSpace: 'normal' }}
                  scroll={{ x: true }}
                >
                  {productsData && productsData.map((row, index) => (
                    <TabPane tab={`${row.name}`} key={index} style={{ overflowX: "auto" }}>

                      <Row style={{ display: pricelistByProductData.length === 0 || pricelistByProductData.every(data => data.status === 0) ? 'none' : 'flex' }}>
                        <Col span={4} style={{ minWidth: "100px" }}>
                          <b>Material Description</b>
                        </Col>

                        <Col span={3} style={{ minWidth: "100px" }}>
                          <b>Storage location</b>
                        </Col>

                        <Col span={4} style={{ minWidth: "100px" }} align="center">
                          <b>Selling Price</b>
                        </Col>

                        <Col span={3} style={{ minWidth: "100px" }} align="center">
                          <b>Min Age</b>
                        </Col>

                        <Col span={3} style={{ minWidth: "100px" }} align="center">
                          <b>Fumigation Days</b>
                        </Col>

                        <Col span={3} style={{ minWidth: "100px" }} align="center">
                          <b>Tagging</b>
                        </Col>

                        <Col span={3} style={{ minWidth: "100px" }} align="center">
                          <b>Status</b>
                        </Col>
                      </Row>

                      <Form onSubmit={this.handleEditPricelist} className="login-form" key={selectedRow.id}>

                        <Form onSubmit={this.handleEditPricelist} className="login-form" key={selectedRow.id}>

                          <Row style={{ height: 400 }}>
                            <Col>
                              {customerMaterials && customerMaterials.map((variant, i) => {
                                return (
                                  <Row gutter={10} key={`row-${selectedRow.id}-${variant.id}-${i}`} style={{ display: "flex" }}
                                  >
                                    <Col span={4} key={`special_code_col-${selectedRow.id}-${variant.id}-${i}`} style={{ minWidth: "100px" }}>
                                      <b>{variant.material.material_number}</b><br></br>
                                      <span>{variant.material.description}</span>
                                    </Col>

                                    <Col span={3} style={{ minWidth: "100px" }}>
                                      <Form.Item key={`special_code_form-${selectedRow.id}-${variant.id}-${i}`} >
                                        {selectedRow.source.length > 1 ?
                                          (<Select
                                            placeholder="Select Source"
                                            className="source-input"
                                            defaultValue={selectedRow.source[0].shipping_point}
                                            onChange={(value) => {
                                              this.handlePriceChangeOnSource(value, variant.material.material_number)
                                            }}
                                          >
                                            {
                                              selectedRow.source.map((source) => {
                                                return (<Option value={source.shipping_point} key={source.shipping_point} className="select">
                                                  {source.description}
                                                </Option>)
                                              })
                                            }
                                          </Select>) :

                                          (<span>
                                            {selectedRow.source[0].description}
                                          </span>)
                                        }
                                      </Form.Item>
                                    </Col>
                                    <Col span={4} style={{ minWidth: "100px" }}>
                                      <Form.Item key={`selling_price_form-${variant.id}-${i}`}  >
                                        <Input
                                          key={`selling_price-${variant.id}-${i}`}
                                          className={`isActiveInput-${i}`}
                                          value={_.find(materialPriceV2, (price) => {
                                            return parseInt(price.material_id, 10) === parseInt(variant.material.material_number, 10)
                                          })?.price || "0.00"}
                                          readOnly={true}
                                          style={{ background: 'white' }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={3} style={{ minWidth: "100px" }}>
                                      <Form.Item key={`max-age-${variant.id}-${i}`}  >
                                        <Input
                                          key={`max-age-${variant.id}-${i}`}
                                          className={`isActiveInput-${i}`}
                                          defaultValue={variant.age}
                                          style={{ background: 'white' }}
                                          onChange={(e) => this.handleChange(variant.id, e.target.value, "age")}
                                          suffix={
                                            selectedRow.tagging && selectedRow?.tagging.includes("mto") ? <Button value="age" id={variant.id} type="link" shape="circle" icon="check" size="small" onClick={this.handleAvailabilityDate} /> : false
                                          }
                                          disabled={(selectedRow.tagging && !selectedRow?.tagging.includes("mto")) || selectedRow.tagging === null}
                                          type='number'
                                        />

                                      </Form.Item>
                                    </Col>
                                    <Col span={3} style={{ minWidth: "100px" }}>
                                      <Form.Item key={`fumigation-${variant.id}-${i}`}  >
                                        <Input
                                          key={`fumigation-${variant.id}-${i}`}
                                          className={`isActiveInput-${i}`}
                                          defaultValue={variant.fumigation}
                                          style={{ background: 'white' }}
                                          onChange={(e) => this.handleChange(variant.id, e.target.value, "fumigation")}
                                          suffix={
                                            selectedRow.tagging && selectedRow?.tagging.includes("mto") ? <Button value="fumigation" id={variant.id} type="link" shape="circle" icon="check" size="small" onClick={this.handleAvailabilityDate} /> : false
                                          }
                                          disabled={(selectedRow.tagging && !selectedRow?.tagging.includes("mto")) || selectedRow.tagging === null}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={3} style={{ minWidth: "100px" }}>
                                      <Form.Item key={`tagging-${variant.id}-${i}`}  >
                                        <Select
                                          placeholder="Select Tagging"
                                          className="tagging-input"
                                          defaultValue={variant.tagging}
                                          onChange={(value) => this.handleTagging(variant.id, value)}
                                        >
                                          <Option value="mto" className="select">MTO</Option>
                                          <Option value="regular" className="select">Regular</Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={3} align="center" style={{ minWidth: "100px" }}>
                                      <Form.Item key={`status-form-${selectedRow.id}-${variant.id}-${i}`}>
                                        <Switch
                                          key={`status-${selectedRow.id}-${variant.id}-${i}`}
                                          checked={(parseInt(variant.status) === 1 ? true : false)}
                                          checkedChildren="Show"
                                          unCheckedChildren="Hide"
                                          onChange={async (e) => {
                                            const data = {
                                              material_id: variant.material_variant_id,
                                              customer_id: selectedRow.id,
                                              status: e === true ? 1 : 0
                                            }

                                            this.setState({
                                              isLoading: true
                                            });

                                            await this.props.onSubmitEditPriceList(data)
                                              .then((response) => {
                                                if (response.error) {
                                                  message.warning('Unable to update Pricelist.')
                                                } else {
                                                  message.success('Pricelist has been updated successfully')
                                                  pricelistByProductData[i]["status"] = parseInt(variant.status) === 1 ? 0 : 1
                                                }
                                                this.setState({
                                                  isLoading: false
                                                })
                                              })
                                          }
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={1} align="center" style={{ minWidth: "100px" }}>
                                      <Form.Item key={`is_hidden-form-${selectedRow.id}-${variant.id}-${i}`}>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                );
                                // }
                              })}
                            </Col>
                          </Row>
                        </Form>
                      </Form>
                    </TabPane>
                  ))}
                </Tabs>

              </Row>
            </Content>
          </Layout>
        </Spin>
      </Modal >
    )
  }
}

function mapStateToProps(state) {
  return {
    productVariantData: state.distributor.productVariantData,
    distributorProductVariantLoading: state.distributor.productVariantLoading,
    productVariantEditPricelist: state.distributor.productVariantEditPricelist,
    pricelistByProductData: state.distributor.pricelistByProductData,
    pricelistByProductLoading: state.distributor.pricelistByProductLoading,
    searchProductVariantData: state.distributor.searchProductVariantData,
    searchProductVariantLoading: state.distributor.searchProductVariantLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitEditPriceList: (data) => dispatch(submitEditPriceList(data)),
    onSubmitShowHideVariant: (data) => dispatch(submitShowHideVariant(data)),
    onLoadProductVariantData: (data) => dispatch(loadProductVariantData(data)),
    onSearchProductVariant: (data) => dispatch(searchProductVariant(data)),
    onLoadPriceChange: (data) => dispatch(priceChangeBySource(data)),
    onUpdateMaterialAvailabilityDate: (id, value, material_variant_id, customer_id) => dispatch(updateMaterialAvailabilityDate(id, value, material_variant_id, customer_id)),
  }
}

const ModalPricelist = Form.create({ name: 'modalPricelist_form' })(ModaPricelistForm);
export default connect(mapStateToProps, mapDispatchToProps)(ModalPricelist);
