import _ from "lodash";

import React, { Component } from 'react';
import { Modal, Icon, Tabs } from 'antd'

import AttachmentList from './AttachmentList';

const { TabPane } = Tabs;

class ModalAttachment extends Component {

	render() {
		const {
			activeKey,
			excluded,
			selectedOrder,
			onDownload,
			onPreview
		} = this.props

		const tabs = {
			list: {
				title: "Attachment List",
				icon: "unordered-list",
				component: <AttachmentList selectedOrder={selectedOrder} onDownload={onDownload} onPreview={onPreview}/>
			}
		}

		return (<Modal
			title={<span>Purchase Order Document Attachment</span>}
			visible={this.props.isVisible}
			onCancel={() => {
				this.props.onClose(false);
			}}
			footer={null}
			maskClosable={true}
			closable={true}
		>
			<Tabs defaultActiveKey={activeKey || "list"}>
				{
					_.map(_.omit(tabs, excluded), (value, index) => {
						return (<TabPane
							tab={
								<span>
									<Icon type={value.icon} />
									{value.title}
								</span>
							}
							key={index}
						>
							{value.component}
						</TabPane>)
					})
				}
			</Tabs>
		</Modal>)
	}
}

export default ModalAttachment
