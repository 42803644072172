import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button, Checkbox, Layout, Spin } from 'antd';
import { Link } from 'react-router-dom'
import './index.scss';

const { Password } = Input;

class Login extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmitLogin(values)
      }
    });
  };

  componentDidMount() {
    const { history } = this.props
    history.push('/')
  }

  render() {
    const { form: { getFieldDecorator }, loggingIn, loginRejected } = this.props;
    return (
      <Layout>
        <div className='login-container'>
          <div className='login-form'>
            <Spin spinning={loggingIn}>
              <div className='login-header'>
                <img alt='login-logo' src={'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Universal_Robina_logo_2016.svg/1200px-Universal_Robina_logo_2016.svg.png'} />
                <span>URC Administrator</span>
              </div>
              {loginRejected &&
                <div className='login-error-message'>
                  <span>Login Failed: Invalid Credentials</span>
                </div>
              }
              <Form onSubmit={this.handleSubmit}>
                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Username"
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Password
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                  </Button><br />
                  <Row>
                    <Col md={12}>
                      {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                      })(<Checkbox>Remember me</Checkbox>)}
                    </Col>
                    <Col md={12} style={{ textAlign: 'right' }}>
                      <Link to='/forgot-password' className="login-form-forgot">Forgot password?</Link>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </Layout>
    );
  }
}

const LoginForm = Form.create({ name: 'login_form' })(Login);

export default LoginForm;