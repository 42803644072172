import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadUserRolesData,
    loadDistributorsData,
    submitUpdateDistributor,
    loadProductVariantData,
    loadStorageLocation,
    createCustomerMaterial,
    loadSoldTo,
    loadShipTo,
    loadSalesman,
    submitUpdateSubCustomer,
    submitUpdateSalesman,
    submitUpdateTagging,
    loadPrice,
    loadMaterialPrice,
    syncCustomerPrice,
    syncPrice
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import {
    loadUsersData
} from '../Users/actions'
import Page from './Page';

class Distributor extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const DistributorForm = Form.create({ name: 'distributor_form' })(Distributor);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        usersIsLoading: state.users.userLoading,
        users: state.users.usersData,
        rolesIsLoading: state.roles.userRolesLoading,
        roles: state.roles.userRolesData,
        distributorLoading: state.distributor.distributorLoading,
        distributorsData: state.distributor.distributorsData,
        distributorPricelistLoading: state.distributor.distributorPricelistLoading,
        distributorPricelistsData: state.distributor.distributorPricelistsData,
        distributorProductVariantData: state.distributor.productVariantData,
        distributorProductVariantLoaded: state.distributor.productVariantLoaded,
        storageLocationData: state.storageLocation.storageLocationData,
        storageLocationLoading: state.storageLocation.storageLocationLoading,
        customerSoldToData: state.distributor.customerSoldToData,
        customerSoldToLoading: state.distributor.customerSoldToLoading,
        customerShipToData: state.distributor.customerShipToData,
        customerShipToLoading: state.distributor.customerShipToLoading,
        customerSalesmanData: state.distributor.customerSalesmanData,
        customerSalesmanLoading: state.distributor.customerSalesmanLoading,
        updateSalesmanLoading: state.distributor.updateCustomerSalesmanLoading,
        updateCustomerMaterialTagging: state.distributor.updateCustomerMaterialTagging,
        priceListV2: state.distributor.priceListV2,
        priceListV2Loading: state.distributor.priceListV2Loading,
        materialPrice: state.distributor.materialPrice
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadUsersData: (data) => dispatch(loadUsersData(data)),
        onLoadUserRolesData: (data) => dispatch(loadUserRolesData(data)),
        onLoadDistributorsData: (data) => dispatch(loadDistributorsData(data)),
        onSubmitUpdateDistributor: (data, searchParams) => dispatch(submitUpdateDistributor(data, searchParams)),
        onLoadProductVariantData: (data) => dispatch(loadProductVariantData(data)),
        onLoadStorageLocationData: (data) => dispatch(loadStorageLocation(data)),
        onCreateMaterialLink: (data) => dispatch(createCustomerMaterial(data)),
        onLoadCustomSoldTo: (data) => dispatch(loadSoldTo(data)),
        onLoadCustomShipTo: (data) => dispatch(loadShipTo(data)),
        onLoadSalesman: (data) => dispatch(loadSalesman(data)),
        onUpdateSubCustomer: (data) => dispatch(submitUpdateSubCustomer(data)),
        onUpdateSalesman: (data) => dispatch(submitUpdateSalesman(data)),
        onSubmitTagging: (data) => dispatch(submitUpdateTagging(data)),
        onLoadPricingV2: (data) => dispatch(loadPrice(data)),
        onLoadMaterialPrice: (customer, material) => dispatch(loadMaterialPrice(customer, material)),
        onSyncCustomerPrice: (customerId) => dispatch(syncCustomerPrice(customerId)),
        onSyncPrice: () => dispatch(syncPrice())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorForm);
