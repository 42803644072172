import React, { Component } from 'react';
import ModalForm from './components/ModalForm';
import _ from 'lodash';

import './ProductVariants.scss'

import { Layout, Row, Col, Table, Modal, Result, Popover, Select, Input } from 'antd';

import { Icon, Form} from '@ant-design/compatible';
const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
class CustomerGroup extends Component {
  initialState = {
    showFormModal: false,
    showViewModal: false,
    showWarningModal: false,
    selectedRow: null,
    customerGroupData: [],
    filterBy: [
      'customer_group.customer_group_code'
    ],
    isImageUploading: false,
    changeStatusData: {},
    isLoading: false
  }

  // Inital State for the sorting
  sortState = {
    sortBy: 'customer_group.customer_group_code',
    orderBy: 'desc',
    page: 1
  };

  // Initial state for searching
  searchState = {};

  activeProducts = {};

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.imageUploader = React.createRef();
  }

  handleSubmit = (checked, event) => {
    this.props.onSubmitUpdateCustomerGroup({
      customer_id: checked ? event.target.value : null,
      id: this.state.selectedRow.id
    }, { ...this.sortState, ...this.searchState })
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update Customer Group mapping.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Customer Group Mapping has been updated successfully',
            content: ''
          })

          this.handleCloseModal();
        }
      })
  }

  handleShowEditFormModal = row => {
    this.props.onLoadCustomerByGroup({ customer_group: row.customer_group_code });
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null
    });
  };

  handleFilterBy = (value) => {
    this.setState({ filterBy: value, keyword: "" });
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadCustomerGroupData();
  }

  handleSearch = (searchTerm, searchKeys) => {

    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.props.onLoadCustomerGroupData({ ...this.sortState, ...{ 
      page: 1
    }, ...this.searchState })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.customerGroupData !== nextProps.customerGroupData) {
      this.setState({ customerGroupData: nextProps.customerGroupData })
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.columnKey || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      page: pagination.current
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.props.onLoadCustomerGroupData({ ...this.sortState, ...this.searchState })
  }

  render() {
    const { 
      showFormModal,
      customerGroupData,
      selectedRow,
      filterBy
    } = this.state
    const {
      myPermissions,
      myPermissionsLoading,
      form: { getFieldDecorator },
      customerByGroupData,
      customerByGroupLoading,
      customerGroupLoading,
      updatesLoading
    } = this.props

    const columns = [
      {
        title: 'Customer Group',
        dataIndex: 'customer_group_code',
        key: 'customer_group.customer_group_code',
        sorter: true
      },
      {
        title: 'Customer Code',
        dataIndex: 'customer.customer_code',
        key: 'customer.customer_code',
        sorter: true
      },
      {
        title: 'Customer Name',
        dataIndex: 'customer.name',
        key: 'customer.name',
        sorter: true
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <div>
              <Popover content={(<p>Edit</p>)}>
                <span onClick={() => this.handleShowEditFormModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
            </div>
          )
        }
      }
    ];

    if (!myPermissions.some(r => r.name === 'admin.customer_group.edit')) columns.pop();
    if (myPermissionsLoading) return null

    if (!myPermissions.some(r => r.name === 'admin.customer_group.view')) {
      return (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      )
    }

    return (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <div className="page-title">Customer Group</div>
            <Row>
              <Col >
                <div className="product-variants-container">
                  <Search
                    className="search-container"
                    size="large"
                    placeholder="Search"
                    allowClear={true}
                    onSearch={(value) => this.handleSearch(value, filterBy)}
                  />
                  <Select style={{ width: 160 }}
                    size="large"
                    placeholder="Filter"
                    onChange={(value) => this.handleFilterBy(value)}
                    defaultValue="customer_group.customer_group_id"
                    dropdownClassName="--select"
                    autoFocus={true}
                    showSearch>
                    <Option value="customer_group.customer_group_id">Customer Group</Option>
                    <Option value="customer.customer_code">Customer Code</Option>
                    <Option value="customer.name">Customer Name</Option>
                  </Select>
                  <Table
                    rowKey="id"
                    dataSource={customerGroupData.data}
                    columns={columns}
                    loading={customerByGroupLoading || customerGroupLoading}
                    onChange={this.handleOnChangeTableListener} 
                    pagination={{ total: customerGroupData.total, current: customerGroupData.current_page, pageSize: customerGroupData.per_page || 10 }} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>

        {showFormModal &&
          <ModalForm
            showFormModal={showFormModal}
            handleCloseModal={this.handleCloseModal}
            handleSubmit={this.handleSubmit}
            getFieldDecorator={getFieldDecorator}
            selectedRow={selectedRow}
            customerByGroup={customerByGroupData}
            customerByGroupLoading={customerByGroupLoading}
            isLoading={updatesLoading}
          />
        }
      </div >
    );
  }
}

const CustomerGroupForm = Form.create({ name: 'customer_group_form' })(CustomerGroup);

export default CustomerGroupForm;
