import React, { Component } from 'react';
import { Form, Icon, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal, Skeleton, Result } from 'antd';
import moment from 'moment';
import ModalForm from './components/ModalForm';
import './index.scss';

const { Content } = Layout;
const { Search } = Input;

class AdminManagement extends Component {
  state = {
    showFormModal: false,
    keyword: ""
  }

  // Inital State for the sorting
  sortState = {
    sortBy: 'created_at',
    orderBy: 'asc'
  };

  pagination = {};

  // Initial state for searching
  searchState = {};

  handleShowFormModal = () => {
    this.setState({
      selectedRow: null,
      showFormModal: true,
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
    });
  };

  handleEdit = value => {
    this.setState({ selectedRow: value, showFormModal: true })
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadUserRolesData()
    this.props.onLoadAdminManagementData()
    this.props.onLoadRetrieveSalesmanCode()
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ tableData: nextProps.data })
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.field || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    this.pagination = {
      page: pagination.current
    };

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveUsers(this.pagination);
  }

  retrieveUsers(data) {
    this.props.onLoadAdminManagementData({...this.sortState, ...data, ...this.searchState});
  }


    // The first params is the search value, second param are the search keys in table
    handleSearch = (event) => {
      this.searchState = event.target.value.trim() === '' ? {} : {
        searchValue: event.target.value
      };

      this.retrieveUsers({
        page: 1, // reset pagination to 1
      });
    }

  render() {
    const { showFormModal, keyword, selectedRow } = this.state
    const {
      myPermissions, myPermissionsLoading,
      users, usersIsLoading,
      roles, rolesIsLoading, salesman
    } = this.props
    
    const dataSource = users.data;

    const columns = [
      {
        title: 'EMAIL ADDRESS',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => { /* a.email.length - b.email.length */ },
      },
      {
        title: 'ACCESS',
        dataIndex: 'roles',
        key: 'roles',
        render: (roles) => roles.map((role) => role.name).join(', ')
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => { /* a.status.length - b.status.length */ },
        render: (status) => <span className={`${status === 'Active' ? 'active' : 'inactive'}-tag`} >{status === 'Active' ? 'active' : 'inactive'}</span>
      },
      {
        title: '',
        key: 'action',
        render: (value) => <Button
          style={{
            border: "none",
            background: "transparent"
          }}
          disabled={!myPermissions.some(r => r.name === 'admin.user.edit')}
          onClick={() => this.handleEdit(value)}>
          <Icon
            type="edit"
            style={{ fontSize: "18px" }} />
        </Button>
      }
    ];

    if (myPermissionsLoading) {
      return null
    }

    return myPermissions.some(r => r.name === 'admin.user.view') ? (
      <Layout className="admin-management-page">
        <Content style={{ padding: 25 }}>
          <Row type="flex" align="middle" style={{ margin: "20px 0px" }}>
            <Col span={12}>
              <h1 style={{ fontSize: 24 }}>Admin Management</h1>
            </Col>
            <Col span={12} align="right">
              <Button
                disabled={!myPermissions.some(r => r.name === 'admin.user.add')}
                type="primary"
                onClick={() => this.handleShowFormModal()}
                style={{ margin: '10px', height: 40 }} ><Icon type="plus" /> Add New User</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='admin-management-container'>
                <Table
                  loading={usersIsLoading}
                  title={() =>
                    (<Row>
                      <Col span={8}>
                        <Search className='search-container' size='large' placeholder="Search" onChange={this.handleSearch} />
                      </Col>
                    </Row>)
                  }
                  rowKey="id"
                  dataSource={dataSource}
                  columns={columns}
                  onChange={this.handleOnChangeTableListener}
                  pagination={{ total: users.total, current: users.current_page, pageSize: users.per_page || 10 }}
                />
              </div>
            </Col>
          </Row>
        </Content>

        <ModalForm
          roles={roles}
          users={dataSource}
          rolesIsLoading={rolesIsLoading}
          isLoading={rolesIsLoading && usersIsLoading}
          showFormModal={showFormModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
          onSubmitAddAdminManagement={this.props.onSubmitAddAdminManagement}
          onSubmitUpdateAdminManagement={this.props.onSubmitUpdateAdminManagement}
          searchParams={{...this.sortState, ...this.pagintation, ...this.searchState}}
          salesman={salesman}
        />

      </Layout>
    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const AdminManagementForm = Form.create({ name: 'admin_management_form' })(AdminManagement);

export default AdminManagementForm;
