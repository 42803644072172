import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input } from 'antd';
import ImageUploader from '../../../shared/ImageUploader'

const { Content } = Layout;
const Option = Select.Option;

export default function ModalForm(props) {
  const { selectedRow, isLoading } = props;

  return (
    <Modal
      title="Edit Material"
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Form onSubmit={props.handleSubmit} className="login-form">
            <Row type="flex" justify="center" gutter={30}>
              <Col span={8} align="center" style={{ height: 200 }}>
                <ImageUploader
                  ref={props.imageUploader}
                  fileFolder={`product-variants`}
                  buttonDesc="Click here to choose image"
                  imageUrl={selectedRow ? selectedRow.image : ""}
                />
              </Col>
              <Col span={16}>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Description">
                      <Input value={selectedRow.description} readOnly={true} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Material Code">
                      <Input value={selectedRow.material_number} readOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={30}>
              <Col span={12}>
                <Form.Item label="Gross Weight">
                  <Input value={selectedRow.gross_weight} readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Volume">
                  <Input value={selectedRow.volume} readOnly={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="start" gutter={30}>
              <Col span={12}>
                <Form.Item label="Sales Unit">
                  <Input value={selectedRow.sales_unit} readOnly={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Status">
                  {props.getFieldDecorator('status', {
                    rules: [{ required: true, message: 'Please input your Status!' }],
                    initialValue: selectedRow ? selectedRow.status.toString() : ""
                  })(
                    < Select placeholder='Select a Status' showSearch>
                      <Option value='Active'>Active</Option>
                      <Option value='Inactive'>Inactive</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Col span={24} align="right">
              <Form.Item>
                <Button key="back" onClick={props.handleCloseModal} disabled={isLoading}>
                  Close
                </Button>
                &nbsp;
                <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Content>
      </Layout>
    </Modal>
  )
}
