import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadUserRolesData,
    loadAdminManagementData,
    submitAddAdminManagement,
    submitUpdateAdminManagement,
    retrieveSalesmanCode
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class AdminManagement extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const AdminManagementForm = Form.create({ name: 'brand_form' })(AdminManagement);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        rolesIsLoading: state.adminManagement.roles.userRolesLoading,
        roles: state.adminManagement.roles.userRolesData,
        usersIsLoading: state.adminManagement.users.adminManagementLoading,
        users: state.adminManagement.users.adminManagementData,
        salesman: state.adminManagement.salesman.salesmanCodeData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadUserRolesData: (data) => dispatch(loadUserRolesData(data)),
        onLoadAdminManagementData: (data) => dispatch(loadAdminManagementData(data)),
        onSubmitAddAdminManagement: (data, searchParams) => dispatch(submitAddAdminManagement(data, searchParams)),
        onSubmitUpdateAdminManagement: (data, searchParams) => dispatch(submitUpdateAdminManagement(data, searchParams)),
        onLoadRetrieveSalesmanCode: () => dispatch(retrieveSalesmanCode())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminManagementForm);
