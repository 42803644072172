import Axios from '../../../utils/axios'

export const loadMaterialsData = (data) => {
    return dispatch => {
        Axios.get('/material-variants', dispatch, 'GET_MATERIALS_DATA', data)
    }
}

export const submitAddMaterial = (data, searchParams) => {
    return dispatch => {
        return Axios.post(`/admin/product-variant`, dispatch, 'POST_MATERIALS_DATA', data)
            .then((response) => {
                dispatch(loadMaterialsData(searchParams))
                return response
            })
    }
}

export const submitUpdateMaterial = (data, searchParams) => {
    return dispatch => {
        return Axios.put(`/material-variants/${data.id}`, dispatch, 'POST_MATERIALS_DATA', data)
            .then((response) => {
                dispatch(loadMaterialsData(searchParams))
                return response
            })
    }
}
