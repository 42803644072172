import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input, Tabs, List, Card, Checkbox, message, Select } from 'antd';
const { Content } = Layout;
const { Option } = Select;

class ModalForm extends Component {
  state = {
    loading: false,
    checkbox: [],
    indeterminate: true,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { checkbox } = this.state

    const { form, onSubmitAddUserRole, onSubmitUpdateUserRole, selectedRow: { id } } = this.props
    form.validateFields((err, values) => {
      if (checkbox.length < 1) {
        message.error('Please select atleast 1 access.')
        return
      }

      if (!err) {
        this.setState({ loading: true })

        if (id) {
          const params = {
            ...values,
            permissions: checkbox,
            id
          }

          onSubmitUpdateUserRole(params).then(res => {
            if (res.error) {
              Modal.error({
                title: 'Unable to process the request. Please try again.',
              })
            } else {
              Modal.success({
                title: 'Successfully updated user.',
              })
            }
            this.handleClose()
          })
        } else {
          const params = {
            ...values,
            permissions: checkbox
          }
          onSubmitAddUserRole(params).then(res => {
            if (res.error) {
              Modal.error({
                title: 'Unable to process the request. Please try again.',
              })
            } else {
              Modal.success({
                title: 'Successfully create user.',
              })
            }
            this.handleClose()
          })
        }
      }
    });
  }


  handleCheckbox = (value, check) => {
    const { checkbox } = this.state

    const access = value.split(".")

    if (check) {
      if (access[2] !== "view") {
        let view = `${access[0]}.${access[1]}.view`
        this.setState({ checkbox: [...new Set(checkbox.concat(value, view))] })
      } else {
        this.setState({ checkbox: [...new Set(checkbox.concat(value))] })
      }

    } else {

      let feature = `${access[0]}.${access[1]}`
      if (access[2] === "view") {

        const permissions = []
        checkbox.map((item) => {
          let checkbox_value = item.split(".");
          if (`${checkbox_value[0]}.${checkbox_value[1]}` === feature) {
            permissions.push(item)
          }
        })

        permissions.map((permission) => {
          const index = checkbox.indexOf(permission);
          if (index > -1) {
            checkbox.splice(index, 1);
          }
        })

      } else {
        const index = checkbox.indexOf(value);
        if (index > -1) {
          checkbox.splice(index, 1);
        }
      }

      this.setState({ checkbox })
    }

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedRow !== nextProps.selectedRow) {
      this.setState({ checkbox: nextProps.selectedRow && nextProps.selectedRow.permissions ? nextProps.selectedRow.permissions.map(value => value.name) : [] })
    }
  }

  handleClose = () => {
    this.setState({ loading: false, checkbox: [] })
    this.props.form.resetFields()
    this.props.handleCloseModal()
  }

  render() {
    const { loading, checkbox } = this.state
    const { showFormModal, permissions, selectedRow, partnerFunction } = this.props
    const { getFieldDecorator } = this.props.form;

    const groupBy = (data, key) => {
      return data.reduce(function (storage, item) {
        var group = item[key];
        storage[group] = storage[group] || [];
        storage[group].push(item);
        return storage;
      }, {});
    };

    const admin = permissions.filter(value => value.name.startsWith("admin"))
    let newAdminArray = [];
    admin.map(value => {
      let title = value.name.split('.')[1]
      let checkbox = value.name.split('.')[2]

      return newAdminArray.push({ title: title, checkbox: checkbox, value: value.name })
    })

    const adminGroupBy = groupBy(newAdminArray, 'title')
    const adminTab = Object.keys(adminGroupBy).filter(value => value !== "*").map((key) => {
      return { title: (key || "").replace(/_/g, ' '), checkbox: adminGroupBy[key].filter(value => value.checkbox !== "*").map(value => ({ checkbox: value.checkbox, value: value.value })) }
    });

    return (
      <Modal
        title={Object.keys(selectedRow).length === 0 ? "Add New User Type" : "Edit User"}
        visible={showFormModal}
        onCancel={this.handleClose}
        footer={null}
        width={900}
      >
        <Layout>
          <Content className="user-roles-modal">
            <Row type='flex' justify='center' gutter={30}>
              <Col span={22}>
                <Form onSubmit={this.handleSubmit}>
                  <Row type="flex" gutter={16}>
                    <Col span={12}>
                      <Form.Item label='User Type Name'>
                        {getFieldDecorator(`name`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please user type name.",
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value) {
                                  if (this.props.roles.some(r => r.name !== selectedRow.name && r.name.toLowerCase() === value.toLowerCase())) {
                                    callback("user type already exists");
                                  } else {
                                    callback();
                                  }
                                }
                                return;
                              }
                            }
                          ],
                          initialValue: selectedRow ? selectedRow.name : null
                        })(
                          <Input placeholder="Enter User Type Name" />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='Description'>
                        {getFieldDecorator(`description`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter description.",
                            },
                          ],
                          initialValue: selectedRow ? selectedRow.description : null
                        })(
                          <Input placeholder="Enter Description" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={16}>
                    <Col span={12}>
                      <Form.Item label='Partner Function'>
                        {getFieldDecorator(`partner_function`, {
                          rules: [
                            {
                              required: false,
                              message: "Select Partner Function",
                            }
                          ],
                          initialValue: selectedRow ? selectedRow?.partner_function : null
                        })(
                          <Select
                            showSearch
                            placeholder="Select a partner function"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                          >
                            {partnerFunction.map((value) => (
                              <Option value={value}>{value}</Option>
                            ))}
                          </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='tagging'>
                        {getFieldDecorator(`tagging`, {
                          rules: [
                            {
                              required: false,
                              message: "Select tagging",
                            }
                          ],
                          initialValue: selectedRow ? selectedRow?.tagging : null
                        })(
                          <Select
                            showSearch
                            placeholder="Select tagging"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                          >
                            <Option value="mto" key="mto">MTO</Option>
                            <Option value="regular" key="regular">Regular</Option>
                          </Select>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Admin" key="1">
                      <Content style={{ padding: '20px' }}>
                        <List
                          grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                          }}
                          dataSource={adminTab}
                          renderItem={item => (
                            <List.Item>
                              <Card title={item.title}>
                                <Row className="checkboxes">
                                  {item.checkbox.map(value => (
                                    <Col span={12} key={value.value}>
                                      <Checkbox
                                        checked={checkbox.some(item => item === value.value)}
                                        value={value.value}
                                        onChange={(e) => this.handleCheckbox(e.target.value, e.target.checked)}
                                      >
                                        {value.checkbox}
                                      </Checkbox>
                                    </Col>
                                  ))}
                                </Row>
                              </Card>
                            </List.Item>
                          )}
                        />
                      </Content>
                    </Tabs.TabPane>
                  </Tabs>
                  <Row type="flex" justify="end" gutter={16}>
                    <Col xs={24} sm={12} md={8} lg={6} align="center" style={{ margin: "5px 0px" }}>
                      <Button style={{ width: "100%", border: "none", fontSize: "16px", boxShadow: "none" }} onClick={this.handleClose} disabled={loading}>
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} align="center" style={{ margin: "5px 0px" }}>
                      <Button type="primary" htmlType="submit" style={{ width: "100%", fontSize: "16px" }} disabled={loading} loading={loading}>
                        {Object.keys(selectedRow).length === 0 ? "Create User Type" : "Update"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Modal>
    )
  }
}

export default Form.create({ name: 'modal_form' })(ModalForm);
