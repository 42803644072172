import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import * as _ from 'lodash'
import { getUser } from '../../../utils/token'
import './Header.scss'
import axios from "axios";
import {
  headers
} from "../../../utils";

const { SubMenu } = Menu;

function Header({ location, paths }) {
  let selectedKeys = _.chain(paths).filter(path => location.pathname.includes(path.slug)).map('slug').value()
  selectedKeys = !selectedKeys.length ? [_.find(paths, ['default', true]).slug] : selectedKeys

  function onLogout() {
    localStorage.clear()
    window.location.href='/'
  }

  async function openUserManual() {
    const url = `${process.env.REACT_APP_API_URL}/admin/download-user-manual`;
    await axios.get(
      url,
      {
        headers: headers(),
        responseType: 'arraybuffer'
      }
    ).then((res) => {
      let blob = new Blob([res.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'URC Flour App - User Manual.pdf'
      link.click()
    });
  }

  const user = JSON.parse(getUser())

  return (
    <Layout.Header style={{ display: "flex" }}>
      <Link to='/' className='logo' style={ { display: "flex" } } />
      <Menu className='ant-menu-urc right' mode='horizontal' defaultSelectedKeys={selectedKeys} selectedKeys={selectedKeys} style={{ lineHeight: "61px", display: "flex", width: "100%", justifyContent: "end" }}>
        <SubMenu className="search ant-menu-user" key="SubMenu" title={user ? user.username ? user.username : user.email : null}>
          <Menu.Item key="setting:1"><Link to="#" onClick={openUserManual}><span className="capitalize">Download User Manual</span></Link></Menu.Item>
        </SubMenu>
        <Menu.Item className='login-signup' onClick={onLogout} style={{ color: "white" }}>Logout</Menu.Item>
      </Menu>
    </Layout.Header>
  )
}

export default Header
