import Axios from '../../../utils/axios'

export const loadStorageLocation = (data) => {
  return dispatch => {
    Axios.get('/admin/storage-location', dispatch, 'GET_STORAGE_LOCATION_DATA', data)
  }
}

export const submitAddStorageLocation = (data, searchParams) => {
  return dispatch => {
    return Axios.post(`/admin/storage-location`, dispatch, 'POST_STORAGE_LOCATION_DATA', data)
      .then((response) => {
        dispatch(loadStorageLocation(searchParams))
        return response
      })
  }
}

export const updateStorageLocationStatus = (data, id, searchParams) => {
  return dispatch => {
    return Axios.put(`/admin/storage-location/${id}/status`, dispatch, 'PUT_STORAGE_LOCATION_DATA', data)
      .then((response) => {
        dispatch(loadStorageLocation(searchParams))
        return response
      })
  }
}
export const updateStorageLocationDetails = (data, id, searchParams) => {
  return dispatch => {
    return Axios.put(`/admin/storage-location/${id}`, dispatch, 'PUT_STORAGE_LOCATION_DATA', data)
      .then((response) => {
        dispatch(loadStorageLocation(searchParams))
        return response
      })
  }
}
