let initialData = {
    loading: false,
    tableData: [],
    customerByGroup: [],
    customerByGroupLoading: false,
    updatesLoading: false
}

export default function MaterialList(state = initialData, action) {
    switch (action.type) {
        case 'GET_CUSTOMER_GROUP_DATA':
            return {
                ...state,
                loading: true,
            }
        case 'GET_CUSTOMER_GROUP_DATA_FULFILLED':
            return {
                ...state,
                tableData: action.payload,
                loading: false
            }
        case 'GET_CUSTOMER_GROUP_DATA_REJECTED':
            return {
                ...state,
                tableData: '',
                loading: false
            }

        case 'GET_FETCH_CUSTOMER_BY_GROUP_DATA':
            return {
                ...state,
                customerByGroupLoading: true,
            }
        case 'GET_FETCH_CUSTOMER_BY_GROUP_DATA_FULFILLED':
            return {
                ...state,
                customerByGroup: action.payload,
                customerByGroupLoading: false
            }
        case 'GET_FETCH_CUSTOMER_BY_GROUP_DATA_REJECTED':
            return {
                ...state,
                customerByGroup: '',
                customerByGroupLoading: false
            }

        case 'POST_CUSTOMER_GROUP_DATA':
            return {
                ...state,
                updatesLoading: true,
            }
        case 'POST_CUSTOMER_GROUP_DATA_FULFILLED':
            return {
                ...state,
                updatesLoading: false
            }
        case 'POST_CUSTOMER_GROUP_DATA_REJECTED':
            return {
                ...state,
                updatesLoading: false
            }

        default:
            return {
                ...state,
            }
    }
}