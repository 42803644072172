import React, { Component } from 'react';
import moment from 'moment'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { Button } from 'antd'
import { numberFormat } from "../../utils";
const wb = new ExcelJS.Workbook()

class DownloadExcel extends Component {
    constructor() {
        super()
        this.state = {
            selectedOrder: []
        }
        this.saveAsExcel = this.saveAsExcel.bind(this)
    }

    async saveAsExcel() {
        let worksheetArray = []
        let worksheet = wb.addWorksheet("Orders Report")
        let orderCounter = 0;
        let counter = 0;
        this.state.selectedOrder.map((orderData, i) => {
            worksheet.getCell((`A1`).toString()).value = "Order Number";
            worksheet.getCell((`B1`).toString()).value = "Order Date"
            worksheet.getCell((`C1`).toString()).value = "Source Plant"
            worksheet.getCell((`D1`).toString()).value = "Order Type"
            worksheet.getCell((`E1`).toString()).value = "Customer Name"
            worksheet.getCell((`F1`).toString()).value = "Sold To Name"
            worksheet.getCell((`G1`).toString()).value = "Material Code"
            worksheet.getCell((`H1`).toString()).value = "Material Description"
            worksheet.getCell((`I1`).toString()).value = "Ordered Quantity"
            worksheet.getCell((`J1`).toString()).value = "Unit of Measure (UOM)"
            worksheet.getCell((`K1`).toString()).value = "Target Delivery Date"
            worksheet.getCell((`L1`).toString()).value = "Availability Leadtime"
            worksheet.getCell((`M1`).toString()).value = "Proposed Delivery Date"
            worksheet.getCell((`N1`).toString()).value = "Status"
            worksheet.getCell((`O1`).toString()).value = "Sales Order Number"
            worksheet.getCell((`P1`).toString()).value = "Delivery Status"

            worksheet = this.setOrderDetail(orderData, orderCounter, worksheet);

            orderData.order_material.map((material, materialCounter) => {
                worksheet.getCell((`G${orderCounter + materialCounter + 2}`).toString()).value = material.material_code
                worksheet.getCell((`H${orderCounter + materialCounter + 2}`).toString()).value = material.material_details?.description
                worksheet.getCell((`I${orderCounter + materialCounter + 2}`).toString()).value = material.quantity
                worksheet.getCell((`J${orderCounter + materialCounter + 2}`).toString()).value = material.material_details?.sales_unit
                worksheet.getCell((`L${orderCounter + materialCounter + 2}`).toString()).value = material.confirmed_availability_date
                worksheet.getCell((`M${orderCounter + materialCounter + 2}`).toString()).value = orderData.proposed_date
                worksheet = this.setOrderDetail(orderData, orderCounter + materialCounter, worksheet);
                counter = orderCounter + materialCounter + 1;
            });

            orderCounter = counter;

            worksheetArray.push(worksheet)
        })
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `Order Management Report.xlsx`)

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })
    }

    setOrderDetail = (orderData, orderCounter, worksheet) => {
        worksheet.getCell((`A${orderCounter + 2}`).toString()).value = orderData.order_transaction_id
        worksheet.getCell((`B${orderCounter + 2}`).toString()).value = orderData.created_at;
        worksheet.getCell((`C${orderCounter + 2}`).toString()).value = orderData.shipping_point ? orderData.shipping_point.map((shipping_point) => {
            return shipping_point.description
        }).join(', ') : "N/A"
        worksheet.getCell((`D${orderCounter + 2}`).toString()).value = orderData?.delivery_details?.type
        worksheet.getCell((`E${orderCounter + 2}`).toString()).value = orderData.customer.name
        worksheet.getCell((`F${orderCounter + 2}`).toString()).value = orderData.sold_to.name
        worksheet.getCell((`K${orderCounter + 2}`).toString()).value = orderData?.delivery_details?.estimated_delivery_date || "N/A"
        worksheet.getCell((`N${orderCounter + 2}`).toString()).value = orderData.mto_status || "N/A"
        worksheet.getCell((`O${orderCounter + 2}`).toString()).value = orderData.sales_order_number
        worksheet.getCell((`P${orderCounter + 2}`).toString()).value = orderData.status

        return worksheet
    }

    retrieveOrderDownloadData = () => {
        const { onDownloadOrder, filter } = this.props;
        onDownloadOrder({
            ...filter, ... {
                page: 1,
                limit: 100
            }
        }).then((response) => {
            this.setState({
                selectedOrder: response.response.data.data
            }, () => {
                this.saveAsExcel();
            })
        })
    }

    render() {
        const { title, style, className } = this.props
        return (
            <div>
                <Button
                    className={className}
                    disabled={this.props.total > 100 || this.props.total < 1}
                    style={style}
                    onClick={this.retrieveOrderDownloadData}
                    loading={this.props.loading}
                >{title}
                </Button>
            </div>
        );
    }
}

export default DownloadExcel
