const initialData = {
  orderData: [],
  orderStatusData: '',
  orderLoading: false,
  orderStatusLoading: false,
  downloadData: null,
  downloadDataLoading: false,
  orderDataTotalCount: 0,
  storageLocationData: [],
  storageLocationLoading: false
}
export default function order(state = initialData, action) {
  switch (action.type) {
    case 'GET_ORDER_DATA':
      return {
        ...state,
        orderLoading: true
      }
    case 'GET_ORDER_DATA_FULFILLED':
      return {
        ...state,
        orderData: action.payload,
        orderDataTotalCount: action.payload.data.total_count,
        orderLoading: false
      }
    case 'GET_ORDER_DATA_REJECTED':
      return {
        ...state,
        orderData: [],
        orderLoading: false
      }

    // orderStatus
    case 'GET_ORDER_STATUS_DATA':
      return {
        ...state,
        orderStatusLoading: true
      }
    case 'GET_ORDER_STATUS_DATA_FULFILLED':
      return {
        ...state,
        orderStatusData: action.payload.orderStatus,
        orderStatusLoading: false
      }
    case 'GET_ORDER_STATUS_DATA_REJECTED':
      return {
        ...state,
        orderStatusData: '',
        orderStatusLoading: false
      }
    case 'GET_DOWNLOAD':
      return {
        ...state,
        downloadData: null,
        downloadDataLoading: true
      }
    case 'GET_DOWNLOAD_FULFILLED':
      return {
        ...state,
        downloadData: action.payload,
        downloadDataLoading: false
      }
    case 'GET_STORAGE_LOCATION_DATA':
      return {
        ...state,
        storageLocationLoading: true
      }
    case 'GET_STORAGE_LOCATION_DATA_FULFILLED':
      return {
        ...state,
        storageLocationData: action.payload,
        storageLocationLoading: false
      }
    case 'CONFIRM_ORDER':
      return {
        ...state,
        orderStatusLoading: true
      }
    case 'CONFIRM_ORDER_FULFILLED':
      return {
        ...state,
        orderStatusLoading: false
      }
    case 'CONFIRM_ORDER_REJECTED':
      return {
        ...state,
        orderStatusLoading: false
      }
    case 'PROPOSE_ORDER':
      return {
        ...state,
        orderStatusLoading: true
      }
    case 'PROPOSE_ORDER_FULFILLED':
      return {
        ...state,
        orderStatusLoading: false
      }
    case 'PROPOSE_ORDER_REJECTED':
      return {
        ...state,
        orderStatusLoading: false
      }
    case 'REJECT_ORDER':
      return {
        ...state,
        orderStatusLoading: true
      }
    case 'REJECT_ORDER_FULFILLED':
      return {
        ...state,
        orderStatusLoading: false
      }
    case 'REJECT_ORDER_REJECTED':
      return {
        ...state,
        orderStatusLoading: false
      }
    default:
      return {
        ...state
      }
  }
}
